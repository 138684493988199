<template>
  <m-modal
    :value="show"
    v-on:close="close"
    :header="$t('spaces.create.addUsersToTeam')"
    max-width="980px"
    class="space-attendees"
  >
    <template v-slot:content>
      <div class="pa-8" data-cy="select-attendees-modal">
        <div class="user-summary">
          {{ $t('spaces.create.allUsers') }} ({{ filteredUsers.length }})
        </div>
        <m-table-view class="space-attendees__table" data-cy="select-attendees-list">
          <template #toolbar>
            <m-search-input
              class="space-attendees__search"
              v-model="searchTerm"
              :placeholder="$t('spaces.attendeesTable.searchUsers')"
              @clear="searchTerm = ''"
              data-cy="select-attendees-search-input"
            ></m-search-input>
            <m-button
              :label="$t('spaces.create.addNewUser')"
              icon="plus-stroke"
              icon-right
              @click="$emit('addUser')"
              variant="secondary"
              class="mr-2"
              data-cy="add-new-user-button"
            ></m-button>
          </template>
          <div class="scrollable-table">
            <m-table
              class="select-attendees__table"
              v-model="selectedUsers"
              :items="filteredUsers"
              :headers="headers"
              showSelect
              :sort-by.sync="sort.key"
              :sort-desc.sync="sort.desc"
              data-cy="select-attendees-table"
            >
              <template #item.name="{ item }">
                <div class="d-flex align-center">
                  <c-user-avatar-circle size="42" :user="item" />
                  <span class="table-text">
                    {{ item.name }}
                  </span>
                </div>
              </template>
              <template #item.company="{ item }">
                <span class="table-text">
                  {{ item.company }}
                </span>
              </template>
              <template #item.jobTitle="{ item }">
                <span class="table-text">
                  {{ item.jobTitle }}
                </span>
              </template>
              <template #item.host="{ item }">
                <m-switcher
                  data-cy="host-switch"
                  :input-value="item.host"
                  @change="addHost($event, item)"
                />
              </template>
            </m-table>
          </div>
        </m-table-view>
      </div>
    </template>
    <template v-slot:buttons>
      <m-button
        variant="secondary"
        :label="$t('ui.cancel')"
        style="height: 48px"
        @click="close"
        data-cy="cancelAttendeeModalButton"
      />
      <m-button
        :label="$t('spaces.create.addNUsers', { n: selectedUsers.length })"
        style="height: 48px"
        @click="addAttendees"
        :disabled="!selectedUsers.length"
        data-cy="add-attendees-button"
      />
    </template>
  </m-modal>
</template>
<script>
import { MButton, MModal, MTable, MTableView, MSearchInput, MSwitcher } from 'gatherings-storybook'

export default {
  components: {
    MButton,
    MModal,
    MSearchInput,
    MTable,
    MTableView,
    MSwitcher,
  },
  props: {
    ecosystem: {
      type: Object,
    },
    attendees: {
      type: Array,
    },
    show: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      searchTerm: '',
      headers: [
        {
          value: 'name',
          text: this.$t('spaces.attendeesTable.headers.name'),
        },
        {
          value: 'company',
          text: this.$t('spaces.attendeesTable.headers.company'),
        },
        {
          value: 'jobTitle',
          text: this.$t('spaces.attendeesTable.headers.jobTitle'),
        },
        {
          value: 'host',
          text: this.$t('spaces.attendeesTable.headers.host'),
        },
      ],
      sort: {
        key: 'name',
        desc: false,
      },
      availableUsers: [],
      selectedUsers: [],
    }
  },
  methods: {
    learnMoreAboutRoles() {
      window.open('', '_blank')
    },
    close() {
      this.selectedUsers = []
      this.searchTerm = ''
      this.$emit('close')
    },
    addAttendees() {
      this.$emit('addAttendees', this.selectedUsers)
      this.close()
    },
    addHost(event, item) {
      if (event) {
        if (this.selectedUsers.some(user => user.id === item.id)) {
          this.setHostValue(event, item)
        } else {
          // actives the checkbox for that item
          this.selectedUsers.push(item)
          this.setHostValue(event, item)
        }
      } else {
        this.setHostValue(event, item)
      }
    },
    setHostValue(event, item) {
      let indexOne = this.availableUsers.findIndex(attendee => attendee.id === item.id)
      let indexTwo = this.selectedUsers.findIndex(attendee => attendee.id === item.id)
      this.availableUsers[indexOne].host = event
      this.selectedUsers[indexTwo].host = event
    },
  },
  computed: {
    filteredUsers() {
      return this.availableUsers
        .map(item => ({
          ...item,
          name: `${item.givenName} ${item.surname}`,
        }))
        .filter(item => !this.attendees.find(attendee => attendee.id === item.id))
        .filter(item => item.name.toLocaleLowerCase().includes(this.searchTerm.toLocaleLowerCase()))
    },
  },
  watch: {
    show(newValue) {
      if (newValue) {
        this.availableUsers = this.ecosystem.users
      }
    },
    selectedUsers: {
      handler(newValue) {
        if (!newValue.length) {
          this.availableUsers.forEach(user => {
            user.host = false
          })
        }
        if (newValue.length) {
          this.availableUsers.filter(user => {
            let index = this.selectedUsers.findIndex(u => u.id === user.id)
            if (index === -1) {
              user.host = false
            }
          })
        }
      },
      deep: true,
    },
  },
}
</script>
<style lang="scss" scoped>
@import '~gatherings-storybook/src/styles/variables.scss';
.description-title {
  height: 24px;
  font-family: 'Mulish';
  font-style: normal;
  font-weight: 300;
  font-size: 18px;
  line-height: 24px;
  color: #464d54;
  margin-bottom: 2px;
}
.description-detail {
  font-family: 'Mulish';
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 20px;
  color: #807ef0;
  cursor: pointer;
}
.user-summary {
  font-family: 'Mulish';
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 24px;
  color: #1f2329;
  padding-top: 32px;
  padding-bottom: 24px;
}

.scrollable-table {
  overflow: scroll;
  max-height: 380px;
}

.table-text {
  font-family: 'Mulish';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: #1f2329;
}

.space-attendees {
  &__description {
    font-size: 18px;
    margin-top: 16px;
  }

  &__add {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 24px;
    padding: 87px 0;
    color: $black;
  }

  &__search {
    flex-grow: 1;
  }

  &__table {
    margin-top: 8px;
  }

  p {
    font-size: 16px;
    margin: 0;
  }

  &__img {
    max-width: 160px;
  }

  // Overwrite font-family
  @at-root {
    .v-application &:deep(*) {
      font-family: 'Mulish' !important;
    }
    .v-application .bulk-select-panel:deep(*) {
      font-family: 'Mulish' !important;
    }
  }
}
.v-application {
  .v-menu__content {
    .menu > .menu__footer > button > span {
      font-family: 'Mulish' !important;
    }
  }
  .v-dialog__content {
    .v-dialog > .modal {
      * {
        font-family: 'Mulish' !important;
      }

      .modal__top-container {
        .modal__header {
          font-weight: 400;
          font-size: 1.4286rem;
          line-height: 32px;
        }
      }
    }
  }
}
</style>

<style>
.select-attendees__table tbody tr {
  cursor: pointer;
}
</style>
