import account from './account'
import dashboard from './dashboard'
import files from './files'
import schedule from './schedule'
import spaces from './spaces'
import users from './users'
import ecosystems from './ecosystems'

const routes = [...dashboard, ...files, ...spaces, ...schedule, ...account, ...users, ...ecosystems]

export default routes
