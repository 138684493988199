<template>
  <v-row class="header-shadow" data-cy="dashboard-nav">
    <v-container :fluid="$vuetify.breakpoint.lgAndDown">
      <v-row>
        <v-col>
          <!-- Nav Header -->
          <v-row class="header-height align-center">
            <!-- Spacer col for small and up -->
            <v-col :cols="1" v-if="$vuetify.breakpoint.smAndUp"></v-col>

            <v-col :cols="11" :sm="9" class="d-flex">
              <!-- Spaces logo home button -->
              <div
                @click="$router.push('/')"
                data-cy="go-home"
                class="d-flex ml-2 mr-6 mr-lg-12 pointer align-center"
              >
                <c-logo />
              </div>

              <div class="d-flex align-center" v-if="$vuetify.breakpoint.smAndUp">
                <!-- Select ecosystem desktop -->
                <c-ecosystem-select />
              </div>
            </v-col>

            <!-- User menu -->
            <v-col :cols="1" class="mt-2 d-flex justify-end">
              <c-user-menu v-if="$vuetify.breakpoint.smAndUp" />
              <!-- Hamburger menu -->
              <div v-else>
                <v-icon @click="showMobileMenu = !showMobileMenu" size="40">mdi-menu</v-icon>
              </div>
            </v-col>
          </v-row>

          <!-- Mobile menu -->
          <v-expand-transition>
            <v-row v-show="showMobileMenu" @click="showMobileMenu = false">
              <v-col :cols="1" v-if="$vuetify.breakpoint.smAndUp"></v-col>
              <v-col>
                <!-- Select ecosystem -->
                <div class="ml-2 mb-2" v-if="myEcosystems.length > 0">
                  <v-menu
                    bottom
                    :close-on-content-click="false"
                    :nudge-bottom="40"
                    :nudge-left="20"
                    data-cy="select-ecosystem-menu-mobile"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <h3 class="primary--text menu-item" v-bind="attrs" v-on="on">
                        Select
                        <v-icon>mdi-menu-down</v-icon>
                      </h3>
                    </template>

                    <v-list>
                      <v-list-item
                        v-for="ecosystem in myEcosystems"
                        :key="ecosystem.id"
                        @click="$router.push({ path: '/ws/' + ecosystem.id })"
                      >
                        <v-list-item-title>{{ ecosystem.name }}</v-list-item-title>
                      </v-list-item>
                    </v-list>
                  </v-menu>
                </div>

                <v-list>
                  <v-list-item style="cursor: auto" class="pl-0">
                    <v-list-item-title data-cy="build-number">
                      Build #: {{ $buildVersion }}
                    </v-list-item-title>
                  </v-list-item>

                  <!-- Log out -->
                  <v-list-item style="cursor: auto" class="pl-0" @click="logOut">
                    <v-btn
                      text
                      style="text-transform: none"
                      class="pa-0 mt-2 primary--text"
                      data-cy="log-out-mobile"
                    >
                      <div class="ml-2 menu-item">Log out</div>
                    </v-btn>
                  </v-list-item>
                </v-list>
              </v-col>
            </v-row>
          </v-expand-transition>
        </v-col>
      </v-row>
    </v-container>
  </v-row>
</template>

<script>
export default {
  data() {
    return {
      showMobileMenu: false,
    }
  },
  computed: {
    myEcosystems() {
      return this.$store.getters['ecosystems/myEcosystems']
    },
  },
  methods: {
    logOut() {
      this.$store.dispatch('auth/forceLogout')
      this.$router.push('/account/login')
    },
  },
  mounted() {
    this.$store.dispatch('ecosystems/fetchMine', true)
  },
}
</script>
<style lang="scss" scoped>
.header-height {
  max-height: 80px;
}
.header-shadow {
  box-shadow: 0 0.25rem 0.75rem rgb(0 0 0 / 5%);
  border-bottom: 1px solid #dee2e6 !important;
  border-bottom-width: 1px !important;
  border-bottom-style: solid !important;
  border-bottom-color: rgb(222, 226, 230) !important;
}
.ecosystem-link {
  display: block;
  width: 100%;
  padding: 8px 1.5rem;
  clear: both;
  font-weight: 400;
  color: #212529;
  text-align: inherit;
  white-space: nowrap;
  background-color: transparent;
  border: 0;
  min-height: 30px;
}
.v-list-item .v-list-item__title,
.v-list-item .v-list-item__subtitle {
  font-size: 1.3rem;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  padding-left: 0.5rem;
}
.v-menu__content {
  box-shadow: none;
  border: 1px solid rgba(0, 0, 0, 0.15);
  border-radius: 0.25rem;
}
.hover-primary:hover {
  color: #807ef0;
}
.menu-item {
  color: #363332;
  font-size: 1.5em;
  font-weight: 300;
}
.select-ecosystem {
  font-family: Kiro;
  font-style: normal;
  font-weight: bold;
  font-size: 20px;
  line-height: 24px;
  color: #363333;
  width: 240px;
}
.select-menu {
  background: #f3efed;
  border-radius: 10px;
  padding: 8px 16px;
}
.v-menu__content {
  border-radius: 10px;
  box-shadow: 0px 4px 14px rgba(0, 0, 0, 0.25);
  border: none;
}
::v-deep .v-list-item:hover {
  background: #f3efed;
}
.select-list {
  padding-bottom: 0px;
}

.select-ecosystem {
  width: 202px;
  margin-top: -0.5rem;
  margin-right: 55px;

  :deep(.text-input__inner),
  :deep(.text-input__input) {
    cursor: pointer;
  }
}

.create-ecosystem {
  position: sticky;
  bottom: 0;
  background: #fff;
}
</style>
