<template>
  <div class="tab-container scrollbar">
    <div v-if="sortedAvatars.length > 0" class="avatar-list">
      <div
        v-for="avatar in sortedAvatars"
        :key="avatar.id"
        class="avatar-summary"
        :class="isSelected(avatar) ? 'selected-avatar' : null"
      >
        <div
          v-if="!isPending(avatar) && !isFailed(avatar)"
          :style="{ backgroundImage: `url(${avatar.thumbnail})` }"
          class="avatar-image"
        ></div>

        <div v-else class="no-avatar">
          <v-progress-circular
            v-if="isPending(avatar)"
            :size="60"
            :width="5"
            color="primary"
            :value="25"
            :rotate="rotation"
          ></v-progress-circular>
          <v-icon v-if="isFailed(avatar)" :size="30" color="primary"> mdi-close </v-icon>
        </div>

        <div class="avatar-summary-details" @click="toggleSelect(avatar)">
          <div class="d-flex justify-space-between">
            <div v-if="isFailed(avatar)" class="avatar-id">Avatar failed</div>
            <div v-else class="avatar-id">Avatar {{ shortId(avatar) }}</div>

            <div class="d-flex justify-end">
              <div class="selected-icon" v-if="isSelected(avatar)">
                <v-icon small dark size="24" class="text-right"> mdi-check </v-icon>
              </div>
            </div>
          </div>

          <div class="d-flex justify-space-between">
            <div class="avatar-time-and-date">{{ date(avatar) }}</div>
            <div class="avatar-time-and-date">{{ time(avatar) }}</div>
          </div>
        </div>
      </div>
    </div>
    <div v-else>
      <h3>No avatars to show</h3>
    </div>
  </div>
</template>

<script>
import { DateTime } from 'luxon'
const _ = require('lodash')
import { getNumberSuffix } from '../../utilities/functions'
export default {
  props: ['user'],
  data() {
    return {
      rotation: 0,
      interval: {},
      selectedAvatars: [],
    }
  },
  beforeDestroy() {
    clearInterval(this.interval)
  },
  mounted() {
    this.interval = setInterval(() => {
      if (this.rotation === 360) {
        return (this.rotation = 0)
      }
      this.rotation += 1
    }, 10)
  },
  methods: {
    isPending(avatar) {
      return avatar.thumbnail.includes('pending')
    },
    isFailed(avatar) {
      return avatar.thumbnail.includes('error')
    },
    shortId(avatar) {
      return avatar.id.split('-')[0]
    },
    date(avatar) {
      if (!avatar.created) {
        return ''
      }
      let dayOfMonth = DateTime.fromISO(avatar.created).toFormat('d')
      let ordinalSuffix = getNumberSuffix(dayOfMonth)
      let weekday = DateTime.fromISO(avatar.created).toFormat(`EEEE`)
      let month = DateTime.fromISO(avatar.created).toFormat(`MMMM`)
      return `${weekday} ${dayOfMonth}${ordinalSuffix} ${month}`
    },
    time(avatar) {
      if (!avatar.created) {
        return ''
      }
      return DateTime.fromISO(avatar.created).toFormat('HH:mm')
    },
    toggleSelect(avatar) {
      if (this.isSelected(avatar)) {
        // Remove
        const index = this.selectedAvatars.map(a => a.id).indexOf(avatar.id)
        this.selectedAvatars.splice(index, 1)
        return
      }

      // Add
      this.selectedAvatars.push(avatar)
    },
    isSelected(avatar) {
      return this.selectedAvatars.find(a => a.id === avatar.id) !== undefined
    },
  },
  computed: {
    sortedAvatars() {
      return this.user.avatars?.items?.length
        ? _.orderBy(this.user.avatars.items, 'created', 'desc')
        : []
    },
  },
}
</script>

<style lang="scss" scoped>
.avatar-summary {
  display: flex;
  justify-content: space-between;
  padding: 0px;
  background: #ffffff;
  border: 1.5px solid #dce2e9;
  border-radius: 8px;
  box-sizing: border-box;
}

.avatar-summary-details {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  place-content: space-between;
  padding: 16px;
}

.status-button {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 16px;
  width: 28px;
  height: 28px;
  border-radius: 50%;
}

.avatar-list {
  display: flex;
  flex-direction: column;
  gap: 16px;
}

.avatar-image {
  height: 116px;
  width: 116px;
  gap: 16px;
  background-size: 116px auto;
  object-fit: contain;
  background-color: #eeeeee;
  border-radius: 7px 0px 0px 7px;
  background-position-y: center;
}

.no-avatar {
  width: 116px;
  height: 116px;
  background: #f6f9fc;
  border-radius: 7px 0px 0px 7px;
  display: flex;
  justify-content: center;
  align-items: center;
}

::v-deep .v-progress-circular__underlay {
  stroke: #dce2e9;
}

.avatar-id {
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 24px;
  color: #1f2329;
}

.avatar-time-and-date {
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: #464d54;
  opacity: 0.8;
}

.selected-icon {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 24px;
  height: 24px;
  border-radius: 50%;
  background: #00a344;
}

.selected-avatar {
  border: 1.5px solid #807ef0;
}
</style>
