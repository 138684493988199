import i18n from '@/plugins/i18n'
import store from '@/plugins/store'
import { makeDefaultGetters, makeDefaultMutations } from '@/utilities/store'

const defaultState = () => ({
  show: false,
  confirmed: false,
  options: {
    title: null,
    message: null,
    subMessage: null,
    illustration: '/images/svgs/clipboard.svg',
    persistent: true,
    buttons: [
      {
        label: i18n.t('common.cancel'),
        type: 'cancel',
      },
      {
        label: i18n.t('common.confirm'),
        type: 'confirm',
      },
    ],
  },
})

const properties = () => Object.keys(defaultState())
const defaultGetters = makeDefaultGetters(properties())
const defaultMutations = makeDefaultMutations(properties(), defaultState())
const state = defaultState()

const getters = {
  ...defaultGetters,
}

const actions = {
  show({ commit }, options) {
    commit('setOptions', {
      ...state.options,
      ...options,
    })

    commit('setShow', true)

    return new Promise((resolve, reject) => {
      // Wait for the correct action before resolving/rejecting
      store.subscribeAction(action => {
        if (action.type === 'modal/confirm') {
          resolve()
        } else if (['modal/hide', 'modal/cancel'].includes(action.type)) {
          reject()
        }
      })
    })
  },
  hide({ commit }) {
    commit('setShow', false)
  },
  confirm({ commit }) {
    commit('setConfirmed', true)
    commit('setShow', false)
  },
  cancel({ commit }) {
    commit('setConfirmed', false)
    commit('setShow', false)
  },
}

const mutations = {
  ...defaultMutations,
}

export default {
  actions,
  getters,
  mutations,
  namespaced: true,
  state,
}
