import { pageTitle } from '@/utilities/page'
import i18n from '@/plugins/i18n'
import requirements from '@/routes/requirements'
import { setLayout } from '@/utilities/routes'

let routes = [
  {
    component: () => import(/* webpackChunkName: "ScheduleEvents" */ '@/views/schedule/Events.vue'),
    meta: {
      title: pageTitle(String(i18n.t('schedule.events.title'))),
      requires: requirements,
    },
    name: 'ScheduleEvents',
    path: '/ws/:ecosystemId/schedule/events',
    props: true,
  },
]

routes = setLayout(routes, 'default')

export default routes
