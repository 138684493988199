<template>
  <v-footer class="footer">
    <v-row>
      <v-col :cols="12" :sm="6" class="text-center mx-0 footerText">
        © Copyright {{ yearLastUpdated }} Mesmerise Global Limited. All rights reserved.
      </v-col>
      <v-col :cols="12" :sm="6" class="text-center footerText" data-cy="powered-by-link">
        <span>Powered by</span>
        <a
          :href="this.$links.mesmeriseGlobalLtd"
          target="_blank"
          style="text-decoration: underline"
        >
          <img
            src="/images/mesmerise-logo-grey.png"
            alt="Mesmerise"
            title="mesmerise"
            class="mesmerise-footer-logo"
          />
        </a>
      </v-col>
    </v-row>
  </v-footer>
</template>

<script>
export default {
  computed: {
    yearLastUpdated() {
      return process.env.VUE_APP_YEAR_LAST_UPDATED ?? new Date().getFullYear()
    },
  },
}
</script>

<style lang="scss" scoped>
.footerText {
  color: #898583;
  font-size: 15px;
}
.footer {
  padding-bottom: 50px;
  min-height: 100px;
}
.mesmerise-footer-logo {
  margin-left: 9px;
  max-width: 95px;
  opacity: 0.33;
  -webkit-opacity: 0.33;
  vertical-align: middle;
  margin-top: -2px;
}
@media screen and (min-width: 600px) {
  .footer {
    padding-bottom: 0px;
  }
}
</style>
