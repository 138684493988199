<template>
  <div style="width: 100%">
    <v-layout child-flex>
      <v-simple-table>
        <template v-slot:default>
          <thead>
            <tr>
              <th class="checkbox-column">
                <v-checkbox v-model="bulkSelect" @change="toggleSelectAll"></v-checkbox>
              </th>
              <th class="avatar-column">
                <!-- For avatar -->
              </th>
              <th class="table-header lg-column">{{ $t('fields.name') }}</th>
              <th class="table-header lg-column">
                {{ $t('fields.company') }}
                <v-btn class="sort-button" size="12" plain @click="sortBy('company')">
                  <img class="sort-icon" src="/images/sort-icon.png" alt="sort_by_company" />
                </v-btn>
              </th>
              <th class="table-header lg-column">
                {{ $t('fields.jobTitle') }}
                <v-btn class="sort-button" size="12" plain @click="sortBy('jobTitle')">
                  <img class="sort-icon" src="/images/sort-icon.png" alt="sort_by_company_role" />
                </v-btn>
              </th>
              <th class="table-header role-column">
                {{ $t('fields.role') }}
                <v-btn class="sort-button" size="12" plain @click="sortBy('userType')">
                  <img class="sort-icon" src="/images/sort-icon.png" alt="sort_by_role" />
                </v-btn>
              </th>
              <th>
                <!-- edit icon -->
              </th>
            </tr>
          </thead>

          <tbody v-if="usersChunk.length">
            <tr v-for="(user, index) in usersChunk" :key="user.id" class="table-text clickable">
              <td class="checkbox-column" :class="{ 'last-row': index + 1 == users.length }">
                <v-checkbox
                  v-model="user.checkBox.value"
                  @change="checked($event, user)"
                ></v-checkbox>
              </td>

              <td
                class="avatar-column"
                :class="{ 'last-row': index + 1 == users.length }"
                @click="$router.push(`/ws/${ecosystemId}/users/${user.id}`)"
              >
                <c-user-avatar-circle :user="user" />
              </td>

              <td class="lg-column" :class="{ 'last-row': index + 1 == users.length }">
                <router-link :to="`/ws/${ecosystemId}/users/${user.id}`" class="view-user-link">
                  <div class="user-name" :data-cy="`user-name-${user.givenName}-${user.surname}`">
                    {{ fullName(user) }}
                  </div>
                  <div class="user-email">{{ user.email }}</div>
                </router-link>
              </td>

              <td
                class="lg-column"
                :class="{ 'last-row': index + 1 == users.length }"
                @click="$router.push(`/ws/${ecosystemId}/users/${user.id}`)"
              >
                {{ user.company }}
              </td>

              <td
                class="lg-column"
                :class="{ 'last-row': index + 1 == users.length }"
                @click="$router.push(`/ws/${ecosystemId}/users/${user.id}`)"
              >
                {{ user.jobTitle }}
              </td>

              <td
                class="role-column"
                :class="{ 'last-row': index + 1 == users.length }"
                @click="$router.push(`/ws/${ecosystemId}/users/${user.id}`)"
              >
                {{ userRole(user) }}
              </td>

              <td :class="{ 'last-row': index + 1 == users.length }">
                <v-btn plain @click="edit(user)">
                  <img class="edit-icon" src="/images/edit.png" alt="edit" />
                </v-btn>
              </td>
            </tr>
          </tbody>
        </template>
      </v-simple-table>
    </v-layout>

    <v-divider class="mb-5"></v-divider>
    <m-pagination
      :pageNumber="pageNumber"
      :total="totalUsers"
      :perPage="usersPerPage"
      v-on:prev="pageNumber--"
      v-on:next="pageNumber++"
      v-on:perPage="changeSize"
    />
  </div>
</template>

<script>
const _ = require('lodash')
import { MPagination } from 'gatherings-storybook'
export default {
  components: {
    MPagination,
  },
  props: ['users', 'selectAll', 'ecosystemId'],
  data() {
    return {
      usersPerPage: 10,
      pageNumber: 1,
      sortingBy: { type: 'email', direction: 'desc' },
      bulkSelect: false,
    }
  },
  methods: {
    userRole(user) {
      // user 0/admin 2/guest 4
      return user.userType == 4 ? 'Guest' : user.userType == 2 ? 'Admin' : 'User'
    },
    fullName(user) {
      return `${user.givenName} ${user.surname}`
    },
    edit(user) {
      this.$emit('edit-user', user)
    },
    sortBy(type) {
      this.sortingBy = {
        type: type,
        direction: this.sortingBy?.direction == 'asc' ? 'desc' : 'asc',
      }
    },
    changeSize(size) {
      this.usersPerPage = size

      if (size >= this.users.length) {
        this.pageNumber = 1
      }
      // If the new page size means that the previous page number is no longer valid
      // Set the page number to the last available page number
      if (this.pageNumber > this.numberOfPages) {
        this.pageNumber = this.numberOfPages
      }
    },
    checked(value, user) {
      this.$emit('userChecked', { user, value })
    },
    toggleSelectAll(value) {
      this.$emit('toggleSelectAll', value)
    },
  },
  computed: {
    usersChunk() {
      let chunk = []
      let users = _.cloneDeep(this.users)
      let directions = this.sortingBy.direction == 'desc' ? ['asc', 'desc'] : ['desc', 'asc']
      let type = this.sortingBy.type

      users = _.orderBy(
        users,
        [
          function (user) {
            let dataType = typeof user[type]
            if (dataType === 'number') {
              return user[type] ? user[type] : null
            }
            return user[type] ? user[type]?.trim().toLowerCase() : ''
          },
        ],
        directions
      )

      if (users.length <= this.usersPerPage) {
        chunk = users
      } else {
        chunk = users.slice(this.chunkStart, this.chunkEnd)
      }

      return chunk
    },
    chunkStart() {
      return this.usersPerPage * this.pageNumber - this.usersPerPage
    },
    chunkEnd() {
      return this.chunkStart + this.usersPerPage
    },
    numberOfPages() {
      if (this.users.length < this.usersPerPage) {
        return 1
      }
      let result = this.users.length / this.usersPerPage
      let remainder = this.users.length % this.usersPerPage

      return remainder === 0 ? result : (this.users.length - remainder) / this.usersPerPage + 1
    },
    pageSizes() {
      if (this.users.length > 50) return [5, 10, 25, 50, 100]
      if (this.users.length > 25) return [5, 10, 25, 50]
      if (this.users.length > 10) return [5, 10, 25]
      if (this.users.length > 5) return [5, 10]
      return [5]
    },
    totalUsers() {
      return this.users.length
    },
  },
  watch: {
    users: {
      handler(newValue, oldValue) {
        this.users = newValue
        if (newValue.length != oldValue.length) {
          this.pageNumber = 1
        }
      },
      deep: true,
    },
    selectAll(value) {
      this.bulkSelect = value
    },
  },
}
</script>

<style lang="scss" scoped>
.table-header {
  font-style: normal !important;
  font-weight: 700 !important;
  font-size: 14px !important;
  line-height: 20px !important;
  color: #95a1aa !important;
  text-align: left;
  word-wrap: break-word;
}

::v-deep .v-data-table > .v-data-table__wrapper > table {
  min-width: 960px;
}

.checkbox-column {
  width: 40px;
}

.avatar-column {
  width: 72px;
  padding: 0px 0px 0px 24px !important;
}

.lg-column {
  width: 33%;
}

.role-column {
  width: 130px;
  min-width: 130px;
}

.sort-button {
  min-width: 0px !important;
  padding: 0px !important;
}

.theme--light.v-data-table
  > .v-data-table__wrapper
  > table
  > tbody
  > tr:not(:last-child)
  > td:not(.v-data-table__mobile-row),
.theme--light.v-data-table
  > .v-data-table__wrapper
  > table
  > tbody
  > tr:not(:last-child)
  > th:not(.v-data-table__mobile-row) {
  border: none;
}

.theme--light.v-data-table > .v-data-table__wrapper > table > thead > tr:last-child > th {
  border: none;
}

td {
  border-top: solid 1px #e8eef3 !important;
}

.last-row {
  border-bottom: solid 1px #e8eef3;
}

.user-name {
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: #1f2329;
  word-break: break-all;
}

.user-email {
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: #464d54;
  word-break: break-all;
}

.table-text td {
  font-style: normal !important;
  font-weight: 400 !important;
  font-size: 16px !important;
  line-height: 24px !important;
  color: #1f2329 !important;
}

.edit-icon {
  height: 17px;
  width: 17px;
}

.sort-icon {
  height: 12px;
  width: 12px;
}

.totals-text {
  padding-top: 34px;
  padding-bottom: 14px;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: #69737a;
}

.pagination-controls {
  display: flex;
  gap: 8px;
}

.pagination-control-button {
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 12px !important;
  gap: 8px;
  min-width: none !important;
  width: 48px !important;
  height: 48px !important;
  background: #ffffff !important;
  border: 1px solid #807ef0 !important;
  border-radius: 8px !important;
  box-shadow: none !important;
}

.disabled-control-button {
  background: #e8eef3 !important;
  border: 1px solid #c4cdd5 !important;
}

.page-size-button {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 12px 12px 12px 16px;
  gap: 6px;

  width: 130px !important;
  height: 48px !important;
  border: 1px solid #dce2e9 !important;
  border-radius: 8px !important;
  box-shadow: none !important;

  text-transform: none;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: #95a1aa;
}

.v-menu__content {
  border: 1px solid #dce2e9;
  border-radius: 8px;
}

.menu-list {
  background: #ffffff;
  box-shadow: 0px 10px 30px rgba(12, 34, 68, 0.1);
}

.menu-item-text {
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  display: flex;
  align-items: center;
  color: #1f2329;
}

.theme--light.v-list-item:hover::before {
  background-color: #807ef0;
  opacity: 0.125;
}

.view-user-link {
  text-decoration: none;
  color: #1f2329 !important;
}
</style>
