import cloneDeep from 'lodash/cloneDeep'
import Vue from 'vue'
import { makeDefaultGetters, makeDefaultMutations, setState } from '@/utilities/store'
import api from '@/utilities/api'
import blobToDataUrl from '@/utilities/blobToDataUrl'

const defaultState = () => ({
  active: null,
  all: [],
})

const properties = () => Object.keys(defaultState())
const defaultGetters = makeDefaultGetters(properties())
const defaultMutations = makeDefaultMutations(properties(), defaultState())
const state = defaultState()

const getters = {
  ...defaultGetters,
}

const actions = {
  fetch({ commit }) {
    const endpoint = `users/avatars`

    return api(endpoint).then(response => {
      commit('setActiveAvatar', response.data.data.active)
      commit('setAllAvatars', response.data.data)
    })
  },
  async avatarStyle(
    { commit },
    { userId, avatarId, style = 'previewIconHeadonlyT', setUserMenuAvatar = false }
  ) {
    const endpoint = `users/${userId}/avatars/${avatarId}/${style}.png`

    try {
      const { data } = await api(
        endpoint,
        { headers: { Accept: 'image/png' }, json: false },
        { responseType: 'blob' }
      )

      const response = await blobToDataUrl(data)
      if (setUserMenuAvatar) {
        commit('setActiveAvatarURL', response)
      }

      return response
    } catch (e) {
      console.error(e)
      return
    }
  },
}

const mutations = {
  ...defaultMutations,
  loadInitialStateData(state, data) {
    if (!data || typeof data !== 'object') return
    Object.keys(data).forEach(k => {
      Vue.set(state, k, cloneDeep(data[k]))
    })
  },
  resetState: state => setState(state, defaultState(), true),
  setActiveAvatar(state, avatar) {
    state.active = { ...avatar, retrieved_at: +new Date() }
  },
  setAllAvatars(state, avatars) {
    state.all = avatars.items
    state.active = { ...avatars.active, retrieved_at: +new Date() }
  },
  setActiveAvatarURL(state, url) {
    state.active.url = url
  },
}

export default {
  actions,
  getters,
  mutations,
  namespaced: true,
  state,
}
