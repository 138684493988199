import cloneDeep from 'lodash/cloneDeep'
import Vue from 'vue'
import { makeDefaultGetters, makeDefaultMutations, setState } from '@/utilities/store'
import api from '@/utilities/api'

const defaultState = () => ({
  templates: {},
})

const properties = () => Object.keys(defaultState())
const defaultGetters = makeDefaultGetters(properties())
const defaultMutations = makeDefaultMutations(properties(), defaultState())
const state = defaultState()

const getters = {
  ...defaultGetters,
}

const actions = {
  fetchStyles({ commit }, { templateId }) {
    let options = { method: 'GET' }
    const endpoint = `templates/${templateId}/style`
    return api(endpoint, options).then(response => {
      const styles = response.data.data
      commit('setStyles', { templateId, styles })
      return styles
    })
  },
}

const mutations = {
  ...defaultMutations,
  loadInitialStateData(state, data) {
    if (!data || typeof data !== 'object') return
    Object.keys(data).forEach(k => {
      Vue.set(state, k, cloneDeep(data[k]))
    })
  },
  resetState: state => setState(state, defaultState(), true),
  setStyles(state, { templateId, styles }) {
    state.templates[templateId] = styles
  },
}

export default {
  actions,
  getters,
  mutations,
  namespaced: true,
  state,
}
