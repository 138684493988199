<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 57.24 57.24"
    style="width: 44px; margin-bottom: -4px"
  >
    <g id="logo_layer_2" data-name="logo_layer 2">
      <g id="logo_layer_1-2" data-name="logo_layer 1">
        <g id="Group_67" data-name="Group 67">
          <circle
            id="Ellipse_68"
            data-name="Ellipse 68"
            class="logo-circle"
            cx="22.14"
            cy="22.14"
            r="22.14"
          ></circle>
          <path
            id="Path_762"
            data-name="Path 762"
            class="logo-circle-g"
            d="M22.14,13.08a9.06,9.06,0,0,0-9.06,9.06h-6A15.1,15.1,0,0,1,22.15,7Zm0,9.06v4.41H30a9,9,0,0,1-7.9,4.65v6a15.1,15.1,0,0,0,15.1-15.1Z"
          ></path>
        </g>
      </g>
    </g>
  </svg>
</template>

<script>
export default { props: {} }
</script>

<style scoped>
.cLogo {
  max-height: 150px;
}
.logo-circle {
  fill: #807ef0;
}

.logo-circle-g {
  fill: #fff;
}

.logo-text-path {
  fill: #bdb6b4;
}
</style>
