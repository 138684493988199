import { pageTitle } from '@/utilities/page'
import i18n from '@/plugins/i18n'
import requirements from '@/routes/requirements'
import { setLayout } from '@/utilities/routes'

let routes = [
  {
    component: () => import(/* webpackChunkName: "SpacesList" */ '@/views/spaces/List.vue'),
    meta: {
      title: pageTitle(String(i18n.t('spaces.titles.list'))),
      requires: requirements,
    },
    name: 'List',
    path: '/ws/:ecosystemId/spaces',
    props: true,
  },
  {
    component: () => import(/* webpackChunkName: "SpacesCreate" */ '@/views/spaces/Create.vue'),
    meta: {
      title: pageTitle(String(i18n.t('spaces.titles.create'))),
      requires: requirements,
    },
    name: 'Create',
    path: '/ws/:ecosystemId/spaces/create',
    props: true,
  },
  {
    component: () => import(/* webpackChunkName: "SpacesEdit" */ '@/views/spaces/Edit.vue'),
    meta: {
      title: pageTitle(String(i18n.t('spaces.titles.edit'))),
      requires: requirements,
    },
    name: 'Edit',
    path: '/ws/:ecosystemId/spaces/:spaceId/edit',
    props: true,
  },
]

routes = setLayout(routes, 'default')

export default routes
