<template>
  <v-app v-bind:class="{ loading: isLoading }" class="">
    <v-main>
      <c-errors></c-errors>
      <c-snackbar></c-snackbar>
      <c-loading-bar></c-loading-bar>

      <v-row class="align-self-center my-6 formCenter">
        <v-col>
          <v-card flat class="text-center pa-2">
            <slot />
          </v-card>
        </v-col>
      </v-row>
      <c-footer
        style="background-color: #e8e0dd; padding-top: 20px; padding-bottom: 20px"
      ></c-footer>
    </v-main>
  </v-app>
</template>

<script>
export default {
  computed: {
    isLoading() {
      return this.$store.getters['ui/loading'].isLoading
    },
  },
}
</script>

<style lang="scss" scoped>
.formCenter {
  height: 100%;
  ::v-deep .cForm {
    max-width: 500px;
    margin: auto;
  }
}
.loading {
  cursor: progress;
}
</style>
