<template>
  <div class="small-card-container">
    <router-link :to="`/ws/${ecosystemId}/users/${user.id}`" class="view-user-link">
      <div class="d-flex align-start">
        <c-user-avatar-circle :user="user" />
        <div>
          <div class="user-name" :data-cy="`user-name-${user.givenName}-${user.surname}`">
            {{ user.givenName }} {{ user.surname }}
          </div>
          <div v-if="isPresenter(user)" class="presenter-label">Presenter</div>
          <div class="user-email">{{ user.email }}</div>
        </div>
      </div>
    </router-link>
    <div class="action-container">
      <div class="text-center">
        <v-simple-checkbox
          color="primary"
          v-model="user.checkBox.value"
          @input="checked($event, user)"
        />
      </div>
      <div class="edit" @click="edit(user)">Edit</div>
    </div>
  </div>
</template>
<script>
export default {
  props: {
    user: {
      type: Object,
    },
    ecosystemId: {
      type: String,
    },
  },
  data() {
    return {}
  },
  methods: {
    edit(user) {
      this.$emit('edit-user', user)
    },
    isPresenter(user) {
      return user.isPresenter
    },
    checked(value, user) {
      this.$emit('userChecked', { user, value })
    },
  },
}
</script>
<style lang="scss" scoped>
.small-card-container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 16px;
  gap: 8px;
  background: #ffffff;
  border: 1px solid #dce2e9;
  border-radius: 12px;
}

.edit {
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 20px;
  cursor: pointer;
  text-align: right;
  color: #807ef0;
  padding: 5px 0px 5px 5px;
}

.action-container {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-end;
}

.v-application--is-ltr .v-input--selection-controls__input {
  margin-right: 0px;
}

.v-simple-checkbox .v-icon {
  color: #c4cdd4;
}

.user-name {
  font-style: normal;
  font-weight: 700;
  font-size: 18px;
  line-height: 24px;
  color: #1f2329;
  margin-bottom: 4px;
}

.user-email {
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: #69737a;
}

.presenter-label {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  font-style: normal;
  padding: 4px 8px 6px;
  gap: 6px;
  width: 62px;
  height: 22px;
  background: #f8f8ff;
  border-radius: 6px;
  font-weight: 700;
  font-size: 10px;
  line-height: 12px;
  display: flex;
  align-items: center;
  font-feature-settings: 'pnum' on, 'lnum' on;
  color: #807ef0;
  margin-bottom: 2px;
}

.view-user-link {
  text-decoration: none;
  color: #1f2329 !important;
}
</style>
