<template>
  <m-modal
    :title="deleteModal.title"
    :message="deleteModal.message"
    :subMessage="deleteModal.subMessage"
    v-model="deleteModal.show"
    v-on:close="
      deleteModal.show = false
      deleteModal.items = null
    "
    icon="/images/delete.png"
    :width="$vuetify.breakpoint.smAndUp ? '610px' : undefined"
    :isLoading="$store.getters['ui/loading'].isLoading"
  >
    <template v-slot:buttons>
      <m-button
        variant="secondary"
        :label="$t('common.cancel')"
        @click="deleteModal.show = false"
        :disabled="$store.getters['ui/loading'].isLoading"
      />
      <m-button
        error
        :label="$t('common.delete')"
        @click="$emit('delete', deleteModal.items)"
        :disabled="$store.getters['ui/loading'].isLoading"
        data-cy="delete-items"
      />
    </template>
  </m-modal>
</template>
<script>
import { MModal, MButton } from 'gatherings-storybook'

export default {
  props: ['deleteModal'],
  components: {
    MModal,
    MButton,
  },
}
</script>
<style scoped lang="scss"></style>
