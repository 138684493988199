<template>
  <div class="list-card" :class="shadow" :data-cy="`list-card-${user.id}`">
    <!-- Image - either a real avatar or a placeholder icon -->
    <div v-if="isRealAvatar">
      <div
        v-if="avatarLoaded"
        id="avatar"
        @click="goToUser"
        class="frame image-container flex-grow-0"
        :style="{
          backgroundImage: 'url(' + storedUser.activeAvatarThumbnail + ')',
        }"
      ></div>
      <div v-else class="d-flex justify-center align-center image-container">
        <v-progress-circular indeterminate color="grey" size="64"></v-progress-circular>
      </div>
    </div>
    <div v-else @click="goToUser" class="image-container initials-container flex-grow-0">
      <div class="initials">{{ userInitials }}</div>
    </div>

    <!-- Just to load the image behind the scenes - @load does not work with background images -->
    <img
      class="d-none"
      :src="user.activeAvatarThumbnail"
      alt="image-loader"
      @load="avatarLoaded = true"
    />

    <!-- User details -->
    <div
      class="d-flex flex-grow-1 flex-column justify-start align-center pa-1 mt-1 mt-md-2 mt-lg-3 mt-xl-4"
    >
      <!-- Checkbox switch row -->
      <div class="width-100 mb-1 mb-sm-2 mb-md-3 mb-lg-4">
        <div
          class="d-flex justify-space-between align-center"
          v-if="(uSwitch && uSwitch.show) || (uCheckBox && uCheckBox.show)"
        >
          <!-- Switch -->
          <div class="d-flex justify-space-between align-center" v-if="uSwitch.show">
            <div class="host mx-2 mx-xl-3">Host</div>
            <v-switch
              v-model="uSwitch.value"
              @change="switched"
              inset
              dense
              hide-details
              :data-cy="`${user.id}-isHost-switch`"
            ></v-switch>
          </div>
          <div v-else></div>

          <!-- Checkbox -->
          <v-checkbox
            v-if="uCheckBox.show"
            @change="checked"
            v-model="uCheckBox.value"
            hide-details
            :ripple="false"
            class="mr-2 mr-xl-3 mb-0 pt-0 d-flex align-center justify-center"
            :data-cy="`${user.id}-isSelected`"
          ></v-checkbox>
          <div v-else></div>
        </div>
      </div>

      <div
        @click="goToUser"
        class="user-name px-2 mb-2 mb-sm-3 mb-lg-5 text-center"
        :data-cy="`user-name-${storedUser.givenName}-${storedUser.surname}`"
      >
        {{ storedUser.givenName }} {{ storedUser.surname }}
      </div>

      <div
        @click="goToUser"
        class="user-email px-2 mb-2 mb-sm-3 mb-lg-5 text-center"
        :data-cy="`user-email-${storedUser.email}`"
      >
        {{ storedUser.email }}
      </div>

      <div class="detail-text font-weight-light mb-2 mb-sm-3 mb-lg-5">
        <span v-if="storedUser.jobTitle">{{ storedUser.jobTitle }}</span>
        <span class="light" v-if="storedUser.jobTitle && storedUser.company"> at </span>
        <span v-if="storedUser.company">{{ storedUser.company }}</span>
        <span v-else>-</span>
      </div>

      <div class="mb-2 mb-sm-3 mb-lg-5">
        <v-chip
          class="uppercase role"
          :color="chipColor"
          :text-color="chipTextColor"
          :data-cy="`user-role-${storedUser.givenName}-${storedUser.surname}`"
        >
          {{ role }}
        </v-chip>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  props: {
    user: {
      type: Object,
      default: function () {
        return {}
      },
    },
    ecosystemId: {
      type: String,
    },
    switch: {
      type: Object,
      default: function () {
        return {
          show: false,
          value: false,
        }
      },
    },
    checkBox: {
      type: Object,
      default: function () {
        return {
          show: false,
          value: false,
        }
      },
    },
    hasLink: {
      type: Boolean,
      default: false,
    },
    shadow: {
      type: String,
      default: 'shadow',
    },
  },
  computed: {
    chipColor() {
      return this.role === 'Admin' ? '#FDEAEA' : this.role == 'Guest' ? '#E6FBF5' : '#FDF7E0'
    },
    chipTextColor() {
      return this.role === 'Admin' ? '#F25C5C' : this.role == 'Guest' ? '#34D3A6' : '#F2BE04'
    },
    role() {
      let role = 'User'
      switch (this.storedUser.userType) {
        case 2:
          role = 'Admin'
          break
        case 4:
          role = 'Guest'
          break

        default:
          role = 'User'
          break
      }
      return role
    },
    userInitials() {
      if (
        this.storedUser?.givenName &&
        this.storedUser?.givenName.length &&
        this.storedUser?.surname &&
        this.storedUser?.surname.length
      ) {
        return `${this.storedUser.givenName[0]}${this.storedUser.surname[0]}`.toUpperCase()
      }
      return ''
    },
    isRealAvatar() {
      return (
        this.storedUser.activeAvatarThumbnail &&
        this.storedUser.activeAvatarThumbnail.length > 0 &&
        !this.storedUser.activeAvatarThumbnail.includes('/img/')
      )
    },
    storedUser() {
      return (
        this.$store.getters['ecosystems/getUserById'](this.ecosystemId, this.user.id) ?? this.user
      )
    },
  },
  data() {
    return {
      uSwitch: undefined, //Can't call this 'switch' because that is a reserved word
      uCheckBox: undefined,
      avatarLoaded: false,
    }
  },
  methods: {
    goToUser() {
      if (this.hasLink) {
        let path = `/ws/${this.ecosystemId}/users/${this.storedUser.id}`
        this.$router.push(path)
      }
    },
    checked(value) {
      this.$emit('checked', { value, user: this.storedUser })
    },
    switched(value) {
      this.$emit('switched', { value, user: this.storedUser })
    },
  },
  mounted() {
    this.uCheckBox = this.checkBox
    this.uSwitch = this.switch
  },
  watch: {
    checkBox: {
      handler(newCheckBoxConfig) {
        this.uCheckBox = newCheckBoxConfig
      },
      deep: true,
    },
  },
}
</script>
<style scoped lang="scss">
.frame {
  background: transparent no-repeat center;
  background-size: cover;
}
.initials-container {
  background-color: #f3efed;
  display: flex;
  justify-content: center;
  align-content: center;
}
.initials {
  font-family: Kiro;
  font-style: normal;
  font-weight: bold;
  font-size: 44px;
  line-height: 150%;
  display: flex;
  align-items: center;
  text-align: center;
  color: #363332;
  opacity: 0.5;
}
.shadow {
  box-shadow: 0px 10px 30px rgba(128, 126, 240, 0.1);
  transition: box-shadow 0.3s;

  &:hover {
    box-shadow: 0px 10px 30px rgba(128, 126, 240, 0.3);

    .user-name {
      color: #807ef0;
    }
  }

  .user-name,
  .user-email,
  .image-container {
    cursor: pointer;
  }
}
.lighter-shadow {
  box-shadow: 0px 10px 30px rgba(128, 126, 240, 0.1);
}

.list-card {
  background: #ffffff;
  border: 1px solid #dfe2e6;
  box-sizing: border-box;
  border-radius: 20px;
  display: flex;
  height: auto;
  width: 320px;
  display: flex;
  flex-direction: column;
  margin: 20px;
}

.image-container {
  border-radius: 20px 20px 0 0px;
  height: 250px;
}

.user-name,
.user-email {
  font-family: Kiro;
  font-style: normal;
  font-weight: bold;
  color: #363332;
  font-size: 22px;
  line-height: 24px;
}

.user-name {
  margin-top: 10px;
  transition: color 0.3s;
}

.user-email {
  font-weight: normal;
  font-size: 16px;
  color: #b0bbcb;
  margin-top: -10px;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  max-width: calc(100% - 40px);
}

@media screen and (min-width: 1260px) {
  .user-email {
    margin-top: -20px;
  }
}

.detail-text {
  font-family: Kiro;
  font-style: normal;
  font-size: 14px;
  line-height: 18px;
  text-align: center;
  max-width: 280px;

  .light {
    color: #b0bbcb;
  }
}

.role {
  font-size: 16px;
  line-height: 18px;
}

.host {
  font-size: 12px !important;
  color: #363333;
}

.v-input--selection-controls {
  margin-top: 0px;
}

::v-deep .v-input__slot {
  min-height: 10px !important;
}

#avatar.loading {
  background-image: '/images/loading.gif';
}
</style>
