<template>
  <div class="d-flex align-center">
    <div class="calendar-icon">
      <img width="20px" src="/images/calendar.png" alt="Date picker" />
    </div>
    <div class="date-time-input">
      <v-menu
        ref="date-picker"
        v-model="dateMenu"
        :close-on-content-click="false"
        transition="scale-transition"
        offset-y
        max-width="290px"
        min-width="auto"
        top
        class="picker"
      >
        <template v-slot:activator="{ on, attrs }">
          <div
            class="date date-time-text"
            :class="{ activeDate: dateMenu || dateIsSet }"
            v-bind="attrs"
            @blur="date = parseDate(dateFormatted)"
            v-on="on"
          >
            {{ dateFormatted }}
          </div>
        </template>

        <v-date-picker
          color="primary"
          v-model="date"
          no-title
          @input="dateMenu = false"
          :weekday-format="formatCalendarHeader"
        />
      </v-menu>

      <v-menu
        top
        v-model="timeMenu"
        :close-on-content-click="true"
        :nudge-top="60"
        data-cy="time-select"
      >
        <template v-slot:activator="{ on, attrs }">
          <div v-bind="attrs" v-on="on">
            <div class="time date-time-text" :class="{ activeTime: timeMenu || timeIsSet }">
              {{ time }}
            </div>
          </div>
        </template>

        <v-list class="time-menu">
          <v-list-item
            v-for="(interval, index) in fifteenMinuteIntervals"
            :key="index"
            class="clickable"
            :class="{ selected: time === interval }"
            @click="setTime(interval)"
          >
            <v-list-item-title
              class="d-flex justify-space-between align-center interval-text"
              :class="{ selected: time === interval }"
            >
              {{ interval }}
            </v-list-item-title>
          </v-list-item>
        </v-list>
      </v-menu>
    </div>
  </div>
</template>

<script>
import { DateTime } from 'luxon'
import { fifteenMinuteIntervals } from '@/utilities/time'
export default {
  data() {
    return {
      date: new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
        .toISOString()
        .substr(0, 10),
      dateFormatted: 'Select date',
      dateMenu: false,
      timeMenu: false,
      time: 'Select time',
      fifteenMinuteIntervals: fifteenMinuteIntervals(),
      timeIsSet: false,
      dateIsSet: false,
    }
  },
  computed: {
    computedDateFormatted() {
      return this.formatDate(this.date)
    },
  },

  methods: {
    formatDate(date) {
      if (!date) return null
      let format = 'EEEE, MMM d' // e.g. Wednesday, Aug 9
      return DateTime.fromFormat(date, 'yyyy-MM-dd').toFormat(format)
    },
    parseDate(date) {
      if (!date) return null
      const [month, day, year] = date.split('/')
      return `${year}-${month.padStart(2, '0')}-${day.padStart(2, '0')}`
    },
    setTime(time) {
      this.timeIsSet = true
      this.time = time
    },
    formatCalendarHeader(date) {
      return DateTime.fromFormat(date, 'yyyy-MM-dd').toFormat('EEE')
    },
  },
  watch: {
    date(value) {
      this.dateFormatted = this.formatDate(this.date)
      this.dateIsSet = true
      this.$emit('date', value)
    },
    time(value) {
      this.$emit('time', value)
    },
  },
}
</script>
<style lang="scss">
.date-time-input {
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 6px;
  gap: 4px;
  width: 284px;
  height: 48px;
  background: #ffffff;
  border: 1px solid #dce2e9;
  border-radius: 8px;
}

.calendar-icon {
  width: 20px;
  height: 20px;
  margin-right: 16px;
}

.date {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: flex-start;
  padding: 6px 10px;
  gap: 16px;
  width: 174px;
  height: 36px;
  border-radius: 6px;
}

.date-time-text {
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: #95a1aa;
  text-transform: none;
}

.activeDate {
  background-color: #f8f8ff;
  color: #1f2329;
}

.activeTime {
  background-color: #f8f8ff;
  color: #1f2329;
}

.time {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 6px 10px;
  gap: 3px;
  width: 94px;
  height: 36px;
  border-radius: 6px;
}

.time-menu {
  height: 230px;
  width: 180px;
  overflow: scroll;
}

.selected {
  background-color: #807ef0;
  color: white !important;
}

.interval-text {
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  display: flex;
  align-items: center;
  color: #1f2329;
}

.v-date-picker-header__value button {
  font-style: normal !important;
  font-weight: 700 !important;
  font-size: 16px !important;
  line-height: 24px !important;
  color: #1f2329 !important;
}

.v-menu__content {
  background: #ffffff;
  border: 1px solid #dce2e9;
  box-shadow: 0px 10px 25px rgba(12, 34, 68, 0.12);
  border-radius: 8px;
}

.v-picker .v-btn--rounded {
  border-radius: 4px !important;
}
</style>
