export default {
  ecosystems: {
    titles: {
      create: 'Create ecosystem',
      view: 'View ecosystem',
      edit: 'Edit ecosystem',
    },
    create: {
      create: 'Create',
      ecosystem: 'Ecosystem',
      ecosystemName: 'Ecosystem name',
      createAEcosystemForYourCompanyOrTeam: 'Create a ecosystem for your company or team',
      button: 'Create ecosystem',
      selectATimezoneForYourEcosystem: 'Select a timezone for your ecosystem',
      errors: {
        name: 'The ecosystem name should be between 3 and 26 characters long and should only contain letters, numbers, hyphens and spaces',
      },
      settingUpBackend: 'Setting up backend',
      uploadingFiles: 'Uploading files',
      updatingEcosystem: 'Updating ecosystem',
      ecosystemHasNotBeenCreated: 'Ecosystem has not been created',
      thereHasBeenAnIssueProcessingYourRequestPleaseTryAgain:
        'There has been an issue processing your request - please try again.',
      theseImagesMustBeTransparent: 'These images must have a transparent background',
      generalDetails: 'General details',
      branding: 'Branding',
      colors: 'Colors',
      logo: 'Logo',
      banners: 'Banners',
      primaryColor: 'Primary color',
      secondaryColor: 'Secondary color',
      dropzoneLabel: 'Drag and drop image or {0} for a file to upload',
      browse: 'browse',
      name: 'Ecosystem name',
      timeZone: 'Ecosystem original timezone',
      namePlaceholder: 'Enter ecosystem name',
      selectFile: 'Select file',
      modal: {
        illustrationAlt: {
          ecosystemBeingCreated: 'Ecosystem being created',
          thereWasAProblem: 'There was a problem',
          setupComplete: 'Setup complete!',
        },
        title: {
          setupComplete: 'Setup complete!',
          weHaveHadAProblem: "We've had a problem",
          buildingEcosystem: 'Building Ecosystem...',
        },
        subTitle: {
          yourEcosystemHasbeenSuccessfullyCreated:
            'Your ecosystem has been successfully created. We have built 6 spaces to get you started',
          ecosystemCreatedBrandingNotApplied:
            'Your ecosystem has been successfully created but your branding has not applied.',
          gettingVRReadyForYou: "We're getting VR ready for you",
        },
        button: {
          exploreSpaces: 'Explore spaces',
          manageEcosystem: 'Manage ecosystem',
        },
      },
    },
    view: {
      gatherings: 'Gatherings',
      administration: 'Administration',
    },
    edit: {
      manage: 'Manage',
      ecosystem: 'Ecosystem',
      hereYouCanManageYourEcosystemAndChangeItsMainSettings:
        'Here you can manage your ecosystem and change its main settings',
      fileTooLarge: 'This file is too large - the maximum size is 5MB',
      fileInvalidExtension: 'Sorry, the file type doesn’t match the supported ones',
      fileWrongDimensions: "Sorry, the file doesn't match the required minimum dimensions",
      ecosystemHasNotBeenUpdated: 'Ecosystem has not been updated',
      thereHasBeenAnIssueProcessingYourRequestPleaseTryAgain:
        'There has been an issue processing your request please try again.',
      button: 'Save changes',
      fileUploadFailed: 'File upload failed',
      submitModalTitle: 'Would you like to save changes?',
      submitModalMessage:
        "Once saved please allow 5-10 minutes for the changes to apply in VR then restart the 'Gatherings VR' app on your headset.",
      ecosystemSavedToast: 'Ecosystem saved',
      yourEcosystemHasbeenSuccessfullySaved: 'Your ecosystem has been saved successfully.',
      unableToBrandEcosystem:
        'You are not able to brand this ecosystem. Please, contact the GFT to custom brand this ecosystem.',
      lockedImageAlt: 'Illustration of a lock',
    },
    select: 'Select ecosystem',
  },
}
