<template>
  <v-dialog v-model="show" :width="width" persistent>
    <div class="set-default-space-dialog">
      <v-icon
        dark
        class="top-right"
        color="black"
        size="24px"
        @click="
          selectedGathering = null
          $emit('close')
        "
      >
        mdi-close
      </v-icon>

      <div class="header">Set default space</div>
      <div class="choose-space">{{ chooseText }}</div>

      <div class="space-container">
        <div
          v-for="(gathering, index) in $store.getters['spaces/defaults']"
          :key="index"
          class="gathering"
          :class="isSelected(gathering) ? 'selected' : 'unselected'"
          @click="select(gathering)"
        >
          {{ gathering.name }}
          <v-icon color="white" size="24px" v-if="isSelected(gathering)">mdi-check</v-icon>
        </div>
      </div>
      <div class="footer-buttons-container">
        <v-btn
          type="submit"
          class="footer-button mb-4 mb-sm-0"
          @click.prevent="
            selectedGathering = null
            $emit('close')
          "
          color="primary"
          outlined
          data-cy="cancel"
        >
          {{ $t('common.cancel') }}
        </v-btn>
        <v-btn
          type="submit"
          class="footer-button"
          @click.prevent="
            $emit('apply', selectedGathering)
            selectedGathering = null
          "
          color="primary"
          :disabled="selectedGathering === null"
          data-cy="apply"
        >
          {{ $t('common.apply') }}
        </v-btn>
      </div>
    </div>
  </v-dialog>
</template>

<script>
export default {
  props: ['show', 'ecosystemId', 'selectedUsers'],
  data() {
    return {
      selectedGathering: null,
    }
  },
  methods: {
    select(gathering) {
      this.selectedGathering = gathering
    },
    isSelected(gathering) {
      return (
        this.selectedGathering &&
        gathering.id === this.selectedGathering.id &&
        gathering.accessType === this.selectedGathering.accessType
      )
    },
  },
  computed: {
    width() {
      return this.$vuetify.breakpoint.smAndUp ? '500px' : null
    },
    chooseText() {
      let numberSelected = this.selectedUsers.length
      if (numberSelected > 1) {
        return `Choose new space for ${numberSelected} selected users`
      }
      return `Choose new space for the 1 selected user`
    },
  },
  mounted() {
    this.$store
      .dispatch('spaces/fetchDefaults', {
        ecosystemId: this.ecosystemId,
        user: this.$store.getters['auth/user'],
      })
      .then(() => {
        this.defaultGatherings = this.$store.getters['spaces/defaults'].map(g => {
          return {
            value: g.id,
            text: g.name,
          }
        })
      })
  },
}
</script>
<style lang="scss" scoped>
.set-default-space-dialog {
  background: #ffffff;
  border-radius: 16px;
  position: relative;
  height: 95vh;
  display: flex;
  flex-direction: column;
}
::v-deep .v-dialog:not(.v-dialog--fullscreen) {
  max-height: 95%;
}

@media screen and (min-width: 600px) {
  .set-default-space-dialog {
    height: 80vh;
  }
}
::v-deep .v-dialog {
  box-shadow: none !important;
}
.top-right {
  position: absolute;
  position: absolute;
  top: 24px;
  right: 24px;
  width: 24px;
}
.header {
  margin: 24px 48px 32px;
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 32px;
  text-align: center;
  color: #1f2329;
}
.choose-space {
  font-style: normal;
  font-weight: 300;
  font-size: 18px;
  line-height: 28px;
  color: #464d54;
  margin-left: 32px;
  margin-bottom: 32px;
}
.space-container {
  margin: 0 24px 8px 16px;
  flex-grow: 1;
  overflow: scroll;
  --scrollbar-foreground: #807ef0;
  --scrollbar-background: white;
  /* Foreground, Background */
  scrollbar-color: var(--scrollbar-foreground) var(--scrollbar-background);
  scrollbar-width: thin;
}

.space-container::-webkit-scrollbar {
  width: 4px !important;
}
.space-container::-webkit-scrollbar {
  width: 10px; /* Mostly for vertical scrollbars */
  height: 10px; /* Mostly for horizontal scrollbars */
}
.space-container::-webkit-scrollbar-thumb {
  /* Foreground */
  background: var(--scrollbar-foreground);
}
.space-container::-webkit-scrollbar-track {
  /* Background */
  background: var(--scrollbar-background);
}

.footer-buttons-container {
  padding: 24px 32px;
  display: block;
  text-align: center;
  align-items: flex-start;
  background: #ffffff;
  border: 1px solid #dce2e9;
  border-radius: 0px 0px 16px 16px;
}

@media screen and (min-width: 600px) {
  .footer-buttons-container {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }
}

.footer-button {
  border-radius: 8px;
  text-transform: none;
  font-style: normal;
  font-weight: 700;
  font-size: 16px !important;
  line-height: 24px !important;
  padding: 12px 75px !important;
  width: 100%;
}

@media screen and (min-width: 600px) {
  .footer-button {
    width: auto;
  }
}

.gathering {
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  padding: 12px 14px 12px 16px;
  cursor: pointer;
  display: flex;
}
.unselected {
  color: #363333;
  justify-content: start;
}
.selected {
  color: white;
  background-color: #807ef0;
  justify-content: space-between;
}
</style>
