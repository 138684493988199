import cloneDeep from 'lodash/cloneDeep'
import Vue from 'vue'
import { makeDefaultGetters, makeDefaultMutations } from '@/utilities/store'

const defaultState = () => ({
  upsertModal: {
    show: false,
  },
})

const properties = () => Object.keys(defaultState())
const defaultGetters = makeDefaultGetters(properties())
const defaultMutations = makeDefaultMutations(properties(), defaultState())
const state = defaultState()

const getters = {
  ...defaultGetters,
  showUpsertModal(state) {
    return state.upsertModal.show
  },
}

const actions = {
  toggleUpsertModal({ commit }, boolean) {
    commit('toggleUpsertModal', boolean)
  },
}

const mutations = {
  ...defaultMutations,
  loadInitialStateData(state, data) {
    if (!data || typeof data !== 'object') return
    Object.keys(data).forEach(k => {
      Vue.set(state, k, cloneDeep(data[k]))
    })
  },
  toggleUpsertModal(state, boolean) {
    state.upsertModal.show = boolean
  },
}

export default {
  actions,
  getters,
  mutations,
  namespaced: true,
  state,
}
