<template>
  <v-dialog v-model="show" :width="width" persistent>
    <div class="set-role-dialog">
      <v-icon dark class="top-right" color="black" size="24px" @click="$emit('close')">
        mdi-close
      </v-icon>

      <div class="header">Set new role</div>

      <div class="choose-space">
        <slot name="instructions">
          {{ chooseText }}
        </slot>
      </div>

      <div class="roles-container">
        <v-radio-group class="mt-0" v-model="selectedRole">
          <v-radio
            v-for="(role, index) in roles"
            :key="index"
            :label="role.name"
            :value="role.number"
          ></v-radio>
        </v-radio-group>
      </div>
      <div class="footer-buttons-container">
        <v-btn
          type="submit"
          class="footer-button mb-4 mb-sm-0"
          @click.prevent="
            selectedRole = 'Administrator'
            $emit('close')
          "
          color="primary"
          outlined
          data-cy="delete-no"
        >
          {{ $t('common.cancel') }}
        </v-btn>
        <v-btn
          type="submit"
          class="footer-button"
          @click.prevent="$emit('apply', selectedRole)"
          color="primary"
          :disabled="selectedRole === []"
          data-cy="delete-yes"
        >
          {{ $t('common.apply') }}
        </v-btn>
      </div>
    </div>
  </v-dialog>
</template>

<script>
export default {
  props: ['show', 'ecosystemId', 'selectedUsers'],
  data() {
    return {
      selectedRole: 2,
      roles: [
        {
          name: 'Administrator',
          number: 2,
        },
        {
          name: 'Guest',
          number: 4,
        },
        {
          name: 'User',
          number: 0,
        },
      ],
    }
  },
  computed: {
    width() {
      return this.$vuetify.breakpoint.smAndUp ? '500px' : null
    },
    chooseText() {
      let numberSelected = this.selectedUsers.length
      if (numberSelected > 1) {
        return `Choose new role for ${numberSelected} selected users`
      }
      return `Choose new role for the 1 selected user`
    },
  },
}
</script>
<style lang="scss" scoped>
.set-role-dialog {
  background: #ffffff;
  border-radius: 16px;
  position: relative;
  display: flex;
  flex-direction: column;
}
::v-deep .v-dialog:not(.v-dialog--fullscreen) {
  max-height: 750px;
}

@media screen and (min-width: 600px) {
  .set-role-dialog {
    max-height: 500px;
  }
}
::v-deep .v-dialog {
  box-shadow: none !important;
}
.top-right {
  position: absolute;
  position: absolute;
  top: 24px;
  right: 24px;
  width: 24px;
}
.header {
  margin: 24px 48px 32px;
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 32px;
  text-align: center;
  color: #1f2329;
}
.choose-space {
  font-style: normal;
  font-weight: 300;
  font-size: 18px;
  line-height: 28px;
  color: #464d54;
  margin-left: 32px;
  margin-right: 32px;
}

.roles-container {
  margin: 32px;
  flex-grow: 1;
}

.footer-buttons-container {
  padding: 24px 32px;
  display: block;
  text-align: center;
  align-items: flex-start;
  background: #ffffff;
  border: 1px solid #dce2e9;
  border-radius: 0px 0px 16px 16px;
}

@media screen and (min-width: 600px) {
  .footer-buttons-container {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }
}

.footer-button {
  border-radius: 8px;
  text-transform: none;
  font-style: normal;
  font-weight: 700;
  font-size: 16px !important;
  line-height: 24px !important;
  padding: 12px 75px !important;
  width: 100%;
}

@media screen and (min-width: 600px) {
  .footer-button {
    width: auto;
  }
}
</style>
