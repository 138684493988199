<template>
  <v-menu
    top
    v-model="active"
    :close-on-content-click="true"
    :nudge-top="60"
    data-cy="event-status-select"
  >
    <template v-slot:activator="{ on, attrs }">
      <div v-bind="attrs" v-on="on">
        <v-btn class="status-btn mb-3" elevation="0">
          <div class="status-btn-text">
            <span :class="{ 'green--text': status === 'Published' }">
              {{ status }}
            </span>
          </div>
          <v-icon v-if="active" size="24">mdi-chevron-up</v-icon>
          <v-icon v-else size="24">mdi-chevron-down</v-icon>
        </v-btn>
      </div>
    </template>

    <v-list class="status-menu">
      <v-list-item class="clickable" @click="$emit('changeStatus', 'draft')">
        <v-list-item-title class="d-flex justify-space-between align-center">
          Draft
          <v-icon v-if="!isPublished" size="24">mdi-check</v-icon>
        </v-list-item-title>
      </v-list-item>
      <v-list-item class="clickable" @click="$emit('changeStatus', 'published')">
        <v-list-item-title class="d-flex justify-space-between align-center">
          Published
          <v-icon v-if="isPublished" size="24">mdi-check</v-icon>
        </v-list-item-title>
      </v-list-item>
    </v-list>
  </v-menu>
</template>

<script>
export default {
  props: ['isPublished'],
  data() {
    return {
      active: false,
    }
  },
  computed: {
    status() {
      return this.isPublished ? 'Published' : 'Draft'
    },
  },
}
</script>
<style lang="scss">
.status-btn {
  width: 100% !important;
  justify-content: space-between;
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 8px 12px 8px 16px !important;
  height: 40px;
  background: #ffffff !important;
  border: 1px solid #dce2e9 !important;
  border-radius: 8px;
  text-transform: none;
}

.status-btn-text {
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  display: flex;
  align-items: center;
  color: #1f2329;
}

.status-menu {
  background: #ffffff;
  border: 1px solid #dce2e9;
  box-shadow: 0px 10px 25px rgba(12, 34, 68, 0.12) !important;
  border-radius: 8px !important;
}
</style>
