export default {
  spaces: {
    titles: {
      create: 'Create Space',
      list: 'Manage Spaces',
      edit: 'Edit Space',
    },
    list: {
      manage: 'Manage',
      spaces: 'Spaces',
      viewAddAndEditSpacesWithinYourEcosystem: 'View, add, and edit spaces within your ecosystem',
      attendees: 'attendees',
      attendee: 'attendee',
      searchSpaces: 'Search spaces',
      space: 'space',
      create: 'Create space',
    },
    create: {
      create: 'Create',
      space: 'Space',
      spaceName: 'Space name',
      boardMeeting: 'e.g. Board meeting',
      template: 'Template',
      select: 'Select',
      startDateAndTime: 'Start date & time',
      endDateAndTime: 'End date & time',
      timezone: 'Timezone',
      back: 'Back',
      attendees: 'Attendees',
      details: 'Details',
      branding: 'Branding',
      accessLevel: 'Access level',
      createANewSpaceForYourTeamARegularMeetingOrSpecialEvent:
        'Create a new Space for your team, a regular meeting or special event',
      spaceDetails: 'Space details',
      provideBasicSpaceDetailsAndManageAttendees:
        'Provide basic space details and manage attendees.',
      addUsers: 'Add users',
      addUsersToThisSpaceEitherAsAttendeesOrAHost:
        'Add users to this space, either as attendees or a host',
      searchUsers: 'Search users',
      createSpace: 'Create Space',
      anyoneInYourEcosystemCanJoin: 'Open (Anyone in your ecosystem can join)',
      attendeesMustBeInvitedToJoin: 'Invite only (Attendees must be invited to join)',
      pleaseNoteTheSelectedTimezoneIsDifferentToTheDefaultForThisEcosystem:
        'Please note - the selected timezone is different to the default for this ecosystem',
      thereAreNoPeopleInYourSession:
        'There are no people in your session. You can start adding users right now',
      addAttendees: 'Add attendees',
      addUsersToTeam: 'Add users to team',
      addNUsers: 'Add users ({n})',
      addNewUser: 'Add new user',
      hereYouCanChooseSomeHostsAndFacilitators: 'Here you can choose some hosts and facilitators.',
      exploreMoreAboutDifferentRoles: 'Explore more about different roles',
      allUsers: 'All users',
      spaceCreated: 'Space created',
      spaceUpdated: 'Space updated',
      saveChanges: 'Save changes',
      loading: {
        title: 'Building your space...',
        subTitle: '(Please allow a few seconds)',
      },
    },
    edit: {
      edit: 'Edit',
      space: 'Space',
      editYourSpace: 'Edit your Space',
      spaceDetailsTab: 'Space details',
      provideBasicSpaceDetailsAndManageAttendees:
        'Provide basic space details and manage attendees.',
      manageUsers: 'Manage users',
      addAndRemoveUsersForThisSpace: 'Add and remove users for this space',
      updateSpace: 'Update Space',
      deleteSpace: 'Delete space',
      anyoneInYourEcosystemCanJoin: 'Open (Anyone in your ecosystem can join)',
      attendeesMustBeInvitedToJoin: 'Invite only (Attendees must be invited to join)',
    },
    delete: {
      delete: 'Delete',
      deleteNSpaces: 'Delete {n} spaces?',
      deleteThisSpace: 'Delete this space?',
      areYouSureYouWantToDeleteThisSpace: 'Are you sure you want to delete this space?',
      areYouSureYouWantToDeleteTheseSpaces: 'Are you sure you want to delete these spaces?',
      spaceDeletionNotCompleted: 'Space deletion not completed',
      spaceDeletionSuccessful: 'Deletion of {n} {item} was successful',
    },
    attendeesTable: {
      searchUsers: 'Search users',
      headers: {
        name: 'Name',
        company: 'Company',
        jobTitle: 'Job title',
        host: 'Host',
      },
    },
    branding: {
      selectFile: 'Select file',
      transparentImagesOnly: 'Transparent images only.',
      recommendedSize: 'Recommended size: ',
      px: 'px',
      openFullPreview: 'Open full preview',
      allFiles: 'All files',
      showingOnlyImageWithAcceptableSize: 'Showing only images with acceptable size:',
      selectFileForBrandingOpportunity: 'Select file for {opportunity}',
      cancel: 'Cancel',
      addFile: 'Add file',
      opportunities: {
        foyer: {
          wallBannerOne: 'Wall Banner One',
          wallBannerTwo: 'Wall Banner Two',
          wallBannerThree: 'Wall Banner Three',
        },
        plaza: {
          dividingWallBannerOne: 'Dividing Wall Banner One',
          dividingWallBannerTwo: 'Dividing Wall Banner Two',
          entranceVerticalBannerOne: 'Entrance Vertical Banner One',
          entranceVerticalBannerTwo: 'Entrance Vertical Banner Two',
          entranceHorizontalBanner: 'Entrance Horizontal Banner',
          internalWallBannerOne: 'Internal Wall Banner One',
          internalWallBannerTwo: 'Internal Wall Banner Two',
          endWallBanner: 'End Wall Banner',
          blimpEventSpace: 'Blimp Event Space',
        },
        lounge: {
          halfWallBannerRight: 'Half Wall Banner Right',
          halfWallBannerLeft: 'Half Wall Banner Left',
          fullWallBanner: 'Full Wall Banner',
          rightBalconyBanner: 'Right Balcony Banner',
        },
        roundtable: {
          leftWallBanner: 'Left Wall Banner',
          rightWallBanner: 'Right Wall Banner',
        },
        presentationRoom: {
          leftFloorBanner: 'Left Floor Banner',
          rightFloorBanner: 'Right Floor Banner',
        },
      },
    },
    unsavedChanges: {
      title: 'Wait! Before you go',
      subMessage: 'Would you like to save this space and continue later or close without saving?',
      buttons: {
        save: 'Save changes',
        dontSave: "Don't save",
      },
    },
  },
}
