<template>
  <v-row class="header-shadow" data-cy="ecosystem-nav">
    <v-container :fluid="$vuetify.breakpoint.lgAndDown">
      <v-row>
        <v-col>
          <!-- Nav Header -->
          <v-row class="header-height align-center">
            <!-- Spacer col for small and up -->
            <v-col :cols="1" v-if="$vuetify.breakpoint.smAndUp"></v-col>

            <v-col :cols="11" :sm="9" class="d-flex">
              <!-- Spaces logo home button -->
              <div
                @click="goHome"
                data-cy="go-home"
                class="d-flex ml-2 mr-6 mr-lg-12 pointer align-center"
              >
                <c-logo />
              </div>

              <!-- Nav menu (desktop) -->
              <div class="d-flex align-center" v-if="$vuetify.breakpoint.width > 1100">
                <c-ecosystem-select :ecosystemId="ecosystemId" />

                <div class="mr-6 mr-lg-12">
                  <router-link
                    :to="{ path: `/ws/${ecosystemId}/users` }"
                    class="text-decoration-none menu-item"
                    :class="{ activePage: isActivePage('users') }"
                    data-cy="users-link"
                  >
                    Users
                  </router-link>
                </div>

                <!-- 'Schedule' nav item -->
                <!-- <v-menu
                  bottom
                  :close-on-content-click="true"
                  :nudge-bottom="60"
                  :nudge-left="5"
                  data-cy="schedule-nav-dropdown"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <div v-bind="attrs" v-on="on" class="menu-item mr-6 mr-lg-12 d-flex relative">
                      <div class="nav-link">Schedule</div>
                      <v-icon size="20" class="pl-2">mdi-chevron-down</v-icon>
                      <div :class="{ activeNavItem: isActivePage('schedule') }"></div>
                    </div>
                  </template>

                  <v-list class="user-menu">
                    <v-list-item class="clickable" :to="`/ws/${ecosystemId}/schedule/events`">
                      <v-list-item-title class="user-menu-item"> Events </v-list-item-title>
                    </v-list-item>
                    <v-list-item class="clickable" :to="`/ws/${ecosystemId}/schedule/speakers`">
                      <v-list-item-title class="user-menu-item"> Speakers </v-list-item-title>
                    </v-list-item>
                  </v-list>
                </v-menu> -->

                <div class="mr-6 mr-lg-12">
                  <router-link
                    :to="{ path: `/ws/${ecosystemId}/spaces` }"
                    class="text-decoration-none menu-item"
                    data-cy="spaces-link"
                    :class="{ activePage: isActivePage('spaces') }"
                  >
                    Spaces
                  </router-link>
                </div>

                <div class="mr-6 mr-lg-12">
                  <router-link
                    :to="{ path: `/ws/${ecosystemId}/files` }"
                    class="text-decoration-none menu-item"
                    data-cy="files-link"
                    :class="{ activePage: isActivePage('files') }"
                  >
                    Files
                  </router-link>
                </div>
              </div>
            </v-col>

            <!-- User menu -->
            <v-col :cols="1" class="mt-2 d-flex justify-end">
              <c-user-menu v-if="$vuetify.breakpoint.width > 1100" />
              <!-- Hamburger menu -->
              <div v-else>
                <v-icon @click="showMobileMenu = !showMobileMenu" size="40">mdi-menu</v-icon>
              </div>
            </v-col>
          </v-row>

          <!-- Mobile menu -->
          <v-expand-transition>
            <v-row
              v-show="showMobileMenu"
              @click="showMobileMenu = false"
              data-cy="ecosystem-menu-mobile"
            >
              <v-col :cols="1" v-if="$vuetify.breakpoint.smAndUp"></v-col>
              <v-col class="pt-0">
                <v-list>
                  <v-menu bottom :close-on-content-click="true">
                    <template v-slot:activator="{ on, attrs }">
                      <div
                        v-bind="attrs"
                        v-on="on"
                        class="menu-item hover-primary pb-1 ecosystem-name-mobile-text"
                      >
                        {{ ecosystem && ecosystem.name }}
                        <v-icon>mdi-menu-down</v-icon>
                      </div>
                    </template>

                    <v-list>
                      <div v-if="otherEcosystems.length">
                        <v-list-item
                          v-for="ws in otherEcosystems"
                          :key="ws.id"
                          class="ecosystem-link"
                          @click="
                            $router.push({ path: '/ws/' + ws.id })
                            showMobileMenu = false
                          "
                        >
                          <v-list-item-title :data-cy="ws.name">{{ ws.name }}</v-list-item-title>
                        </v-list-item>
                      </div>

                      <!-- Create new ecosystem button -->
                      <v-list-item
                        v-if="otherEcosystems.length"
                        class="py-2 d-flex align-center create-ecosystem"
                        style="border-top: 1px solid #dfe2e6"
                        @click="$router.push('/ecosystems/create')"
                      >
                        <v-icon size="16" color="#1A1E38">fa-plus-circle</v-icon>
                        <v-list-item-title>{{ $t('ecosystems.titles.create') }}</v-list-item-title>
                      </v-list-item>
                      <v-list-item
                        v-else
                        class="d-flex align-center create-ecosystem"
                        @click="$router.push('/ecosystems/create')"
                      >
                        <v-icon size="16" color="#1A1E38">fa-plus-circle</v-icon>
                        <v-list-item-title>{{ $t('ecosystems.titles.create') }}</v-list-item-title>
                      </v-list-item>
                    </v-list>
                  </v-menu>

                  <!-- <router-link
                    :to="{ path: `/ws/${ecosystemId}/edit` }"
                    class="text-decoration-none menu-item"
                  >
                    <v-list-item>
                      <v-list-item-title class="menu-item">Ecosystem</v-list-item-title>
                    </v-list-item>
                  </router-link> -->

                  <router-link
                    :to="{ path: `/ws/${ecosystemId}/users` }"
                    class="text-decoration-none menu-item"
                  >
                    <v-list-item>
                      <v-list-item-title class="menu-item">Users</v-list-item-title>
                    </v-list-item>
                  </router-link>

                  <!-- Schedule nav sub menu -->
                  <!-- <v-menu bottom :close-on-content-click="true">
                    <template v-slot:activator="{ on, attrs }">
                      <div
                        v-bind="attrs"
                        v-on="on"
                        class="menu-item hover-primary py-2 workspace-name-mobile-text"
                      >
                        Schedule
                        <v-icon>mdi-menu-down</v-icon>
                      </div>
                    </template>

                    <v-list>
                      <v-list-item
                        class="workspace-link"
                        :to="`/ws/${ecosystemId}/schedule/events`"
                      >
                        <v-list-item-title class="user-menu-item"> Events </v-list-item-title>
                      </v-list-item>
                      <v-list-item
                        class="workspace-link top-border"
                        :to="`/ws/${ecosystemId}/schedule/speakers`"
                      >
                        <v-list-item-title class="user-menu-item"> Speakers </v-list-item-title>
                      </v-list-item>
                    </v-list>
                  </v-menu> -->

                  <router-link
                    :to="{ path: `/ws/${ecosystemId}/spaces` }"
                    class="text-decoration-none menu-item"
                  >
                    <v-list-item>
                      <v-list-item-title class="menu-item">Spaces</v-list-item-title>
                    </v-list-item>
                  </router-link>
                  <router-link
                    :to="{ path: `/ws/${ecosystemId}/files` }"
                    class="text-decoration-none menu-item"
                  >
                    <v-list-item>
                      <v-list-item-title class="menu-item">Files</v-list-item-title>
                    </v-list-item>
                  </router-link>
                  <v-list-item style="cursor: auto">
                    <v-list-item-title class="user-menu-item" data-cy="build-number">
                      Build #: {{ $buildVersion }}
                    </v-list-item-title>
                  </v-list-item>
                  <v-list-item @click="logOut">
                    <v-btn
                      text
                      style="text-transform: none; font-size: 1.4rem"
                      class="pa-0 ml-1 primary--text text-left menu-item font-weight-light"
                      data-cy="log-out-mobile"
                    >
                      Log out
                    </v-btn>
                  </v-list-item>
                </v-list>
              </v-col>
            </v-row>
          </v-expand-transition>
        </v-col>
      </v-row>
    </v-container>
  </v-row>
</template>

<script>
export default {
  props: ['ecosystemId'],
  data() {
    return {
      showMobileMenu: false,
    }
  },
  computed: {
    myEcosystems() {
      return this.$store.getters['ecosystems/myEcosystems']
    },
    ecosystem() {
      return this.$store.getters['ecosystems/getById'](this.ecosystemId)
    },
    otherEcosystems() {
      return this.$store.getters['ecosystems/myEcosystems'].filter(ws => ws.id !== this.ecosystemId)
    },
  },
  methods: {
    logOut() {
      this.$store.dispatch('auth/forceLogout')
      this.$router.push('/account/login')
    },
    goHome() {
      if (this.$store.getters['ecosystems/myEcosystems'].length === 1) {
        this.$router.push({ path: `/ws/${this.ecosystemId}` })
      } else {
        this.$router.push('/')
      }
    },
    isActivePage(string) {
      if (
        string === 'ws' &&
        (['users', 'spaces', 'files'].some(v => this.$router.currentRoute.fullPath.includes(v)) ||
          !this.$router.currentRoute.fullPath.includes('edit'))
      ) {
        return false
      }
      return this.$router.currentRoute.fullPath.includes(string)
    },
  },
}
</script>
<style lang="scss" scoped>
.header-height {
  max-height: 80px;
}
.header-shadow {
  box-shadow: 0 0.25rem 0.75rem rgb(0 0 0 / 5%);
  border-bottom: 1px solid #dee2e6 !important;
  border-bottom-width: 1px !important;
  border-bottom-style: solid !important;
  border-bottom-color: rgb(222, 226, 230) !important;
}
.ecosystem-link {
  display: block;
  width: 100%;
  padding: 0.25rem 1.5rem;
  clear: both;
  font-weight: 400;
  color: #212529;
  text-align: inherit;
  white-space: nowrap;
  background-color: transparent;
  border: 0;
  min-height: 30px;
}
.pointer {
  cursor: pointer;
}
.menu-item {
  color: #363332;
  font-size: 1.2em;
  font-weight: 300;
}
.menu-item:hover {
  color: #807ef0;
}

@media screen and (min-width: 1264px) {
  .menu-item {
    font-size: 1.5rem;
  }
}
.v-list-item .v-list-item__title,
.v-list-item .v-list-item__subtitle {
  font-size: 1.3rem;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  padding-left: 0.5rem;
}
.v-menu__content {
  box-shadow: none;
  border: 1px solid rgba(0, 0, 0, 0.15);
  border-radius: 0.25rem;
}
.ecosystem-name {
  font-family: Kiro;
  font-style: normal;
  font-weight: bold;
  line-height: 24px;
  color: #363333;
  width: 240px;
  font-size: 20px;
}
.ecosystem-name-mobile-text {
  margin-left: 22px;
  font-size: 1.3rem;
}
.select-menu {
  background: #f3efed;
  border-radius: 10px;
  padding: 8px 16px;
}
.v-menu__content {
  border-radius: 10px;
  box-shadow: 0px 4px 14px rgba(0, 0, 0, 0.25);
  border: none;
}
::v-deep .v-list-item:hover {
  background: #f3efed;
}
.select-list {
  padding-bottom: 0px;
}
.top-border {
  border-top: 1px solid #dfe2e6;
}
.activeNavItem {
  position: absolute;
  width: 100%;
  border-bottom: solid 4px #807ef0;
  height: 4px;
  bottom: -25px;
  left: -5px;
}
</style>
