///////////////////////////////////////////////////////////////////////////////////////////////////
//     ____                            __                _
//    / __ \___  ____  ___  ____  ____/ /__  ____  _____(_)__  _____
//   / / / / _ \/ __ \/ _ \/ __ \/ __  / _ \/ __ \/ ___/ / _ \/ ___/
//  / /_/ /  __/ /_/ /  __/ / / / /_/ /  __/ / / / /__/ /  __(__  )
// /_____/\___/ .___/\___/_/ /_/\__,_/\___/_/ /_/\___/_/\___/____/
//           /_/
//
///////////////////////////////////////////////////////////////////////////////////////////////////

import App from '@/App.vue'
import components from '@/components'
import Env from '@/plugins/env'
import filters from '@/utilities/filters'
import i18n from '@/plugins/i18n'
import router from '@/plugins/router'
import store from '@/plugins/store'
import Vue from 'vue'
import VueI18n from 'vue-i18n'
import VueRouter from 'vue-router'
import VueScrollTo from 'vue-scrollto'
import vuetify from '@/plugins/vuetify'
import Vuetify from 'vuetify'

import Vuex from 'vuex'
import links from '@/utilities/links'
import '@/utilities/veeValidations'
import VueCookies from 'vue-cookies-reactive'
import VueAppInsights from 'vue-application-insights'
import AsyncComputed from 'vue-async-computed'
const packageJson = require('../package.json')
import 'vue-advanced-cropper/dist/style.css'
import { getAppSetting, resolveAppSettings } from './utilities/settings'

import { createServer, Response } from 'miragejs'
import { patchPassthrough } from '../mirage/utils/passthrough'
import minimatch from 'minimatch'

import '@/utilities/veeValidations'
import VuePortal from '@linusborg/vue-simple-portal'
///////////////////////////////////////////////////////////////////////////////////////////////////
//    _____ __        __
//   / ___// /___  __/ /__  _____
//   \__ \/ __/ / / / / _ \/ ___/
//  ___/ / /_/ /_/ / /  __(__  )
// /____/\__/\__, /_/\___/____/
//          /____/
//
///////////////////////////////////////////////////////////////////////////////////////////////////

import 'material-design-icons-iconfont/dist/material-design-icons.css'
import '@mdi/font/css/materialdesignicons.css'
import '@/scss/_index.scss'
import 'gatherings-storybook/dist/mesmerise-ui.css'

if (window.Cypress) {
  // If your app makes requests to domains other than / (the current domain), add them
  // here so that they are also proxied from your app to the handleFromCypress function.
  // For example: let otherDomains = ["https://my-backend.herokuapp.com/"]
  let otherDomains = [process.env.VUE_APP_API_ROOT_URL, process.env.VUE_APP_UPLOAD_API_ROOT_URL]
  let methods = ['get', 'put', 'patch', 'post', 'delete']

  createServer({
    environment: 'test',
    routes() {
      // Patches passthrough to allow blob requests
      patchPassthrough(this.pretender)

      // Passthrough any requests to file blobs
      this.passthrough(request => {
        if (request.method === 'GET' && minimatch(request.url, '**/ecosystem/*/files/*')) {
          // passthrough
          return true
        }
      })

      for (const domain of ['/', ...otherDomains]) {
        for (const method of methods) {
          this[method](`${domain}*`, async (schema, request) => {
            try {
              let [status, headers, body] = await window.handleFromCypress(request)
              return new Response(status, headers, body)
            } catch (e) {
              console.log(e)
              return new Response(400, {}, '')
            }
          })
        }
      }

      this.urlPrefix = ''
      this.namespace = ''

      this.passthrough('https://api.ipify.org/', 'https://ipapi.co/', '/config/appSettings.json')
    },
  })
}

resolveAppSettings().then(() => {
  ///////////////////////////////////////////////////////////////////////////////////////////////////
  //     ____  __            _
  //    / __ \/ /_  ______ _(_)___  _____
  //   / /_/ / / / / / __ `/ / __ \/ ___/
  //  / ____/ / /_/ / /_/ / / / / (__  )
  // /_/   /_/\__,_/\__, /_/_/ /_/____/
  //               /____/
  //
  ///////////////////////////////////////////////////////////////////////////////////////////////////

  Vue.use(Env)
  Vue.use(VueI18n)
  Vue.use(VueRouter)
  Vue.use(VueAppInsights, {
    id: getAppSetting('VUE_APP_INSIGHTS_INSTRUMENTATION_KEY'),
    router,
    appInsightsConfig: {
      enableAutoRouteTracking: true,
    },
  })
  Vue.use(VueScrollTo)
  Vue.use(Vuetify)
  Vue.use(Vuex)
  Vue.use(VueCookies)
  Vue.use(AsyncComputed)
  Vue.use(VuePortal)

  Vue.prototype.$links = links
  Vue.prototype.$buildVersion = packageJson.version

  ///////////////////////////////////////////////////////////////////////////////////////////////////
  //     _______ ____
  //    / ____(_) / /____  __________
  //   / /_  / / / __/ _ \/ ___/ ___/
  //  / __/ / / / /_/  __/ /  (__  )
  // /_/   /_/_/\__/\___/_/  /____/
  //
  ///////////////////////////////////////////////////////////////////////////////////////////////////

  Object.keys(filters).forEach(f => Vue.filter(f, filters[f]))

  ///////////////////////////////////////////////////////////////////////////////////////////////////
  //
  //    ______                                             __
  //   / ____/___  ____ ___  ____  ____  ____  ___  ____  / /______
  //  / /   / __ \/ __ `__ \/ __ \/ __ \/ __ \/ _ \/ __ \/ __/ ___/
  // / /___/ /_/ / / / / / / /_/ / /_/ / / / /  __/ / / / /_(__  )
  // \____/\____/_/ /_/ /_/ .___/\____/_/ /_/\___/_/ /_/\__/____/
  //                     /_/
  //
  ///////////////////////////////////////////////////////////////////////////////////////////////////

  // import other components
  Object.keys(components).forEach(k => Vue.component(k, components[k]))

  ///////////////////////////////////////////////////////////////////////////////////////////////////
  //
  //     ____      _ __  _       ___
  //    /  _/___  (_) /_(_)___ _/ (_)___  ___
  //    / // __ \/ / __/ / __ `/ / /_  / / _ \
  //  _/ // / / / / /_/ / /_/ / / / / /_/  __/
  // /___/_/ /_/_/\__/_/\__,_/_/_/ /___/\___/
  //
  ///////////////////////////////////////////////////////////////////////////////////////////////////

  Vue.config.productionTip = false
  console.log('Client app version: ', packageJson.version)

  const app = new Vue({
    i18n,
    router,
    store,
    vuetify,
    render: h => h(App),
  })

  window.app = app
  app.$mount('#app')
})
