<template>
  <div class="filter-menu">
    <div v-if="$vuetify.breakpoint.smAndDown" class="mobile-header">
      <div class="d-flex justify-center align-center">Filters</div>
      <v-btn @click="$emit('close')" depressed fab plain>
        <v-icon height="auto" color="black" size="24px"> mdi-close </v-icon>
      </v-btn>
    </div>

    <div class="d-block d-sm-flex flex-grow-1">
      <div class="filter-column">
        <div class="filter-title">Type</div>
        <div class="pt-1">
          <v-checkbox
            class="filter filter-checkbox pt-sm-4"
            v-model="filters.type.mesmerise"
            label="Mesmerise"
            dense
          ></v-checkbox>
          <v-checkbox
            class="filter filter-checkbox pt-sm-4"
            v-model="filters.type.external"
            label="External"
            dense
          ></v-checkbox>
        </div>
      </div>

      <div class="filter-column mt-5 mt-sm-0 ml-sm-6">
        <div class="filter-title">Avatar</div>
        <v-radio-group class="mt-0 mt-sm-4" v-model="filters.avatar" hide-details>
          <v-radio class="filter" label="Show all" value="All"></v-radio>
          <v-radio class="filter" label="With avatar" value="With avatar"></v-radio>
          <v-radio class="filter" label="Without avatar" value="Without avatar"></v-radio>
        </v-radio-group>
      </div>

      <div class="filter-column mt-4 mt-sm-0 ml-sm-6">
        <div class="filter-title">Role</div>
        <div class="pt-1">
          <v-checkbox
            class="filter filter-checkbox pt-0 pt-sm-4"
            v-model="filters.role.guest"
            label="Guest"
            dense
          ></v-checkbox>
          <v-checkbox
            class="filter filter-checkbox pt-0 pt-sm-4"
            v-model="filters.role.user"
            label="User"
            dense
          ></v-checkbox>
          <v-checkbox
            class="filter filter-checkbox pt-0 pt-sm-4"
            v-model="filters.role.admin"
            label="Admin"
            dense
          ></v-checkbox>
        </div>
      </div>
    </div>
    <div class="mt-4">
      <v-btn
        color="primary"
        :disabled="neverFiltered && unFiltered"
        class="action-button"
        :class="{ 'purple-button': !neverFiltered }"
        @click="$emit('filter')"
      >
        Apply filters
      </v-btn>
    </div>
  </div>
</template>

<script>
export default {
  props: ['filters', 'neverFiltered', 'unFiltered'],
}
</script>

<style lang="scss" scoped>
.filter-menu {
  width: 100%;
  background: #ffffff;
  display: flex;
  flex-direction: column;
}

@media screen and (max-width: 960px) {
  .filter-menu {
    padding: 16px 24px 24px 24px;
    border-radius: 16px;
    border: 1px solid #e8eef3 !important;
    box-shadow: 0px 10px 25px rgba(41, 45, 51, 0.12) !important;
  }
}

@media screen and (min-width: 960px) {
  .filter-menu {
    padding: 24px;
    width: 638px;
  }
}

.filter-column {
  width: 180px;
  min-width: 180px;
}

.filter-title {
  font-weight: 700;
  font-size: 16px;
  line-height: 24px;
  display: flex;
  align-items: center;
  color: #252729;
}

@media screen and (min-width: 600px) {
  .filter-title {
    margin-bottom: 8px;
  }
}

.filter {
  height: 44px;
}

.filter-checkbox {
  margin-bottom: 7px;
}

.purple-button {
  font-weight: 700;
  gap: 8px;
  height: 48px !important;
}

.action-button {
  align-items: center;
  border-radius: 8px !important;
  display: flex;
  flex-direction: row;
  font-size: 16px !important;
  font-style: normal !important;
  justify-content: center;
  line-height: 24px !important;
  text-transform: none;
  width: 100% !important;
  height: 48px !important;
}

@media screen and (min-width: 600px) {
  .action-button {
    width: 141px !important;
  }
}

.mobile-header {
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 32px;
  color: #1f2329;
  display: flex;
  align-content: center;
  justify-content: space-between;
  padding: 0px;
}
</style>
