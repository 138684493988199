export default {
  files: {
    titles: {
      list: 'List files',
    },
    list: {
      manage: 'Manage',
      files: 'Files',
      uploadDownloadAndViewFiles: 'Upload, download and view files',
      edit: 'Edit',
      delete: 'Delete',
      preview: 'Preview',
      save: 'Save',
      download: 'Download',
      downloading: 'Downloading',
      close: 'Close',
      table: {
        filename: 'File name',
        contentType: 'Type',
        created: 'Uploaded date',
        uploadedBy: 'Uploaded by',
      },
      contentType: {
        pdf: 'PDF',
        image: 'Image',
        video: 'Video',
      },
      fileDeletionNotCompleted: 'File deletion not completed',
      fileDeletionSuccessful: 'Deletion of {n} {item} was successful',
      deleteThisFile: 'Delete this file?',
      deleteNFiles: 'Delete {n} files?',
      areYouSureYouWantToDeleteThisFile: 'Are you sure you want to delete this file?',
      areYouSureYouWantToDeleteTheseFiles: 'Are you sure you want to delete these files?',
      thisCannotBeUndone: 'This cannot be undone',
      videoModal: {
        title: 'Video preview not available',
        message: 'Please download file to preview video.',
      },
      cantPreviewThisFile: 'This file cannot be previewed',
    },
    add: {
      uploadFile: 'Upload new file',
      fileTypeValidationMsg: 'File type not supported.',
      fileSizeValidationMsg: 'File size too large. Should not exceed 500MB.',
      filesUploading: '{count} files uploading',
      filesNotUploaded: '{count} files not loaded',
      filesUploaded: '{count} files uploaded',
      dragAndDropFilesToUpload: 'Drag and drop files to upload.',
      uploadFailed: 'File upload failed',
      invalidFile: 'Invalid file',
      requestCompleteButError: 'Request completed but message returned unsuccessful',
      success: 'success',
      error: 'error',
    },
    searchForFiles: 'Search for files',
    filter: {
      buttons: {
        activator: 'Filters',
        apply: 'Apply filter',
        reset: 'Reset',
      },
      search: 'Search',
      uploadedBy: 'Uploaded by',
      fileTypes: 'File types',
      noResultsFound: 'No results found',
      nA: 'N/A',
    },
    misc: {
      dateAndTime: 'Date & Time',
      fileSize: 'File Size',
      fileId: 'File ID',
    },
  },
}
