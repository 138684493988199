import cloneDeep from 'lodash/cloneDeep'
import Vue from 'vue'
import { makeDefaultGetters, makeDefaultMutations, setState } from '@/utilities/store'
import api from '@/utilities/api'

const defaultState = () => ({
  defaults: [],
  templates: [],
})

const properties = () => Object.keys(defaultState())
const defaultGetters = makeDefaultGetters(properties())
const defaultMutations = makeDefaultMutations(properties(), defaultState())
const state = defaultState()

const getters = {
  ...defaultGetters,
}

const actions = {
  fetchDefaults({ commit }, { ecosystemId, user }) {
    let options = { method: 'GET' }
    const endpoint = `workspaces/${ecosystemId}/users/${user.id}/gatherings`
    return api(endpoint, options).then(response => {
      commit('setDefaults', response.data.data)
    })
  },
  fetchTemplates({ commit, state }, force = false) {
    if (state.templates.length > 0 && !force) {
      return state.templates
    }
    let options = { method: 'GET' }
    const endpoint = `gatherings/templates`
    return api(endpoint, options).then(response => {
      commit('setTemplates', response.data.data)
    })
  },
  async remove(_, { ecosystemId, spaces }) {
    const endpoints = spaces.map(space => `workspace/${ecosystemId}/gatherings/${space.id}`)
    let errors = []
    for (const endpoint of endpoints) {
      try {
        const { data } = await api(endpoint, { method: 'DELETE' })
        if (data.message !== 'Gathering details deleted') {
          errors.push(data.message)
          throw data.message
        }
      } catch (error) {
        console.error(error)
      }
    }
    return errors
  },
  fetchSpaceStyles(_, spaceId) {
    let options = { method: 'GET' }
    const endpoint = `space/${spaceId}/style`
    return api(endpoint, options)
  },
}

const mutations = {
  ...defaultMutations,
  loadInitialStateData(state, data) {
    if (!data || typeof data !== 'object') return
    Object.keys(data).forEach(k => {
      Vue.set(state, k, cloneDeep(data[k]))
    })
  },
  resetState: state => setState(state, defaultState(), true),
}

export default {
  actions,
  getters,
  mutations,
  namespaced: true,
  state,
}
