<template>
  <div class="search-input-container relative" id="search-input">
    <input
      v-model="searchTerm"
      @focus="search"
      @input="search"
      type="text"
      class="search-input"
      :placeholder="placeholderText"
      @keydown.esc="escape"
    />

    <svg
      class="search-icon"
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M16.4702 17.5308C16.7633 17.8234 17.2382 17.823 17.5308 17.5298C17.8234 17.2367 17.823 16.7618 17.5298 16.4692L16.4702 17.5308ZM13.5678 13.5741L13.0378 13.0434L13.5678 13.5741ZM9.19231 14.6346C6.1866 14.6346 3.75 12.198 3.75 9.19231H2.25C2.25 13.0264 5.35818 16.1346 9.19231 16.1346V14.6346ZM3.75 9.19231C3.75 6.1866 6.1866 3.75 9.19231 3.75V2.25C5.35818 2.25 2.25 5.35818 2.25 9.19231H3.75ZM9.19231 3.75C12.198 3.75 14.6346 6.1866 14.6346 9.19231H16.1346C16.1346 5.35818 13.0264 2.25 9.19231 2.25V3.75ZM14.6346 9.19231C14.6346 10.6968 14.0251 12.0575 13.0378 13.0434L14.0977 14.1048C15.3553 12.849 16.1346 11.111 16.1346 9.19231H14.6346ZM13.0378 13.0434C12.0524 14.0274 10.694 14.6346 9.19231 14.6346V16.1346C11.1074 16.1346 12.8425 15.3582 14.0977 14.1048L13.0378 13.0434ZM13.0379 14.1049L16.4702 17.5308L17.5298 16.4692L14.0976 13.0433L13.0379 14.1049Z"
        fill="#9996A2"
      />
    </svg>

    <v-icon @click="resetSearch" ref="closeIcon" class="close-icon" size="20">mdi-close</v-icon>
  </div>
</template>

<script>
export default {
  props: ['placeholder'],
  data() {
    return {
      searchTerm: '',
      visibleEvents: [],
    }
  },
  methods: {
    search() {
      let preppedSearchTerm = this.searchTerm.trim().toLowerCase()
      this.$emit('search', preppedSearchTerm)
    },
    async resetSearch() {
      this.searchTerm = ''
      this.$emit('search', this.searchTerm)
    },
    escape(e) {
      e.preventDefault()
      this.$refs.closeIcon.$el.click()
      this.$refs.closeIcon.$el.focus()
    },
  },
  computed: {
    placeholderText() {
      return this.placeholder && this.placeholder.length ? this.placeholder : 'Search...'
    },
  },
}
</script>
<style lang="scss" scoped>
.search-input {
  width: 100%;
  margin-top: 1px;
  padding: 18px 16px 16px 45px;
  box-sizing: border-box;
  height: 48px;
  border: 1px solid #e2e5e9;
  background: #ffffff;
  border-radius: 8px;
}

.search-input:focus {
  border: 1px solid #807ef0;
}

.search-input:focus-visible {
  outline: 1px solid #807ef0;
}

.search-input::-webkit-input-placeholder {
  font-family: 'Kiro';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 19px;
  color: #9e9e9e;
}

.search-input-container {
  width: 100%;
}

@media screen and (min-width: 600px) {
  .search-input-container {
    width: 448px;
  }
}

.search-icon {
  position: absolute;
  top: calc(50% - 10px);
  left: 20px;
}

.close-icon {
  position: absolute;
  top: calc(50% - 10px);
  right: 20px;
}
</style>
