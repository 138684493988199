<template>
  <div class="input-container">
    <div class="label">{{ label }} <span v-if="required" class="red--text">&nbsp;*</span></div>
    <div>
      <slot>
        <v-text-field
          height="48px"
          outlined
          dense
          :placeholder="placeholder"
          :value="content"
          @input="$emit('update:content', $event)"
          :type="type"
          :disabled="disabled"
          :rules="rules"
        />
      </slot>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    label: {
      type: String,
    },
    placeholder: {
      type: String,
      default: '',
    },
    content: {
      type: String,
    },
    type: {
      type: String,
      default: 'text',
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    rules: {
      type: Array,
    },
    required: {
      type: Boolean,
      default: false,
    },
  },
}
</script>
<style lang="scss" scoped>
.input-container {
  width: 100%;
}
.label {
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 20px;
  color: #464d54;
  margin-bottom: 8px;
}
input {
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 12px 16px;
  gap: 6px;
  width: 100%;
  background: #ffffff;
  border: 1px solid #dce2e9;
  border-radius: 8px;
}

::v-deep .v-text-field .v-input__slot {
  background: #ffffff !important;
  border: 1px solid #dce2e9 !important;
  border-radius: 8px !important;
}
</style>
