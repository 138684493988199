<template>
  <v-app
    v-bind:class="{ loading: loading.isLoading, noClicks: loading.isLoading && loading.disable }"
  >
    <v-main>
      <c-errors></c-errors>
      <c-snackbar></c-snackbar>
      <m-loading :loading="loading.isLoading" :type="loading.type" :text="loading.text"></m-loading>
      <slot />
    </v-main>

    <c-footer style="background-color: #e8e0dd; padding-top: 20px; padding-bottom: 20px"></c-footer>
  </v-app>
</template>

<script>
import { MLoading } from 'gatherings-storybook'
export default {
  components: {
    MLoading,
  },
  computed: {
    loading() {
      return this.$store.getters['ui/loading']
    },
  },
}
</script>

<style lang="scss">
.loading {
  cursor: progress;
}
.noClicks {
  pointer-events: none;
}
</style>
