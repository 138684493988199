<template>
  <v-dialog
    :value="show"
    :fullscreen="$vuetify.breakpoint.height < 900 || $vuetify.breakpoint.width < 1900"
    persistent
    hide-overlay
    transition="fade-transition"
    data-cy="event-upsert"
    @click:outside="clickOutside"
    @keydown="onKeydown"
  >
    <!-- Desktop -->
    <div v-if="$vuetify.breakpoint.mdAndUp" class="upsert-height upsert-background relative d-flex">
      <v-btn icon fab elevation="0" absolute right class="top" @click="close">
        <v-icon size="24">mdi-close</v-icon>
      </v-btn>

      <!-- left nav bar -->
      <div class="sidebar">
        <div id="steps" class="step-container">
          <div
            class="step clickable"
            v-for="(step, index) in steps"
            :key="index"
            @click="goTo(step)"
          >
            <div
              class="rounded-circle step-number"
              :class="
                isCurrent(step)
                  ? 'current-step-number'
                  : isPrevious(step)
                  ? 'previous-step-number'
                  : 'disabled-step-number'
              "
            >
              <div v-if="isPrevious(step)" class="d-flex justify-center align-center">
                <v-icon size="15">mdi-check</v-icon>
              </div>
              <span v-else> {{ index + 1 }}</span>
            </div>
            <span
              :class="
                isCurrent(step)
                  ? 'current-step-text'
                  : isPrevious(step)
                  ? 'previous-step-text'
                  : 'disabled-step-text'
              "
              >{{ step.name }}</span
            >
          </div>
        </div>

        <div class="sidebar-footer">
          <c-event-settings-modal v-on:changeStatus="changeStatus" :isPublished="isPublished" />
          <v-btn class="sidebar-text preview-btn" primary outlined>
            <div class="pr-2 d-flex align-center">
              <img src="/images/svgs/eye.svg" alt="Preview" />
            </div>
            <div class="primary--text">Preview</div>
          </v-btn>
        </div>
      </div>

      <!-- main content -->
      <div class="upsert-main">
        <!-- Body -->
        <div class="upsert-body">
          <div class="upsert-title">
            {{ mode }} Event: <span class="primary--text">{{ currentStep.section }}</span>
          </div>
          <div class="upsert-subtitle">{{ currentStep.subTitle }}</div>

          <div class="upsert-content">
            <component v-bind:is="currentStep.component" />
          </div>
        </div>

        <!-- Footer -->
        <div class="upsert-footer">
          <div>
            <v-btn v-if="!isFirst(currentStep)" class="upsert-btn white-btn" @click="back">
              <v-icon class="mr-2">mdi-chevron-left</v-icon>
              Back
            </v-btn>
          </div>

          <div class="d-flex space-around">
            <v-btn
              v-if="isLast(currentStep)"
              class="upsert-btn purple-btn save-btn"
              @click="publish"
            >
              Save & exit
            </v-btn>
            <v-btn v-else class="upsert-btn purple-btn" @click="forward">
              <span>Next step</span>
              <v-icon class="ml-2">mdi-chevron-right</v-icon>
            </v-btn>
          </div>
        </div>
      </div>
    </div>

    <!-- Mobile  -->
    <div v-else class="upsert-background upsert-height d-flex flex-column">
      <div class="upsert-top-bar">
        <div class="d-flex justify-space-between align-center mb-5">
          <v-dialog v-model="showEventSettingsDialog" width="500">
            <template v-slot:activator="{ on, attrs }">
              <v-btn plain x-small class="pl-0" v-bind="attrs" v-on="on">
                <v-icon size="24">mdi-cog-outline</v-icon>
              </v-btn>
            </template>
            <div class="event-settings-modal relative">
              <v-btn @click="showEventSettingsDialog = false" class="pr-0" plain absolute top right>
                <v-icon size="24">mdi-close</v-icon>
              </v-btn>
              <div class="event-settings-title">Event settings</div>

              <c-event-settings-modal v-on:changeStatus="changeStatus" :isPublished="isPublished" />

              <v-btn class="sidebar-text preview-btn" primary outlined>
                <div class="pr-2 d-flex align-center">
                  <img src="/images/svgs/eye.svg" alt="Preview" />
                </div>
                <div class="primary--text">Preview</div>
              </v-btn>
            </div>
          </v-dialog>

          <div class="upsert-mobile-title">{{ mode }} event</div>
          <v-icon size="24" @click="close">mdi-close</v-icon>
        </div>
        <div class="d-flex justify-space-between align-center">
          <div class="horizontal-step" v-for="(step, index) in steps" :key="index">
            <div
              @click="goTo(step)"
              class="rounded-circle step-number"
              :class="
                isCurrent(step)
                  ? 'current-step-number'
                  : isPrevious(step)
                  ? 'previous-step-number'
                  : 'disabled-step-number'
              "
            >
              <div v-if="isPrevious(step)" class="d-flex justify-center align-center">
                <v-icon size="20">mdi-check</v-icon>
              </div>
              <div class="d-flex align-center justify-center" v-else>{{ index + 1 }}</div>
            </div>
          </div>
        </div>
      </div>
      <div class="divider"></div>
      <div class="upsert-main mobile-main">
        <div class="upsert-title primary--text">
          {{ currentStep.section }}
        </div>

        <div v-if="currentStep.shortSubTitle" class="upsert-subtitle">
          {{ currentStep.shortSubTitle }}
        </div>
        <div v-else class="upsert-subtitle">{{ currentStep.subTitle }}</div>

        <div class="upsert-content">
          <component v-bind:is="currentStep.component" />
        </div>
      </div>
      <div class="upsert-footer">
        <v-btn v-if="!isFirst(currentStep)" class="upsert-btn white-btn" @click="back">
          <v-icon class="mr-2">mdi-chevron-left</v-icon>
          Back
        </v-btn>
        <v-btn v-if="isLast(currentStep)" class="upsert-btn purple-btn save-btn" @click="publish">
          Save & exit
        </v-btn>
        <v-btn v-else class="upsert-btn purple-btn" @click="forward">
          <span>Next step</span>
          <v-icon class="ml-2">mdi-chevron-right</v-icon>
        </v-btn>
      </div>
    </div>
  </v-dialog>
</template>

<script>
import upsertSteps from './upsertModalSteps'
export default {
  props: {
    show: {
      type: Boolean,
      default: false,
    },
    ecosystemId: {
      type: String,
    },
    gathering: {
      type: Object,
      default() {
        return {
          isPublished: false,
        }
      },
    },
    creating: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      steps: upsertSteps,
      showEventSettingsDialog: false,
    }
  },
  methods: {
    clickOutside() {
      this.close()
    },
    onKeydown(event) {
      if (event.key === 'Escape') {
        this.close()
      }
    },
    close() {
      this.$emit('close')
    },
    back() {
      let previousStep = this.steps.find(step => step.order == this.currentStep.order - 1)
      this.$router.push({
        path: `/ws/${this.ecosystemId}/schedule/events`,
        query: { step: previousStep.name },
      })
    },
    forward() {
      let nextStep = this.steps.find(step => step.order == this.currentStep.order + 1)
      this.$router.push({
        path: `/ws/${this.ecosystemId}/schedule/events`,
        query: { step: nextStep.name },
      })
    },
    goTo(step) {
      let goToStep = this.steps.find(s => s.order === step.order)
      this.$router.push({
        path: `/ws/${this.ecosystemId}/schedule/events`,
        query: { step: goToStep.name },
      })
    },
    save() {
      alert('saving')
    },
    publish() {
      alert('publishing')
    },
    isCurrent(step) {
      return step.order === this.currentStep.order
    },
    isPrevious(step) {
      return step.order < this.currentStep.order
    },
    isFirst(step) {
      return step.order === 1
    },
    isLast(step) {
      return step.order === this.steps.length
    },
    changeStatus(status) {
      if (status === 'published') {
        this.gathering.isPublished = true
        return
      }
      this.gathering.isPublished = false
    },
  },
  computed: {
    currentStep() {
      return this.steps.find(step => step.name == this.currentStepName)
    },
    currentStepName() {
      return this.$route.query.step ?? 'Details'
    },
    mode() {
      return this.creating ? 'Create' : 'Edit'
    },
    isPublished() {
      return this.gathering.isPublished
    },
  },
}
</script>
<style lang="scss" scoped>
.upsert-background {
  background: #ffffff;
}

::v-deep .v-dialog {
  box-shadow: none !important;
  overflow: hidden;
}

.sidebar {
  background: #f6f9fc;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 300px;
  min-width: 300px;
  height: 100vh;
  padding: 60px 16px 24px;
}

@media screen and (min-width: 1100px) {
  .sidebar {
    width: 300px;
  }
}

.sidebar-footer {
  display: flex;
  flex-direction: column;
}
.upsert-top-bar {
  height: 102px;
  min-height: 102px;
  filter: drop-shadow(0px 4px 8px rgba(4, 12, 41, 0.05)) !important;
  padding: 14px 16px;
}
.divider {
  background-color: #e2e5e9;
  height: 1px;
}

.status-text {
  font-weight: 400;
  color: #9da3a8;
}

@media screen and (max-width: 600px) {
  .status-text {
    font-size: 14px;
    line-height: 20px;
    color: #73787c;
  }
}

.sidebar-text {
  font-size: 14px;
  line-height: 20px;
  text-transform: none;
}

.step {
  padding: 24px 15px;
  display: flex;
}

@media screen and (min-width: 1100px) {
  .step {
    padding: 24px 0 24px 60px;
  }
}

.horizontal-step {
  display: flex;
}

.previous-step-text {
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: #252729;
}

.step-number {
  width: 28px;
  height: 28px;
  display: flex;
  justify-content: center;
  align-content: center;
  margin-right: 16px;
  font-weight: 700;
  font-size: 16px;
  line-height: 24px;
}

@media screen and (min-width: 960px) {
  .step-number {
    width: 24px;
    height: 24px;
  }
}

.current-step-number {
  color: white;
  background-color: #807ef0;
  font-weight: 700;
  font-size: 16px;
  line-height: 24px;
}

.previous-step-number {
  border: 1px solid #807ef0;
}

.current-step-text {
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 24px;
  color: #252729;
}

.disabled-step-number {
  color: #9da3a8;
  border: 1px solid #e2e5e9;
  background: #ffffff;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
}

.disabled-step-text {
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: #9da3a8;
}

.upsert-main {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  padding: 24px 16px;
}

.mobile-main {
  height: calc(100vh - (80px + 105px)); // 100% minus footer + header
  overflow-y: scroll;
  overflow-x: hidden;
}

@media screen and (min-width: 960px) {
  .upsert-main {
    padding: 0;
  }
}

.upsert-body {
  padding: 60px;
  overflow-y: scroll;
  --scrollbar-foreground: #807ef0;
  --scrollbar-background: white;
  /* Foreground, Background */
  scrollbar-color: var(--scrollbar-foreground) var(--scrollbar-background);
  scrollbar-width: thin;
  flex-grow: 1;
  height: calc(100vh - 140px);
}

@media screen and (min-width: 960px) {
  .upsert-body {
    height: calc(100vh - 114px);
  }
}

.upsert-body::-webkit-scrollbar {
  width: 4px !important;
}
.upsert-body::-webkit-scrollbar {
  width: 10px; /* Mostly for vertical scrollbars */
  height: 10px; /* Mostly for horizontal scrollbars */
}
.upsert-body::-webkit-scrollbar-thumb {
  /* Foreground */
  background: var(--scrollbar-foreground);
}
.upsert-body::-webkit-scrollbar-track {
  /* Background */
  background: var(--scrollbar-background);
}

.upsert-title {
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 32px;
  color: #252729;
}

@media screen and (min-width: 960px) {
  .upsert-title {
    font-size: 38px;
    line-height: 46px;
    padding-bottom: 6px;
  }
}

.upsert-mobile-title {
  font-weight: 300;
  font-size: 18px;
  line-height: 28px;
  text-align: center;
  color: #4e5255;
}

.upsert-subtitle {
  font-style: normal;
  font-weight: 300;
  font-size: 16px;
  line-height: 24px;
  color: #4e5255;
}

@media screen and (min-width: 960px) {
  .upsert-subtitle {
    font-size: 18px;
    line-height: 28px;
    padding-bottom: 0;
  }
}

.upsert-content {
  margin-top: 32px;
}

.upsert-footer {
  box-shadow: 0px 10px 25px rgba(41, 45, 51, 0.12) !important;
  display: flex;
  justify-content: space-between;
  padding: 16px;
  align-items: center;
}

@media screen and (min-width: 960px) {
  .upsert-footer {
    height: 114px;
    padding: 16px 60px 50px 60px;
  }
}

.upsert-btn {
  display: flex;
  align-items: center;
  gap: 8px;
  border-radius: 8px;
  width: 141px !important;
  text-transform: none;
  height: 48px !important;
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 24px;
}

.white-btn {
  background: white !important;
  color: #807ef0;
  padding: 12px !important;
  box-shadow: none !important;
  border: 1px solid #807ef0;
}

.purple-btn {
  color: #ffffff;
  background: #807ef0 !important;
  border: 1px solid #807ef0;
  padding: 12px 12px 12px 24px !important;
}

.save-btn {
  padding: 12px 12px 12px 12px !important;
}

@media screen and (min-width: 1900px) and (min-height: 900px) {
  .upsert-height {
    width: 1800px;
    height: 100vh;
    max-height: none;
    position: absolute;
    top: 0;
    left: calc(50% - 900px);
    box-shadow: 0px 10px 80px rgba(41, 45, 51, 0.08) !important;
  }
}
.top {
  top: 15px;
}

.preview-btn {
  display: flex;
  align-items: center;
  padding: 10px 20px 10px 10px;
  gap: 6px;
  width: 100%;
  height: 40px;
  background: #ffffff;
  border: 1px solid #807ef0;
  border-radius: 8px;
}

.event-settings-modal {
  padding: 16px;
  width: 100%;
  background: #ffffff;
  border-radius: 16px;
}

.event-settings-title {
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 32px;
  text-align: center;
  color: #1f2329;
  margin-bottom: 16px;
}
</style>
