<template>
  <m-modal
    data-cy="global-modal"
    :value="value"
    @close="hide"
    :title="options.title"
    :message="options.message"
    :subMessage="options.subMessage"
    :persistent="options.persistent"
    :icon="options.illustration"
    :width="$vuetify.breakpoint.smAndUp ? '610px' : undefined"
    :isLoading="ui.isLoading"
  >
    <template v-slot:buttons>
      <m-button
        v-for="button in options.buttons"
        :key="button.type"
        :label="button.label"
        :variant="button.variant"
        :icon="button.icon"
        :iconRight="button.iconRight"
        @click="action(button.type)"
        :disabled="ui.isLoading"
      />
    </template>
  </m-modal>
</template>
<script>
import { MModal, MButton } from 'gatherings-storybook'
import { mapGetters, mapActions } from 'vuex'

export default {
  components: {
    MModal,
    MButton,
  },
  computed: {
    ...mapGetters({
      ui: 'ui/loading',
      value: 'modal/show',
      options: 'modal/options',
    }),
  },
  methods: {
    ...mapActions({
      hide: 'modal/hide',
      confirm: 'modal/confirm',
      cancel: 'modal/cancel',
    }),
    action(type) {
      // Get action confirm/cancel function
      const action = this[type]

      if (action) action()
      else this.hide() // default to hide
    },
  },
}
</script>
