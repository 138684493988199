export default {
  fields: {
    address: 'Address',
    addressLine1: 'Address line 1',
    addressLine2: 'Address line 2',
    addressLine3: 'Address line 3',
    city: 'City',
    company: 'Company',
    country: 'Country',
    county: 'County',
    description: 'Description',
    email: 'Email',
    emailAddress: 'Email address',
    givenName: 'First name',
    home: 'Home',
    jobTitle: 'Job title',
    mobile: 'Mobile',
    name: 'Name',
    ecosystemName: 'Ecosystem name',
    postCode: 'Post code',
    registrationNumber: 'Registration number',
    surname: 'Last name',
    telephoneNumber: 'Telephone number',
    title: 'Title',
    titles: {
      dr: 'Dr',
      lady: 'Lady',
      lord: 'Lord',
      mr: 'Mr',
      miss: 'Miss',
      mrs: 'Mrs',
      ms: 'Ms',
      mx: 'Mx',
      prof: 'Prof',
      sir: 'Sir',
    },
    town: 'Town',
    userTimeZone: 'Time zone',
    work: 'Work',
    role: 'Role',
  },
}
