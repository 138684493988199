import i18n from '@/plugins/i18n'
import cloneDeep from 'lodash/cloneDeep'
import router from '@/plugins/router'
import Vue from 'vue'
import {
  getErrorMessage,
  makeDefaultGetters,
  makeDefaultMutations,
  setState,
} from '@/utilities/store'
import { HTTP, SNACKBAR_DEFAULTS } from '@/utilities/constants'
import { config as env } from '@/plugins/env'

const { ERRORS, NON_ERRORS, NON_GLOBAL_ERRORS } = HTTP.STATUSES

const defaultState = () => ({
  apiEndpointsLoading: {},
  layout: 'default-layout',
  loading: {
    isLoading: false,
    type: 'bar',
    text: '',
    disable: true,
  },
  errors: {},
  keepAlive: [],
  routerViewportOffsetTop: 0,
  snackbar: { ...SNACKBAR_DEFAULTS, color: 'primary' },
  tables: { itemsPerPage: 10 },
  uploadProgresses: [], // expects objects with url [string] and progress [integer] properties
  showCookieDialog: true,
  timeZones: [],
})

const properties = () => Object.keys(defaultState())

const defaultGetters = makeDefaultGetters(properties())

const defaultMutations = makeDefaultMutations(properties(), defaultState())

const state = defaultState()

const getters = {
  ...defaultGetters,
  apiEndpointIsLoading: state => opts => {
    const { method, url } = opts
    return !!state.apiEndpointsLoading[method]?.[url]
  },
  globalErrors: state => {
    const errors = cloneDeep(state.errors)
    return Object.keys(errors)
      .map(n => Number(n))
      .filter(s => ERRORS.filter(e => !NON_GLOBAL_ERRORS.includes(e)).includes(s))
      .map(e => String(e) + ': ' + (errors[e]?.message || i18n.t('common.error')))
  },
  uploadProgressByUrl: state => url => state.uploadProgresses.find(up => up.url === url)?.progress,
  validationError: state => error => state.errors[422]?.errors?.[error],
  validationErrors: state => state.errors[422]?.errors,
}

const actions = {
  closeSnackbar({ commit }) {
    commit('resetSnackbar')
  },
  toggleLoading({ commit }, settings) {
    commit('toggleLoading', settings)
  },
  launchSnackbar({ commit }, params) {
    commit('setSnackbar', {
      ...defaultState().snackbar,
      ...params,
      visible: true,
    })
  },
  toggleCookieDialog({ commit }, boolean) {
    // true = on, false = off
    commit('setShowCookieDialog', boolean)
  },
  async onApiError({ commit, dispatch }, { data = {}, status } = {}) {
    if (!status || NON_ERRORS.includes(status)) return

    let { error, errors, message } = data

    if (!message || (+status > 399 && +status < 500)) {
      // Prefer our custom 4XX messages over those provided by the API server or a blank message
      message = getErrorMessage(status)
    }

    // if not authenticated, force logout
    if ((status === 401 && router.currentRoute.path !== '/account/passcode') || status === 419) {
      // 419 = page expired
      dispatch('auth/forceLogout', {}, { root: true })
      dispatch('launchSnackbar', { message: "You've been logged out" })
    } else {
      await commit('setErrors', { error, errors, message, status })
      dispatch('launchSnackbar', { message, color: 'red', buttonColor: 'white' })
    }
  },
  onInitialLoad({ commit }, { initialStateData }) {
    commit('loadInitialStateData', initialStateData)
  },
  onRouteChange({ commit }, { to, from }) {
    const meta = { new: to?.meta || {}, old: from?.meta || {} }
    document.title = meta.new.title || env.APP_NAME
    const layout = meta.new.layout || 'login-layout'
    if (layout !== meta.old.layout) commit('setLayout', layout)
  },
  redirect({ commit }, url) {
    commit('resetErrors')
    router.push(url)
  },
  setTimeZones({ commit }, timeZones) {
    commit('setTimeZones', timeZones)
  },
}

const mutations = {
  ...defaultMutations,
  loadInitialStateData(state, data) {
    if (!data || typeof data !== 'object') return
    Object.keys(data).forEach(k => {
      Vue.set(state, k, cloneDeep(data[k]))
    })
  },
  resetState: state => setState(state, defaultState(), true),
  setApiEndpointsLoading(state, { method, url, value }) {
    const target = state.apiEndpointsLoading
    if (!target[method]) Vue.set(target, method, {})
    Vue.set(target[method], url, value)
  },
  setErrors(state, { status, message, errors }) {
    Vue.set(state.errors, status, { errors, message })
  },
  setUploadProgress(state, { url, progress }) {
    const target = state.uploadProgresses.find(v => v.url === url)
    if (target) target.progress = progress
    else state.uploadProgresses.push({ url, progress })
  },
  toggleLoading(state, { isLoading, type, text, disable }) {
    if (isLoading === false) {
      state.loading = defaultState().loading
      return
    }
    state.loading = { ...state.loading, isLoading, type, text, disable }
  },
}

export default {
  actions,
  getters,
  mutations,
  namespaced: true,
  state,
}
