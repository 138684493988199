<template>
  <m-card class="ecosystem-details">
    <template #header>
      <h3 class="card-title" data-cy="generalDetails">
        {{ $t('ecosystems.create.generalDetails') }}
      </h3>
    </template>

    <m-text-input
      :label="$t('ecosystems.create.name')"
      :placeholder="$t('ecosystems.create.namePlaceholder')"
      :value="internalValue.name"
      @input="update('name', $event)"
      rules="required|min:2|max:26|noSpecialChars"
      data-cy="name"
      required
    ></m-text-input>

    <m-timezone-picker
      :label="$t('ecosystems.create.timeZone')"
      :value="internalValue.timeZone"
      @input="update('timeZone', $event)"
      showTimezoneField
      :menuProps="timeZonePickerMenuProps"
      :timezones="timeZones"
      data-cy="ecosystemTimeZone-select"
    ></m-timezone-picker>
  </m-card>
</template>

<script>
import { MCard, MTextInput, MTimezonePicker } from 'gatherings-storybook'
import { cloneDeep, set, tap } from 'lodash'
export default {
  name: 'ecosystem-details',
  components: {
    MCard,
    MTextInput,
    MTimezonePicker,
  },
  model: {
    prop: 'ecosystem',
  },
  props: {
    /**
     * The ecosystem to update
     */
    ecosystem: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      lazyValue: cloneDeep(this.ecosystem),
      timeZones: [],
      timeZonePickerMenuProps: {
        width: '100%',
        nudgeRight: '12px',
      },
    }
  },
  computed: {
    internalValue() {
      return this.ecosystem
    },
  },
  methods: {
    update(key, value) {
      this.$emit(
        'input',
        tap(cloneDeep(this.internalValue), v => set(v, key, value))
      )
    },
  },
  async mounted() {
    this.timeZones = await this.$store.getters['timeZones/all']
  },
}
</script>

<style lang="scss" scoped>
.text-input {
  margin-bottom: 1.5rem;
  max-width: 372px;
}

.timezone-picker {
  max-width: 372px;
}
</style>
