<template>
  <m-dropdown
    :value="selectedEcosystem"
    data-cy="select-ecosystem-menu-desktop"
    class="select-ecosystem"
    :items="myEcosystems"
    itemText="name"
    itemValue="id"
    :menuProps="{
      nudgeRight: '12px',
    }"
    :placeholder="$t('ecosystems.select')"
  >
    <template #item="{ item }">
      <m-list-item-content
        @click="selectEcosystem(item.id)"
        :data-cy="`select-ecosystem-${item.id}`"
      >
        {{ item.name }}
      </m-list-item-content>

      <m-list-item-action @click="editEcosystem(item.id)" :data-cy="`edit-ecosystem-${item.id}`">
        <m-icon style="pointer-events: none">cog-stroke</m-icon>
      </m-list-item-action>
    </template>

    <template #append-list>
      <m-list-item-button
        data-cy="create-ecosystem"
        class="create-ecosystem"
        @click="$router.push('/ecosystems/create')"
        icon="plus-stroke"
      >
        {{ $t('ecosystems.titles.create') }}
      </m-list-item-button>
    </template>
  </m-dropdown>
</template>

<script>
import {
  MDropdown,
  MListItemButton,
  MListItemAction,
  MListItemContent,
  MIcon,
} from 'gatherings-storybook'

export default {
  props: {
    ecosystemId: {
      type: String,
      default: null,
    },
  },
  components: {
    MDropdown,
    MListItemButton,
    MListItemAction,
    MListItemContent,
    MIcon,
  },
  data() {
    return {
      selectedEcosystem: this.ecosystemId,
    }
  },
  watch: {
    ecosystemId(newVal) {
      this.selectedEcosystem = newVal
    },
  },
  computed: {
    myEcosystems() {
      return this.$store.getters['ecosystems/myEcosystems']
    },
  },
  methods: {
    selectEcosystem(ecosystemId) {
      this.selectedEcosystem = ecosystemId
      this.$router.push(`/ws/${ecosystemId}`)
    },
    editEcosystem(ecosystemId) {
      this.$router.push(`/ws/${ecosystemId}/edit`)
    },
  },
}
</script>

<style lang="scss" scoped>
.select-ecosystem {
  font-family: 'Mulish' !important;
  width: 202px;
  margin-top: -0.5rem;
  margin-right: 55px;

  :deep(.text-input__inner),
  :deep(.text-input__input) {
    cursor: pointer;
  }
}

.create-ecosystem {
  position: sticky;
  bottom: 0;
  background: #fff;
}
</style>

<style>
.dropdown__menu {
  font-family: 'Mulish' !important;
}
</style>
