export default {
  schedule: {
    events: {
      title: 'Schedule Events',
      manage: 'Manage',
      events: 'Events',
      createAndManageEventsForYourTeamARegularMeetingOrSpecialEvent:
        'Create and manage events for your team, a regular meeting or special event.',
      createNewEvent: 'Create new event',
    },
  },
}
