<template>
  <div class="d-block d-sm-flex flex-wrap">
    <div
      v-for="(location, index) in locations"
      :key="index"
      class="location"
      :class="{ 'purple-border': isSelected(location) }"
      @click="select(location)"
    >
      <div
        class="relative location-image"
        :style="{ backgroundImage: backgroundImageString(location) }"
      >
        <v-btn v-if="!isSelected(location)" fab class="location-btn unselected-btn"></v-btn>
        <v-btn v-if="isSelected(location)" fab class="location-btn selected-btn">
          <v-icon color="white" size="16">mdi-check</v-icon>
        </v-btn>
      </div>
      <div class="location-name" :class="{ 'faded-text': hasNotBeenChosen(location) }">
        {{ location.name }}
      </div>
    </div>
  </div>
</template>

<script>
import { v4 as uuidv4 } from 'uuid'

import eventLocations from './eventLocations'
export default {
  data() {
    return {
      locations: eventLocations.map(location => {
        return { ...location, id: uuidv4() }
      }),
      selectedLocation: null,
    }
  },
  methods: {
    select(location) {
      this.selectedLocation = location
    },
    isSelected(location) {
      return location === this.selectedLocation
    },
    hasNotBeenChosen(location) {
      return this.selectedLocation !== null && !this.isSelected(location)
    },
    backgroundImageString(location) {
      let url = `url('/images/locations/${location.image}')`
      let fade = 'linear-gradient(rgba(255,255,255,0.5), rgba(255,255,255,0.5))'
      if (this.hasNotBeenChosen(location)) {
        return `${fade}, ${url}`
      }
      return url
    },
  },
}
</script>

<style lang="scss" scoped>
.location {
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 0px;
  width: 315px;
  height: 178px;
  border: 1px solid #dce2e9;
  border-radius: 12px;
  margin: 12px auto;
  cursor: pointer;
  gap: 24px;
}

@media screen and (min-width: 600px) {
  .location {
    margin: 0px 24px 24px 0px;
  }
}

.location:hover {
  border: 1px solid #807ef0;
}

.purple-border {
  border: 1px solid #807ef0;
}

.location-image {
  width: 314px;
  height: 126px;
  background-repeat: no-repeat;
  background-size: 314px 126px;
  background-color: #eeeeee;
  border-radius: 12px 12px 0px 0px;
}

.faded-text {
  color: #69737a;
}

.location-name {
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  padding: 12px 10px;
  width: 100%;
  background: #ffffff;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: #1f2329;
  border-radius: 0px 0px 12px 12px;
}

.location-btn {
  border: 1px solid #ffffff;
  border-radius: 50%;
  position: absolute;
  width: 24px;
  height: 24px;
  right: 8px;
  top: 8px;
}

.unselected-btn {
  background: rgba(255, 255, 255, 0.5) !important;
}

.selected-btn {
  background: #807ef0 !important;
}
</style>
