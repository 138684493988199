///////////////////////////////////////////////////////////////////////////////////////////////////
//     _                            __
//    (_)___ ___  ____  ____  _____/ /_
//   / / __ `__ \/ __ \/ __ \/ ___/ __/
//  / / / / / / / /_/ / /_/ / /  / /_
// /_/_/ /_/ /_/ .___/\____/_/   \__/
//            /_/
//
///////////////////////////////////////////////////////////////////////////////////////////////////

import Errors from '@/components/pages/Errors'
import Form from '@/components/forms/Form'
import FormField from '@/components/forms/FormField'
import Footer from '@/components/pages/Footer'
import LoadingScreen from '@/components/pages/LoadingScreen'
import LoadingSpinner from '@/components/pages/LoadingSpinner'
import LoadingBar from '@/components/pages/LoadingBar'
import LoadingOverlay from '@/components/pages/LoadingOverlay'
import Logo from '@/components/images/Logo'
import MaskInput from '@/components/forms/MaskInput'
import Page from '@/components/pages/Page'
import PageHeading from '@/components/pages/PageHeading'
import Snackbar from '@/components/pages/Snackbar'
import DashboardNav from '@/components/nav/DashboardNav'
import EcosystemNav from '@/components/nav/EcosystemNav'
import Panels from '@/components/pages/Panels'
import SearchSelect from '@/components/pages/SearchSelect'
import SearchSelectUser from '@/components/pages/SearchSelectUser'
import ConfirmDeleteDialog from '@/components/pages/ConfirmDeleteDialog'
import TemporarilyDisabled from '@/components/pages/TemporarilyDisabled'
import Union from '@/components/pages/Union'
import UserMenu from '@/components/nav/UserMenu'
import UserListCard from '@/components/users/ListCard'
import UserCardDetails from '@/components/users/CardDetails'
import UserAvatarHistory from '@/components/users/AvatarHistory'
import UserOnboardingContent from '@/components/users/OnboardingContent'
import UserActivityContent from '@/components/users/ActivityContent'
import UserSetDefaultSpaceDialog from '@/components/users/SetDefaultSpaceDialog'
import UserSetNewRoleDialog from '@/components/users/SetNewRoleDialog'
import SpaceDetailsTab from '@/components/spaces/DetailsTab'
import SpaceAttendeesTab from '@/components/spaces/AttendeesTab'
import SpaceBrandingTab from '@/components/spaces/BrandingTab'
import SpaceBrandingPreview from '@/components/spaces/BrandingPreview'
import SpaceBrandingPreviewOverlay from '@/components/spaces/components/PreviewOverlay'
import SpaceSelectAttendeesModal from '@/components/spaces/SelectAttendeesModal'
import SpaceFilesModal from '@/components/spaces/FilesModal'
import BulkSelectPanel from '@/components/utilities/BulkSelectPanel'

import EventUpsertModal from '@/components/events/upsert-modal/UpsertModal'
import EventUpsertGeneralDetails from '@/components/events/upsert-modal/UpsertGeneralDetails'
import EventUpsertChooseLocation from '@/components/events/upsert-modal/UpsertChooseLocation'
import EventSettingsModal from '@/components/events/upsert-modal/EventSettingsModal'
import ImageUpload from '@/components/utilities/ImageUpload'

import DateTimePicker from '@/components/utilities/DateTimePicker'
import BulkActionSelect from '@/components/utilities/BulkActionSelect'
import UserFilterMenu from '@/components/users/FilterMenu'
import UserTable from '@/components/users/Table'
import PinDialog from '@/components/users/PinDialog'
import UserAvatarCircle from '@/components/users/AvatarCircle'
import SmallUserCard from '@/components/users/SmallCard'
import SearchInput from '@/components/utilities/SearchInput'
import { ModelSelect } from 'vue-search-select'
import ImageCropper from '@/components/utilities/ImageCropper'
import Button from '@/components/utilities/Button'
import UpsertModal from '@/components/utilities/UpsertModal/Modal'
import UpsertInput from '@/components/utilities/UpsertModal/Input'
import UserUpsertContent from '@/components/users/UpsertContent'
import EcosystemCreatingModal from '@/components/ecosystems/CreatingModal'

import EcosystemDetails from '@/components/ecosystems/EcosystemDetails'
import EcosystemBranding from '@/components/ecosystems/EcosystemBranding'
import EcosystemForm from '@/components/ecosystems/EcosystemForm'
import FilePreviewModal from '@/components/files/FilePreviewModal'

import EditFileModal from '@/components/files/EditFileModal'
import DeleteModal from '@/components/modals/DeleteModal'
import EcosystemSelect from '@/components/nav/EcosystemSelect'

import GlobalModal from '@/components/modals/GlobalModal'

///////////////////////////////////////////////////////////////////////////////////////////////////
//                     _      __
//    ________  ____ _(_)____/ /____  _____
//   / ___/ _ \/ __ `/ / ___/ __/ _ \/ ___/
//  / /  /  __/ /_/ / (__  ) /_/  __/ /
// /_/   \___/\__, /_/____/\__/\___/_/
//           /____/
//
///////////////////////////////////////////////////////////////////////////////////////////////////

export default {
  'c-errors': Errors,
  'c-form': Form,
  'c-form-field': FormField,
  'c-footer': Footer,
  'c-loading-screen': LoadingScreen,
  'c-loading-spinner': LoadingSpinner,
  'c-loading-overlay': LoadingOverlay,
  'c-logo': Logo,
  'c-mask-input': MaskInput,
  'c-page': Page,
  'c-page-heading': PageHeading,
  'c-snackbar': Snackbar,
  'c-loading-bar': LoadingBar,
  'c-dashboard-nav': DashboardNav,
  'c-ecosystem-nav': EcosystemNav,
  'c-panels': Panels,
  'c-search-select': SearchSelect,
  'c-search-select-user': SearchSelectUser,
  'c-model-select': ModelSelect,
  'c-confirm-delete-dialog': ConfirmDeleteDialog,
  'c-union': Union,
  'c-user-menu': UserMenu,
  'c-user-list-card': UserListCard,
  'c-user-card-details': UserCardDetails,
  'c-user-avatar-history': UserAvatarHistory,
  'c-user-onboarding-content': UserOnboardingContent,
  'c-user-activity-content': UserActivityContent,
  'c-space-details-tab': SpaceDetailsTab,
  'c-space-attendees-tab': SpaceAttendeesTab,
  'c-space-branding-tab': SpaceBrandingTab,
  'c-space-branding-preview': SpaceBrandingPreview,
  'c-space-branding-preview-overlay': SpaceBrandingPreviewOverlay,
  'c-space-files-modal': SpaceFilesModal,
  'c-space-select-attendees-modal': SpaceSelectAttendeesModal,
  'c-bulk-select-panel': BulkSelectPanel,
  'c-bulk-action-select': BulkActionSelect,
  'c-temporarily-disabled': TemporarilyDisabled,
  'c-event-upsert-modal': EventUpsertModal,
  'c-event-upsert-general-details': EventUpsertGeneralDetails,
  'c-event-upsert-choose-location': EventUpsertChooseLocation,
  'c-event-settings-modal': EventSettingsModal,
  'c-date-time-picker': DateTimePicker,
  'c-set-default-user-space-dialog': UserSetDefaultSpaceDialog,
  'c-set-new-user-role-dialog': UserSetNewRoleDialog,
  'c-search-input': SearchInput,
  'c-user-filter-menu': UserFilterMenu,
  'c-image-upload': ImageUpload,
  'c-image-cropper': ImageCropper,
  'c-button': Button,
  'c-user-table': UserTable,
  'c-user-avatar-circle': UserAvatarCircle,
  'c-upsert-modal': UpsertModal,
  'c-upsert-input': UpsertInput,
  'c-user-upsert-content': UserUpsertContent,
  'c-user-card-small': SmallUserCard,
  'c-pin-dialog': PinDialog,
  'c-ecosystem-creating-modal': EcosystemCreatingModal,
  'c-ecosystem-details': EcosystemDetails,
  'c-ecosystem-branding': EcosystemBranding,
  'c-ecosystem-form': EcosystemForm,
  'c-edit-file-modal': EditFileModal,
  'c-file-preview-modal': FilePreviewModal,
  'c-delete-modal': DeleteModal,
  'c-ecosystem-select': EcosystemSelect,
  'c-global-modal': GlobalModal,
}
