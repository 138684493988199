<template>
  <div class="preview-container">
    <div class="preview-box" data-cy="branding-area-preview">
      <div class="overlay-container">
        <c-space-branding-preview-overlay
          :letterPosition="brandingStyle.previewImage.indicatorPosition"
          :letter="letter"
        />
        <img
          data-cy="branding-preview-image"
          id="brandingImage"
          :src="brandingStyle.previewImage.url || '/images/FOYER - A.png'"
          alt="Branding Image"
          class="preview-image"
        />
      </div>
    </div>
    <div class="open-full-preview" @click="preview" data-cy="open-full-preview">
      {{ $t('spaces.branding.openFullPreview') }}
      <span style="margin-left: 8px"><img src="/images/svgs/eye.svg" /></span>
    </div>
    <c-file-preview-modal
      v-model="previewModal.show"
      :title="previewModal.title"
      :subtitle="previewModal.subtitle"
      :file="previewModal.file"
      :message="previewModal.message"
      :width="previewModal.width"
      :maxWidth="previewModal.maxWidth"
      :height="$vuetify.breakpoint.lgAndUp ? previewModal.maxHeight : previewModal.height"
      :footer="false"
      :fill="!!$vuetify.breakpoint.lgAndUp"
      @close="closePreview()"
    >
      <c-space-branding-preview-overlay
        :letterPosition="brandingStyle.previewImage.indicatorPosition"
        :letter="letter"
    /></c-file-preview-modal>
  </div>
</template>

<script>
export default {
  props: {
    brandingStyle: {
      type: Object,
    },
    letter: {
      type: String,
    },
  },
  data() {
    return {
      previewModal: {
        show: false,
        title: null,
        subtitle: null,
        width: '100%',
        maxWidth: '80%',
        height: '50vh',
        maxHeight: '80vh',
        file: null,
        message: null,
      },
    }
  },
  methods: {
    async preview() {
      this.previewModal.title = this.brandingStyle.name
      this.previewModal.file = this.brandingStyle.previewImage.url ?? '/images/FOYER - A.png'
      this.previewModal.show = true
    },
    closePreview() {
      this.previewModal.show = false
      this.previewModal.file = null
    },
  },
}
</script>

<style lang="scss" scoped>
.preview-box {
  border-radius: 8px;
}
.overlay-container {
  position: relative;
}
.preview-image {
  height: 100%;
  width: 100%;
  border-radius: 8px;
}
.open-full-preview {
  display: flex;
  align-content: center;
  justify-content: flex-end;
  font-size: 16px;
  font-weight: 700;
  line-height: 24px;
  color: #807ef0;
  margin-top: 12px;
  cursor: pointer;
}
</style>
