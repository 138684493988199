<template>
  <div>
    <v-avatar v-if="avatarUrl" :size="size" class="mr-4">
      <img :src="avatarUrl" :alt="userName" style="object-fit: cover" />
    </v-avatar>
    <v-avatar v-else color="primary" :size="size" class="mr-4">
      <span class="white--text avatar-text">
        {{ userInitials }}
      </span>
    </v-avatar>
  </div>
</template>

<script>
import { getUnexpiredSignedUrlObject } from '../../utilities/files'
export default {
  props: {
    size: {
      type: [Number, String],
    },
    user: {
      type: Object,
    },
  },
  data() {
    return {
      authUser: this.$store.getters['auth/user'],
    }
  },
  asyncComputed: {
    avatarUrl() {
      if (this.user) {
        if (this.user.activeAvatarThumbnail && !this.user.activeAvatarThumbnail.includes('/img')) {
          return this.user.activeAvatarThumbnail
        } else {
          return ''
        }
      } else {
        let avatarLifetime = process.env.VUE_APP_AVATAR_IMAGE_MILLISECOND_LIFETIME
        // Get logged in user's active avatar image, if there is one
        return getUnexpiredSignedUrlObject('avatars', 'active', avatarLifetime).then(
          activeAvatar => {
            let thumbnail = activeAvatar?.thumbnail
              ? activeAvatar?.thumbnail.replace('/img/', '/images/')
              : undefined
            return thumbnail
          }
        )
      }
    },
  },
  computed: {
    userName() {
      let user = this.user ?? this.authUser
      return `${user.givenName} ${user.surname}`
    },
    userInitials() {
      let user = this.user ?? this.authUser
      return `${user.givenName[0]}${user.surname[0]}`.toUpperCase()
    },
  },
}
</script>

<style lang="scss" scoped>
.avatar-text {
  font-size: 16px;
}
</style>
