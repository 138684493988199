export default {
  dashboard: {
    title: 'Home Page',
    gatherings: 'Gatherings',
    administration: 'Administration',
    selectYourEcosystemOrCreateANewOne: 'Select your ecosystem or create a new one.',
    panels: {
      users: 'Users',
      userPanelText:
        'Add, edit and remove users within your ecosystem and invite users to join different Spaces you own and manage.',
      spaces: 'Spaces',
      files: 'Files',
      filesPanelText: 'Manage files in your ecosystem for use in spaces.',
      comingSoon: 'Coming soon!',
      spacesPanelText:
        "Configure the rooms and environments you want to host your Spaces in. From meeting rooms to conference rooms, to the top of Everest, it's all here.",
      admin: 'Admin',
      adminPanelText:
        "Full administration and reporting. Review logs to see who's attended and who hasn't, and view and generate reports.",
    },
  },
}
