<template>
  <!-- header needs changing once api is ready -->
  <m-modal
    :header="header"
    :value="show"
    @close="close"
    @closed="closed"
    :width="width"
    data-cy="branding-files-modal"
  >
    <template v-slot:content>
      <div class="modal-content">
        <div v-if="size" class="modal-subheader">
          {{ $t('spaces.branding.showingOnlyImageWithAcceptableSize') }}
          <span class="modal-subheader__image-size">{{ size }}</span>
        </div>
        <div class="files-count">
          {{ $t('spaces.branding.allFiles') }} <span>({{ filteredFiles.length }})</span>
        </div>
        <v-container fluid class="pa-0 ma-0">
          <v-row v-if="loading" class="d-flex justify-center align-center mt-6 mb-6">
            <c-loading-spinner />
          </v-row>
          <v-row no-gutters v-if="!loading">
            <v-col>
              <m-table-view>
                <template v-slot:toolbar>
                  <div class="table-toolbar">
                    <m-search-input
                      v-model="searchTerm"
                      :placeholder="$t('files.searchForFiles')"
                      class="table-search-bar"
                      @clear="searchTerm = null"
                    ></m-search-input>
                    <input
                      type="file"
                      ref="upload"
                      :accept="allowedFileTypes"
                      hidden
                      multiple
                      @change="onFileChange"
                    />
                    <m-button
                      :label="$t('files.add.uploadFile')"
                      @click="$refs.upload.click()"
                    ></m-button>
                  </div>
                </template>
                <template #default>
                  <m-table
                    class="files-list__table"
                    height="400"
                    style="overflow: hidden; overflow-y: scroll"
                    showSelect
                    selectionType="radio"
                    singleSelect
                    fixedHeader
                    :items="filteredFiles"
                    :headers="headers"
                    :disablePagination="true"
                    :sort-by.sync="sortBy"
                    :sort-desc.sync="sortDesc"
                    droppable
                    @drop="onFileChange"
                    v-model="selected"
                  >
                    <template #item.name="{ item }">
                      <span class="file-name">{{ item.name }}</span>
                    </template>
                    <template #item.owner="{ item }">
                      <span class="file-owner">
                        {{
                          item.owner
                            ? `${item.owner.givenName} ${item.owner.surname}`
                            : $t('files.filter.nA')
                        }}
                      </span>
                    </template>
                    <template #item.created="{ item }">
                      <span class="file-created">{{ formatDate(item.created) }}</span>
                    </template>
                  </m-table>
                </template>
              </m-table-view>
            </v-col>
          </v-row>
        </v-container>
      </div>
      <m-action-status-panel
        id="upload-panel"
        v-model="statusPanel.show"
        :menuProps="statusPanel.menuProps"
        :title="statusPanelTitle"
        :showClose="itemStatuses.pending === 0"
        bottom
        right
      >
        <m-action-status-panel-item
          v-for="(item, index) in uploadQueue"
          :key="item.file.name + index"
          :status="item.status"
          :icon="item.icon"
          :iconColor="item.iconColor"
          :title="item.file.name"
          :subtitle="item.message || item.fileSize"
        />
      </m-action-status-panel>
    </template>
    <template v-slot:buttons>
      <m-button
        variant="secondary"
        :label="$t('spaces.branding.cancel')"
        @click="close"
        data-cy="cancel-button"
      />
      <m-button
        :label="$t('spaces.branding.addFile')"
        :disabled="!selected.length"
        @click="addFile"
        data-cy="add-file-button"
      />
    </template>
  </m-modal>
</template>

<script>
import {
  MModal,
  MButton,
  MTableView,
  MTable,
  MSearchInput,
  MActionStatusPanel,
  MActionStatusPanelItem,
} from 'gatherings-storybook'
import { DateTime } from 'luxon'
import { addFilesToQueue, uploadFiles } from '@/utilities/fileUpload'
import { itemStatuses, statusPanelTitle } from '@/utilities/statusPanel'
import { filter } from '../../utilities/search'
export default {
  name: 'files-modal',
  props: {
    show: {
      type: Boolean,
      default: false,
    },
    ecosystemId: {
      type: String,
    },
    brandingOpportunity: {
      type: [Object, null],
    },
    header: {
      type: String,
    },
  },
  components: {
    MModal,
    MButton,
    MSearchInput,
    MTableView,
    MTable,
    MActionStatusPanel,
    MActionStatusPanelItem,
  },
  data() {
    return {
      size: '',
      submitDisabled: true,
      width: this.$vuetify.breakpoint.smAndUp ? '980px' : undefined,
      loading: false,
      searchTerm: null,
      selected: [],
      files: [],
      headers: [
        {
          value: 'name',
          text: this.$t('files.list.table.filename'),
        },
        {
          value: 'created',
          text: this.$t('files.list.table.created'),
        },
        {
          value: 'owner',
          text: this.$t('files.list.table.uploadedBy'),
          sort: (a, b) => {
            const aName = `${a?.givenName} ${a?.surname}`
            const bName = `${b?.givenName} ${b?.surname}`
            if (aName === bName) return 0
            return aName > bName ? 1 : -1
          },
        },
      ],
      sortBy: 'name',
      sortDesc: false,
      allowedFileTypes: ['image/png'],
      uploadQueue: [],
      statusPanel: {
        show: false,
        menuProps: {
          attach: '#routerViewport',
          right: true,
          bottom: true,
        },
      },
    }
  },
  mounted() {
    this.sortBy = 'name'
    this.sortDesc = false
    this.fetchFiles()
  },
  methods: {
    async fetchFiles() {
      this.loading = true
      await this.$store.dispatch('files/fetchForEcosystem', this.ecosystemId)
      this.files = this.$store.getters['files/getForEcosystem'](this.ecosystemId)
      this.loading = false
    },
    setDefaultFile(id) {
      if (!id) {
        return
      }
      const file = this.files.find(file => {
        return file.id === id
      })
      if (file) {
        this.selected.splice(0, 1, file)
      }
    },
    /**
     * Triggered when a user drops files on the table or when they upload a file to the file input
     * @param {Event} event The event containing the files
     */
    async onFileChange(event) {
      // Pull files from input or from dropped event
      const { files } = event.dataTransfer || event.target
      // add files to the queue
      for (let file of files) {
        this.uploadQueue.push(addFilesToQueue(file, this.allowedFileTypes))
      }
      // Show the status panel
      this.statusPanel.show = true
      //   this.showSnackbar = false
      // Reset file input
      if (!event.dataTransfer) {
        this.$refs.upload.value = null
      }
    },
    async uploadFiles(files) {
      const filesToUpload = files.filter(file => file.status === 'pending')
      if (filesToUpload.length > 0) {
        await uploadFiles(
          filesToUpload.map(item => item.file),
          this.ecosystemId,
          this.uploadQueue
        )
        this.sortBy = 'created'
        this.sortDesc = true
        this.fetchFiles()
      }
    },
    searchTable(item) {
      if (!this.searchTerm) {
        return undefined
      } else {
        return (
          `${item.owner?.givenName} ${item.owner?.surname}`
            .toLocaleLowerCase()
            .includes(this.searchTerm.toLocaleLowerCase()) ||
          item.name.toLocaleLowerCase().includes(this.searchTerm.toLocaleLowerCase())
        )
      }
    },
    formatDate(date) {
      if (!date) return null
      return DateTime.fromISO(date).toFormat('EEE DD, H:mm (ZZZZ)')
    },
    addFile() {
      this.$emit('addFile', this.selected)
      this.close()
    },
    close() {
      this.selected = []
      this.searchTerm = null
      this.$emit('close')
    },
    closed() {
      this.$emit('closed')
    },
    setModalProps() {
      this.size = this.getSize
    },
    resetTableSort() {
      this.sortBy = 'name'
      this.sortDesc = false
    },
  },
  computed: {
    filteredFiles() {
      const filters = {
        search: item => this.searchTable(item) ?? item,
        fileType: item => item.contentType === 'image/png',
      }
      const tableFilter = filter(this.files, filters)
      return tableFilter
    },
    itemStatuses() {
      return itemStatuses(this.uploadQueue)
    },
    statusPanelTitle() {
      return statusPanelTitle(this.uploadQueue)
    },
    modalHeader() {
      return this.$t('spaces.branding.selectFileForbrandingOpportunity', {
        oppotunity: this.brandingOpportunity?.name,
      })
    },
    getSize() {
      return this.brandingOpportunity & this.brandingOpportunity.minimumSize
        ? `${this.brandingOpportunity.minimumSize?.width} x ${this.brandingOpportunity.minimumSize?.height}`
        : ''
    },
  },
  watch: {
    uploadQueue(files) {
      this.uploadFiles(files)
    },
    'statusPanel.show'(value) {
      if (!value) {
        this.uploadQueue = []
      }
    },
    show(value) {
      if (value) {
        this.$nextTick(() => {
          this.setDefaultFile(this.brandingOpportunity?.textureId)
          this.setModalProps()
          this.resetTableSort()
        })
      }
    },
  },
}
</script>
<style lang="scss" scoped>
.modal-content {
  padding: 32px;
  :deep(*) {
    font-family: 'Mulish' !important;
    font-size: 1rem;
  }
}
.modal-subheader {
  font-family: 'Mulish' !important;
  font-style: normal;
  font-weight: 300;
  font-size: 18px;
  line-height: 24px;
  margin-bottom: 32px;
  text-align: center;
  color: #464d54;
}
.modal-subheader__image-size {
  font-size: 18px;
  color: #807ef0;
}
.files-count {
  font-family: 'Mulish' !important;
  text-align: left;
  font-weight: 500;
  font-size: 18px;
  line-height: 24px;
  color: #1f2329;
}
.table-toolbar {
  width: 100%;
  display: flex;
  align-items: flex-end;
  gap: 12px;
  :deep(*) {
    font-family: 'Mulish' !important;
  }
}
.table-search-bar {
  flex: content;
  :deep(*) {
    font-family: 'Mulish' !important;
  }
}
.files-list__table {
  :deep(td) {
    font-family: 'Mulish' !important;
  }
}
.file-name {
  font-weight: 700;
}
.file-owner,
.file-created {
  font-size: 14px;
  font-weight: 400;
}
</style>
<style lang="scss">
.action-status-panel * {
  font-family: 'Mulish' !important;
}
</style>
