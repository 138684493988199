import cloneDeep from 'lodash/cloneDeep'
import Vue from 'vue'
import { makeDefaultGetters, makeDefaultMutations, setState } from '@/utilities/store'
import api from '@/utilities/api'

const defaultState = () => ({
  myEcosystems: [],
  userImportLogs: {},
})

const properties = () => Object.keys(defaultState())
const defaultGetters = makeDefaultGetters(properties())
const defaultMutations = makeDefaultMutations(properties(), defaultState())
const state = defaultState()

const getters = {
  ...defaultGetters,
  getById: state => id => {
    return state.myEcosystems.find(ecosystem => ecosystem.id === id)
  },
  getUserById: state => (ecosystemId, userId) => {
    let ecosystem = state.myEcosystems.filter(es => es.id === ecosystemId)[0]
    let users = ecosystem.users
    const user = users.filter(user => user.id === userId)[0]
    return user
  },
  userImportLog: state => ecosystemId => {
    return state.userImportLogs[ecosystemId] ?? null
  },
  getSpaceById: state => (ecosystemId, spaceId) => {
    let ecosystem = state.myEcosystems.find(es => es.id === ecosystemId)
    return ecosystem && ecosystem.gatherings
      ? ecosystem.gatherings?.find(g => g.id === spaceId)
      : undefined
  },
}

const actions = {
  // Ecosystems
  create({ commit }, data) {
    let options = {
      data,
      method: 'POST',
      json: false,
    }

    const endpoint = 'ecosystem'
    return api(endpoint, options).then(response => {
      let newEcosystem = response.data.data
      commit('addNewEcosystem', newEcosystem)
      return newEcosystem
    })
  },
  update({ commit }, { ecosystemId, data }) {
    let options = {
      data,
      method: 'PUT',
      json: false,
    }

    const endpoint = `ecosystem/${ecosystemId}`
    return api(endpoint, options).then(response => {
      let updatedEcosystem = response.data.data
      commit('updateEcosystems', updatedEcosystem)
      return updatedEcosystem
    })
  },
  get({ commit }, ecosystemId) {
    let options = {
      method: 'GET',
      json: false,
    }

    const endpoint = `ecosystem/${ecosystemId}`
    return api(endpoint, options).then(response => {
      let updatedEcosystem = response.data.data
      commit('updateEcosystems', updatedEcosystem)
      return updatedEcosystem
    })
  },
  updateStyle({ commit }, { ecosystemId, data }) {
    let options = {
      data,
      method: 'PUT',
      json: false,
    }

    const endpoint = `ecosystem/${ecosystemId}/style`
    return api(endpoint, options).then(response => {
      let updatedEcosystemStyle = response.data.data
      commit('updateEcosystemStyle', { ecosystemId, style: updatedEcosystemStyle })
      return updatedEcosystemStyle
    })
  },
  getStyle({ commit }, { ecosystemId }) {
    let options = {
      method: 'GET',
      json: false,
    }

    const endpoint = `ecosystem/${ecosystemId}/style`
    return api(endpoint, options).then(response => {
      let updatedEcosystemStyle = response.data.data
      commit('updateEcosystemStyle', { ecosystemId, style: updatedEcosystemStyle })
      return updatedEcosystemStyle
    })
  },
  fetchSpaceStyle({ commit }, { ecosystemId, spaceId }) {
    let options = {
      method: 'GET',
    }

    const endpoint = `ecosystem/${ecosystemId}/space/${spaceId}/style`
    return api(endpoint, options).then(response => {
      let spaceStyles = response.data.data.styles
      commit('updateSpaceStyles', { ecosystemId, spaceId, spaceStyles })
      return spaceStyles
    })
  },
  fetchMine({ commit, state }, force = false) {
    if (state.myEcosystems.length > 0 && !force) {
      return state.myEcosystems
    }
    let options = { method: 'GET' }
    // const endpoint = 'workspaces/admin'
    const endpoint = 'api/users/workspaces'
    return api(endpoint, options).then(response => {
      let ecosystems = response.data.data
      // sort results alphabetically
      ecosystems.sort((a, b) => a.name.localeCompare(b.name))
      commit('setMyEcosystems', response.data.data)
    })
  },
  fetchById({ commit }, ecosystemId) {
    let options = { method: 'GET' }
    const endpoint = `workspaces/${ecosystemId}`
    return api(endpoint, options).then(response => {
      commit('updateEcosystems', response.data.data)
    })
  },
  remove({ commit }, ecosystemId) {
    commit('removeEcosystem', ecosystemId)
  },
  fetchUserById({ commit }, { ecosystemId, userId }) {
    let options = { method: 'GET' }
    const endpoint = `workspaces/${ecosystemId}/users/${userId}`
    return api(endpoint, options).then(response => {
      const userData = response.data.data.user
      const userType = response.data.data.userType
      let user = { ...userData, userType }
      commit('updateEcosystemUser', { ecosystemId, user })
    })
  },
  fetchUsers({ commit }, ecosystemId) {
    let options = { method: 'GET' }
    const endpoint = `workspaces/${ecosystemId}/users`
    return api(endpoint, options).then(response => {
      let userData = response.data.data
      let users = userData.map(data => {
        let { user, userType } = data
        return { ...user, userType }
      })
      commit('updateEcosystemUsers', { ecosystemId, users })
    })
  },
  addUser({ commit }, { ecosystemId, data }) {
    let options = { data: { ...data, workspaceId: data.ecosystemId }, method: 'POST' }
    const endpoint = `workspaces/${ecosystemId}/users`
    return api(endpoint, options).then(response => {
      const updatedUserList = response.data.data.map(updatedUser => {
        if (updatedUser.email == data.user.email) {
          return {
            ...updatedUser,
            company: data.user.company,
            jobTitle: data.user.jobTitle,
            userTimeZone: data.user.userTimeZone,
            defaultGatheringId: data.user.defaultGatheringId,
          }
        }
        return updatedUser
      })

      commit('updateUsers', { ecosystemId, updatedUserList })
    })
  },
  importUsers({ commit }, { ecosystemId, values, file }) {
    let data = new FormData()

    data.append('importFile', file, 'importFile.csv')

    let endpoint = `workspace/${ecosystemId}/users/import`
    if (values.defaultSpaceId || values.userTimeZone) {
      if (values.defaultSpaceId && values.userTimeZone) {
        endpoint = `${endpoint}?defaultGatheringId=${values.defaultSpaceId}&timeZone=${values.userTimeZone}`
      } else if (values.defaultSpaceId) {
        endpoint = `${endpoint}?defaultGatheringId=${values.defaultSpaceId}`
      } else {
        endpoint = `${endpoint}?timeZone=${values.userTimeZone}`
      }
    }

    const options = {
      data,
      method: 'POST',
      headers: {
        'Content-Type': 'multipart/form-data',
        'Content-Disposition': 'form-data',
      },
      json: false,
    }

    return api(endpoint, options).then(response => {
      const logs = response.data.data.logItems
      commit('addImportLog', { ecosystemId, logs })
    })
  },
  updateUser({ commit }, { ecosystemId, userId, data }) {
    let options = { data: { ...data, workspaceId: data.ecosystemId }, method: 'PUT' }
    const endpoint = `workspaces/${ecosystemId}/users/${userId}`
    return api(endpoint, options).then(response => {
      let updatedUserList = response.data.data

      let partialUser = updatedUserList.find(u => u.id === userId)
      const fullUser = {
        ...partialUser,
        company: data.user.company,
        jobTitle: data.user.jobTitle,
        userTimeZone: data.user.userTimeZone,
        defaultGatheringId: data.user.defaultGatheringId,
      }

      const index = updatedUserList.map(u => u.id).indexOf(userId)
      updatedUserList.splice(index, 1, fullUser)

      commit('updateUsers', { ecosystemId, updatedUserList })
    })
  },
  async removeUsers({ commit }, { ecosystemId, users }) {
    let options = { method: 'DELETE' }
    let updatedEcosystem

    for (const user of users) {
      const endpoint = `workspaces/${ecosystemId}/users/${user.id}`
      await api(endpoint, options)
        .then(response => {
          updatedEcosystem = response.data.data
        })
        .catch(err => {
          console.error(err)
        })
    }
    commit('updateEcosystems', updatedEcosystem)
  },
  // User avatars
  fetchUserAvatars({ commit }, { ecosystemId, userId }) {
    let options = { method: 'GET' }
    const endpoint = `workspaces/${ecosystemId}/users/${userId}/avatars`
    return api(endpoint, options).then(response => {
      const avatars = response.data.data.items
      commit('updateEcosystemUserAvatars', { ecosystemId, userId, avatars })
    })
  },
  // Ecosystem Spaces
  createSpace({ commit }, { ecosystemId, data }) {
    let options = { data: { ...data, workspaceId: data.ecosystemId }, method: 'POST' }
    const endpoint = `workspaces/${ecosystemId}/gatherings`
    return api(endpoint, options).then(response => {
      const newSpace = response.data.data
      commit('addNewSpace', { ecosystemId, newSpace })
      return newSpace
    })
  },
  fetchSpaces({ commit }, ecosystemId) {
    let options = { method: 'GET' }
    const endpoint = `workspaces/${ecosystemId}/gatherings`
    return api(endpoint, options).then(response => {
      const ecosystemSpaces = response.data.data
      commit('updateEcosystemSpaces', { ecosystemId, ecosystemSpaces })
    })
  },
  fetchSpaceById({ commit }, { ecosystemId, spaceId }) {
    let options = { method: 'GET' }
    const endpoint = `workspace/${ecosystemId}/gatherings/${spaceId}`
    return api(endpoint, options).then(response => {
      const updatedSpace = response.data.data
      commit('updateSpace', { ecosystemId, updatedSpace })
      return updatedSpace
    })
  },
  updateSpace({ commit }, { ecosystemId, data }) {
    let options = { data: { ...data, workspaceId: data.ecosystemId }, method: 'PUT' }
    const endpoint = `workspace/${ecosystemId}/gatherings/`
    return api(endpoint, options).then(response => {
      const updatedSpace = response.data.data
      commit('updateSpace', { ecosystemId, updatedSpace })
      return updatedSpace
    })
  },
  deleteSpace({ commit }, { ecosystemId, spaceId }) {
    let options = { method: 'DELETE' }
    const endpoint = `workspace/${ecosystemId}/gatherings/${spaceId}`
    return api(endpoint, options).then(() => {
      commit('deleteSpace', { ecosystemId, spaceId })
    })
  },
  updateSpaceStyle({ commit }, { ecosystemId, spaceId, data }) {
    let options = { method: 'PUT', data }
    const endpoint = `ecosystem/${ecosystemId}/space/${spaceId}/style`
    return api(endpoint, options).then(response => {
      const spaceStyles = response.data.data
      commit('updateSpaceStyles', { ecosystemId, spaceId, spaceStyles })
      return spaceStyles
    })
  },
}

const mutations = {
  ...defaultMutations,
  loadInitialStateData(state, data) {
    if (!data || typeof data !== 'object') return
    Object.keys(data).forEach(k => {
      Vue.set(state, k, cloneDeep(data[k]))
    })
  },
  resetState: state => setState(state, defaultState(), true),
  // Ecosystems
  addNewEcosystem(state, ecosystem) {
    state.myEcosystems.push(ecosystem)
  },
  removeEcosystem(state, ecosystemId) {
    const index = state.myEcosystems.map(es => es.id).indexOf(ecosystemId)
    state.myEcosystems.splice(index, 1)
  },
  updateEcosystems(state, updatedEcosystem) {
    state.myEcosystems = state.myEcosystems.map(ecosystem =>
      ecosystem.id !== updatedEcosystem.id ? ecosystem : { ...ecosystem, ...updatedEcosystem }
    )
  },
  // Users
  updateUsers(state, { ecosystemId, updatedUserList }) {
    let ecosystem = state.myEcosystems.filter(es => es.id === ecosystemId)[0]

    if (!ecosystem.users || ecosystem.users.length == 0) {
      ecosystem.users = updatedUserList
      return
    }

    // Add any new users and update any existing users
    let updatedUsers = []
    updatedUserList.forEach(updatedUser => {
      let matchingUser = ecosystem.users.find(esUser => esUser.id === updatedUser.id)
      if (!matchingUser) {
        updatedUsers.push(updatedUser)
      } else {
        updatedUsers.push({ ...matchingUser, ...updatedUser })
      }
    })
    ecosystem.users = updatedUsers
  },
  addImportLog(state, { ecosystemId, logs }) {
    Vue.set(state.userImportLogs, ecosystemId, logs)
  },
  addUsers(state, { ecosystemId, newUsers }) {
    let ecosystem = state.myEcosystems.filter(es => es.id === ecosystemId)[0]
    if (ecosystem.users && ecosystem.users.length) {
      ecosystem.users = [...ecosystem.users, ...newUsers]
    } else {
      ecosystem.users = newUsers
    }
  },
  updateEcosystemUser(state, { ecosystemId, user }) {
    let ecosystem = state.myEcosystems.filter(es => es.id === ecosystemId)[0]
    const index = ecosystem.users.map(u => u.id).indexOf(user.id)
    ecosystem.users.splice(index, 1)
    ecosystem.users.push(user)
  },
  updateEcosystemUsers(state, { ecosystemId, users }) {
    let ecosystem = state.myEcosystems.filter(es => es.id === ecosystemId)[0]
    ecosystem.users = users
  },
  removeUser(state, { ecosystemId, updatedEcosystem }) {
    const index = state.myEcosystems.map(es => es.id).indexOf(ecosystemId)
    state.myEcosystems.splice(index, 1)
    state.myEcosystems.push(updatedEcosystem)
  },
  // User avatars
  updateEcosystemUserAvatars(state, { ecosystemId, userId, avatars }) {
    let ecosystem = state.myEcosystems.filter(es => es.id === ecosystemId)[0]
    let user = ecosystem.users.filter(u => u.id === userId)[0]
    user.avatars = avatars
  },
  // Spaces
  addNewSpace(state, { ecosystemId, newSpace }) {
    const ecosystem = state.myEcosystems.find(es => es.id === ecosystemId)
    if (ecosystem.gatherings) {
      ecosystem.gatherings.push(newSpace)
    } else {
      ecosystem.gatherings = [newSpace]
    }
  },
  updateSpace(state, { ecosystemId, updatedSpace }) {
    let ecosystem = state.myEcosystems.find(es => es.id === ecosystemId)
    ecosystem.gatherings = ecosystem.gatherings.map(space =>
      space.id !== updatedSpace.id ? space : { ...space, ...updatedSpace }
    )
  },
  updateSpaceStyles(state, { ecosystemId, spaceId, spaceStyles }) {
    let ecosystem = state.myEcosystems.find(es => es.id === ecosystemId)
    ecosystem.gatherings = ecosystem.gatherings.map(space =>
      space.id !== spaceId ? space : { ...space, styles: spaceStyles }
    )
  },
  updateEcosystemSpaces(state, { ecosystemId, ecosystemSpaces }) {
    let ecosystem = state.myEcosystems.find(es => es.id === ecosystemId)
    ecosystem.gatherings = ecosystem.gatherings.map(space => {
      let updatedSpace = ecosystemSpaces.find(sp => sp.id === space.id)
      return !updatedSpace ? space : { ...space, ...updatedSpace }
    })
  },
  deleteSpace(state, { ecosystemId, spaceId }) {
    const spaces = state.myEcosystems.find(es => es.id === ecosystemId).gatherings
    const index = spaces.map(item => item.id).indexOf(spaceId)
    spaces.splice(index, 1)
  },
  updateEcosystemStyle(state, { ecosystemId, style }) {
    state.myEcosystems.find(ecosystem => ecosystem.id === ecosystemId).style = style
  },
}

export default {
  actions,
  getters,
  mutations,
  namespaced: true,
  state,
}
