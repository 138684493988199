<template>
  <v-btn
    @click="$emit('click')"
    :style="{ height, width }"
    class="button-text button-style"
    :class="btnClass"
    :disabled="disabled"
    :type="buttonType"
  >
    <slot />
  </v-btn>
</template>

<script>
export default {
  props: {
    type: {
      type: String,
      default: 'primary',
    },
    height: {
      type: Number,
      default: 10,
    },
    width: {
      type: Number,
      default: 10,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    buttonType: {
      type: String,
      default: 'submit',
    },
  },
  computed: {
    btnClass() {
      switch (this.type) {
        case 'secondary':
          return 'secondary-btn'
        case 'danger':
          return 'danger-btn'
        default:
          //primary
          return 'primary-btn'
      }
    },
  },
}
</script>
<style lang="scss" scoped>
.button-style {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 12px 24px !important;
  gap: 8px;
  border-radius: 8px !important;
  box-shadow: none !important;
  height: auto !important;
}
.button-text {
  text-transform: none;
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 24px;
}

.primary-btn {
  color: #ffffff !important;
  background: #807ef0 !important;
}

.secondary-btn {
  background: #ffffff !important;
  border: 1px solid #807ef0 !important;
  color: #807ef0 !important;
}

.danger-btn {
  background: #e11900 !important;
  border: 1px solid #e11900 !important;
  color: white !important;
}
</style>
