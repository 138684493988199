<template>
  <m-card class="ecosystem-branding">
    <template #header>
      <h3 class="card-title" data-cy="branding">
        {{ $t('ecosystems.create.branding') }}
      </h3>
    </template>

    <template v-if="!ecosystem.style.isStyleLocked">
      <h3>{{ $t('ecosystems.create.colors') }}</h3>
      <v-row data-cy="color-inputs">
        <v-col>
          <m-color-picker-input
            :value="internalValue.style.primaryColour"
            @input="update('style.primaryColour', $event)"
            data-cy="primaryColor"
            rules="required"
            required
          >
            {{ $t('ecosystems.create.primaryColor') }}
          </m-color-picker-input>
        </v-col>

        <v-col>
          <m-color-picker-input
            :value="internalValue.style.secondaryColour"
            @input="update('style.secondaryColour', $event)"
            data-cy="secondaryColor"
            rules="required"
            required
          >
            {{ $t('ecosystems.create.secondaryColor') }}
          </m-color-picker-input>
        </v-col>
      </v-row>

      <v-row v-if="loaded" data-cy="asset-inputs">
        <v-col>
          <!-- Not implemented yet
            <h3>{{ $t('ecosystems.create.logo') }}</h3>
            <m-asset-picker
              title="Logo wide"
              layout="vertical"
              class="mb-4"
              v-model="internalValue.logo"
            >
              <template #dropzone>
                <i18n
                  path="ecosystems.create.dropzoneLabel"
                  tag="span"
                  class="asset-picker__dropzone-title"
                >
                  <a>{{ $t('ecosystems.create.browse') }}</a>
                </i18n>
              </template>
            </m-asset-picker> 
          -->

          <h3>
            {{ $t('ecosystems.create.banners') }}
            <span style="color: #95a1aa; font-weight: 300">
              - {{ $t('ecosystems.create.theseImagesMustBeTransparent') }}</span
            >
          </h3>

          <validation-provider
            v-for="(banner, index) in banners"
            :key="banner.displayName"
            rules="size:50000"
            ref="provider"
          >
            <m-asset-picker
              :title="banner.displayName"
              :layout="assetPickerLayout"
              class="mb-4"
              :dimensions="banner.requirements.minimumSize"
              :previewBackground="internalValue.style.primaryColour"
              :value="localBanners[index]"
              :loading="assetPickersState[index]"
              @change="onBannerUpload(index, banner, $event)"
              @delete="onBannerDelete(banner)"
              data-cy="asset-pickers"
              :errorMessage="$t('validations.fileNotSupportedOrWrongResolution')"
              ref="asset-pickers"
            >
              <template #dropzone>
                <i18n
                  v-if="$vuetify.breakpoint.smAndUp"
                  path="ecosystems.create.dropzoneLabel"
                  tag="span"
                  class="asset-picker__dropzone-title"
                >
                  <a>{{ $t('ecosystems.create.browse') }}</a>
                </i18n>

                <template v-else>
                  <a>{{ $t('ecosystems.create.selectFile') }}</a>
                </template>
              </template>
            </m-asset-picker>
          </validation-provider>
        </v-col>
      </v-row>
    </template>

    <!-- Branding is locked for this ecosystem -->
    <v-row v-else justify="center" class="my-2">
      <v-col class="text-center">
        <img
          data-cy="branding-locked-image"
          src="../../../public/images/svgs/locked.svg"
          :alt="$t('ecosystems.edit.lockedImageAlt')"
        />
        <v-row justify="center" class="mt-6">
          <v-col md="5" class="text-center">
            {{ $t('ecosystems.edit.unableToBrandEcosystem') }}
          </v-col>
        </v-row>
      </v-col>
    </v-row>
  </m-card>
</template>

<script>
import { MCard, MColorPickerInput, MAssetPicker } from 'gatherings-storybook'
import { cloneDeep, set, tap } from 'lodash'
import { ValidationProvider } from 'vee-validate'

export default {
  name: 'ecosystem-branding',
  components: {
    MCard,
    MColorPickerInput,
    MAssetPicker,
    ValidationProvider,
  },
  model: {
    prop: 'ecosystem',
  },
  props: {
    /**
     * The ecosystem to update
     */
    ecosystem: {
      type: Object,
      required: true,
    },
    /**
     * The required list of banners
     */
    banners: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      localBanners: [],
      assetPickersState: [],
      loaded: false,
    }
  },
  computed: {
    assetPickerLayout() {
      return this.$vuetify.breakpoint.mobile ? 'horizontal' : 'vertical'
    },
    internalValue() {
      return this.ecosystem
    },
  },
  methods: {
    update(key, value) {
      this.$emit(
        'input',
        tap(cloneDeep(this.internalValue), v => set(v, key, value))
      )
    },
    async onBannerUpload(index, banner, file) {
      if (file instanceof File || !file) {
        if (!file) {
          return
        }

        const provider = this.$refs.provider[index]
        const { valid, errors } = await provider.validate(file)

        if (!valid) {
          this.$store.dispatch('ui/launchSnackbar', {
            color: 'error',
            message: this.$t('files.add.invalidFile'),
            subMessage: errors[0],
            buttonColor: 'white',
            icon: 'mdi-alert-octagon',
          })

          return
        }
        this.localBanners[index] = file
        this.$emit('upload', { file, banner })
      }
    },
    onBannerDelete(banner) {
      this.$emit('delete', banner)
    },
    async getBannerImage(banner, index) {
      this.setAssetPickerLoading(index, true)

      let data = null

      try {
        let response = await this.$store.dispatch('files/fetchFile', {
          file: { id: banner.textureId },
          ecosystemId: this.ecosystem.id,
        })

        data = response.data
      } catch (e) {
        console.error(e)
        return null
      } finally {
        this.setAssetPickerLoading(index, false)
      }

      return new File([data], banner.displayName, { type: data.type })
    },
    resetAssetPicker(index) {
      this.$refs['asset-pickers'][index]?.deleteFile()
    },
    setAssetPickerLoading(index, isLoading) {
      this.$set(this.assetPickersState, index, isLoading)
    },
  },
  async mounted() {
    const { logos } = this.ecosystem.style

    for (let [index, logo] of logos.entries()) {
      this.setAssetPickerLoading(index, false)

      if (logo.textureId) {
        this.localBanners[index] = await this.getBannerImage(logo, index)
      }
    }

    this.loaded = true
  },
}
</script>

<style lang="scss" scoped>
.ecosystem-branding {
  .color-picker-input {
    @media #{map-get($display-breakpoints, 'sm-and-up')} {
      margin-bottom: 1.5rem;
    }
  }

  h3:not(.card-title) {
    font-size: 1.125rem;
    line-height: 1.5;
    margin-bottom: 0.75rem;
  }
}
</style>
