import { pageTitle } from '@/utilities/page'
import i18n from '@/plugins/i18n'

const routes = [
  {
    component: () =>
      import(/* webpackChunkName: "ErrorByStatus" */ '@/views/errors/ErrorByStatus.vue'),
    meta: {
      title: pageTitle(String(i18n.t('errors.oops') + ' ' + i18n.t('errors.anErrorOccurred'))),
      layout: 'default-layout',
    },
    name: 'Error',
    path: '/error/:status',
  },
]

export default routes
