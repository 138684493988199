import axios from 'axios'
import CryptoJS from 'crypto-js'
import camelCase from 'lodash/camelCase'
import isInteger from 'lodash/isInteger'
import isNumber from 'lodash/isNumber'
import snakeCase from 'lodash/snakeCase'
import { CASIFY, DEFAULT_CURRENCY_PREFIX } from '@/utilities/constants'
import i18n from '@/plugins/i18n'

const { ACCEPTED_CASES = [] } = CASIFY || {}

const changeCaseFunctions = { camelCase, snakeCase }

/**
 * Adds a unique 'id' property to every element in the array based on the index.
 *
 * @param {Array} a array of objects
 * @param {Boolean} s ids should be returned as strings if true
 *
 * @returns {Array}
 */
export const addId = (a, s) => a.map((i, n) => ({ ...i, id: s ? String(n) : n }))

/**
 * Camelise object data properties (recursively).
 *
 * @param {Mixed} obj object to be camelised
 * @param {Boolean} recursive decides if camelisation should be applied recursively
 *
 * @returns {Object}
 */
export function camelise(obj, recursive = true) {
  return casify(obj, 'camel', recursive)
}

/**
 * Change the case pattern of object keys (recursively).
 *
 * @param {Object} obj object to be operated on
 * @param {String} targetCase the desired target case
 * @param {Boolean} recursive decides if case change should be applied recursively
 *
 * @returns {Object}
 */
export function casify(obj, targetCase = 'camel', recursive = true) {
  if (!obj || !(Array.isArray(obj) || typeof obj === 'object')) return obj
  if (!ACCEPTED_CASES.includes(targetCase))
    throw (
      'casify was provided with an invalid case - accepted cases include: ' + String(ACCEPTED_CASES)
    )
  const changeCaseFunction = targetCase + 'Case'
  const isArray = Array.isArray(obj)
  const keys = isArray ? obj : Object.keys(obj)
  const changed = isArray ? [] : {}
  for (const i in keys) {
    const key = isArray ? i : changeCaseFunctions[changeCaseFunction](keys[i])
    const val = isArray ? obj[i] : obj[keys[i]]
    if (recursive && (Array.isArray(val) || typeof val === 'object')) {
      changed[key] = casify(val, targetCase)
    } else {
      changed[key] = val
    }
  }
  return changed
}

/**
 * Copy a string to the clipboard.
 *
 * @param {String} s string to copy to the clipboard
 *
 * @returns {Void}
 */
export function copyToClipboard(s) {
  window.navigator.clipboard.writeText(s)
}

/**
 * Handle an ajax file download.
 *
 * @param {Object} payload payload
 * @param {String} payload.filename filename
 * @param {String} payload.method HTTP method (default: GET)
 * @param {String} payload.url target url
 *
 * @returns {Void}
 */
export async function downloadAjax(payload) {
  if (!payload) return Promise.reject('downloadAjax requires payload')
  const { filename, method = 'GET', url } = payload
  return axios({
    method,
    responseType: 'blob',
    url,
  })
    .then(response => {
      console.debug(response)
      downloadDirectToBrowser(response?.data, filename)
      return response
    })
    .catch(error => {
      console.error(error)
      return error
    })
}

/**
 * Handle a file download in the browser from response data.
 *
 * @param {String} data data for download
 * @param {String} filename filename (default: 'file.txt')
 *
 * @returns {Void}
 */
export function downloadDirectToBrowser(data, filename = i18n.t('common.unknown') + '.txt') {
  const link = document.createElement('a')
  link.href = window.URL.createObjectURL(new Blob([data]))
  link.download = filename
  link.click()
}

/**
 * Simulate a mouse click (mousedown, then mouseup) in the center of a DOM element.
 *
 * @param {Element} el DOM element to target
 *
 * @returns {Void}
 */
export function fakeClick(el, timeout = 60) {
  const e = new Event('mousedown')
  const offset = el.getBoundingClientRect()
  e.clientX = offset.left + offset.width / 2
  e.clientY = offset.top + offset.height / 2
  el.dispatchEvent(e)
  setTimeout(function () {
    el.dispatchEvent(new Event('mouseup'))
  }, timeout)
}

/**
 * Format a Number as a currency string.
 *
 * @param {Number} n string to be formatted
 * @param {String} prefix currency prefix to be prepended to the string (default: '£')
 *
 * @returns {String}
 */
export function formatCurrency(n, prefix = DEFAULT_CURRENCY_PREFIX) {
  const prependPrefix = v => prefix + v
  const split = Number.parseFloat(n).toFixed(2).toString().split('.')
  const str = split[0]
  if (split.length <= 1) return prependPrefix(str) + '.00'
  else if (split.length > 1 && split[1].length === 1) return `${prependPrefix(str)}.${split[1]}0`
  else if (split.length > 1 && split[1].length >= 2) return `${prependPrefix(str)}.${split[1]}`
  else return prefix + str
}

/**
 * Return an array filled with all the integers between 2 numbers.
 *
 * @param {Number} x1 First number
 * @param {Number} x2 Second number
 * @param {Boolean} inclusive The range should be considered inclusive (default: true)
 * @param {Boolean} ascending The returned array should be in ascending order (default: true)
 *
 * @returns {Array} Array containing the integers between x1 and x2
 */
export function generateIntermediateIntegers(x1, x2, inclusive = true, ascending = true) {
  if (!isNumber(x1) || !isNumber(x2)) throw 'generateIntermediateIntegers requires a range'
  if (x1 === x2) return inclusive ? [x1, x2] : []
  const min = x1 < x2 ? x1 : x2
  const max = x1 > x2 ? x1 : x2
  const intermediates = []
  const dist = max - min
  for (let i = 1; i < dist; i++) intermediates.push(min + i)
  const res = []
  if (inclusive && isInteger(min)) res.push(min)
  intermediates.forEach(i => res.push(i))
  if (inclusive && isInteger(max)) res.push(max)
  return ascending ? res : res.reverse()
}

/**
 * Hash a string.
 *
 * @param {String} str string to be hashed
 * @param {String} alg string defining algorithm to be used in hashing (optional)
 *
 * @returns {String}
 */
export function hash(str, alg = 'SHA-256') {
  if (alg === 'SHA-256') return CryptoJS.SHA256(str).toString()
  else return undefined
}

/**
 * Returns true if the passed object has the given property provided, else false.
 *
 * @param {Object} o The object to be tested
 * @param {String} s The name of the property
 *
 * @returns {String}
 */
export function hasProp(o, s) {
  return Object.prototype.hasOwnProperty.call(o, s)
}

/**
 * Maps an array of objects to an object with key referencing a shared object property.
 *
 * Important: Key must be a unique field or values will be overwritten
 *
 * @param {Array} arr array to be objectified
 * @param {String} key object property to be used as key
 * @param {Boolean} strict if true, throw error when key is missing
 *
 * @returns {Object}
 */
export function objectify(arr, key, strict = false) {
  return arr.reduce((a, v) => {
    if (Object.prototype.hasOwnProperty.call(v, key)) a[v[key]] = v
    else if (strict) throw 'cannot objectify with missing keys in strict mode'
    return a
  }, {})
}

/**
 * Returns true if the result of a Math.random() call <= the passed number. (Notes <= 0 always return false, and numbers >= 1 always return true)
 *
 * @param {Number} p propability of being true
 *
 * @returns {Boolean}
 */
export const possiBool = p => (p <= 0 ? false : Math.random() <= p)

/**
 * Returns a random float within a given range
 *
 * @param {Number} max maximum value to return (required)
 * @param {Number} min minimum value to return (default: 0)
 *
 * @returns {Number} A random float within the given range
 */
export const randomFloat = (max = 1, min = 0) => Math.random() * (max - min) + min

/**
 * Returns a random integer within a given range
 *
 * @param {Number} max maximum integer value to return (required)
 * @param {Number} min minimum integer value to return (default: 0)
 *
 * @returns {Number} A random integer within the given range
 */
export const randomInt = (max, min = 0) => Math.floor(Math.random() * (max - min)) + min

/**
 * Remove prefix from a string.
 *
 * @param {String} str string to be altered
 * @param {String} pre prefix to be removed from string
 *
 * @returns {String} Altered string
 */
export function removePrefix(str, pre) {
  return str.split(pre).slice(1).join('')
}

/**
 * Snakify object data properties (recursively).
 *
 * @param {Object} obj object to be snakified
 * @param {Boolean} recursive decides if snakification should be applied recursively
 *
 * @returns {Object}
 */
export function snakify(obj, recursive = true) {
  return casify(obj, 'snake', recursive)
}

export function getNumberSuffix(num) {
  const th = 'th'
  const rd = 'rd'
  const nd = 'nd'
  const st = 'st'

  if (num == 11 || num == 12 || num == 13) return th

  let lastDigit = num.toString().slice(-1)

  switch (lastDigit) {
    case '1':
      return st
    case '2':
      return nd
    case '3':
      return rd
    default:
      return th
  }
}

// Function to emulate pausing between interactions
export function sleep(ms) {
  return new Promise(resolve => setTimeout(resolve, ms))
}
