<template>
  <v-container :fluid="$vuetify.breakpoint.lgAndDown" class="tray">
    <v-row>
      <v-col
        offset-sm="1"
        cols="12"
        :sm="10"
        class="d-sm-block text-center d-sm-flex pa-5 pa-sm-0 justify-space-between align-center bulk-action-text"
      >
        <div class="pl-3 mb-4 mb-sm-0">
          <v-icon size="20" class="mr-3" dark color="primary">mdi-minus-box</v-icon>
          {{ quantitySelected }} {{ itemName }} {{ selected }}
        </div>
        <slot name="menu">
          <c-bulk-action-select v-on:select="bulkAction"></c-bulk-action-select>
        </slot>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {
  props: {
    quantitySelected: {
      type: Number,
    },
    totalRecords: {
      type: Number,
    },
    itemName: {
      type: String,
    },
  },
  methods: {
    bulkAction(action) {
      this.$emit('bulkAction', action)
    },
  },
  computed: {
    selected() {
      return this.totalRecords ? `of ${this.totalRecords}` : 'selected'
    },
  },
}
</script>
<style lang="scss">
.tray {
  position: sticky;
  left: 0;
  bottom: 0;
  width: 100%;
  max-width: 1750px;
  background-color: white;
  color: #363333;
  filter: drop-shadow(0px -4px 8px rgba(0, 0, 0, 0.05));
  display: flex;
  justify-content: space-between;
  align-content: center;
}
@media screen and (min-width: 600px) {
  .tray {
    height: 100px;
  }
}
@media screen and (min-width: 1751px) {
  .tray {
    filter: none;
    box-shadow: 0px -4px rgb(0 0 0 / 5%);
  }
}
.bulk-action-button {
  font-style: normal !important;
  font-weight: normal !important;
  font-size: 20px !important;
  line-height: 22px !important;
  text-transform: none !important;
  width: 170px !important;
}
.bulk-action-text {
  font-style: normal;
  color: #363333;
  padding: 16px 0px;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: #1f2329;
}

.v-btn.v-size--large {
  font-size: 20px;
}
</style>
