<template>
  <div>
    <!-- Conference checkbox -->
    <div class="d-flex align-center mb-6">
      <div class="mr-3 conference-question">
        {{ $t('events.upsert.generalDetails.isThisEventPartOfAConference') }}
      </div>
      <v-checkbox class="mt-0 pt-0" dense hide-details v-model="partOfAConference"></v-checkbox>
    </div>

    <v-expand-transition>
      <div class="mb-6" v-show="partOfAConference">
        <div class="mb-2 input-label">
          {{ $t('events.upsert.generalDetails.conferenceName') }}
          <span class="primary--text">*</span>
        </div>
        <div>
          <input
            type="text"
            class="name-input"
            v-model="conferenceName"
            :class="{ filled: conferenceName.length }"
            :placeholder="$t('events.upsert.generalDetails.enterConferenceName')"
          />
        </div>
      </div>
    </v-expand-transition>

    <div class="mb-6">
      <div class="mb-2 input-label">
        {{ $t('events.upsert.generalDetails.coverImage') }}
      </div>

      <c-image-upload>
        <template v-slot:drop-zone="{ extensions, targetDimensions, draggingInto }">
          <div
            class="image-upload image-hover"
            :class="{ 'dragging-into': draggingInto }"
            @dragenter="draggingInto = true"
            @dragleave="draggingInto = false"
          >
            <div class="upload-icon-container">
              <img width="40px" src="/images/image-upload.png" alt="Upload an image" />
            </div>
            <div class="drag-drop-text">
              {{ $t('events.upsert.generalDetails.dragAndDropImageOr') }}
              <span class="primary--text">{{ $t('events.upsert.generalDetails.browse') }}</span>
              {{ $t('events.upsert.generalDetails.forAFileToUpload') }}
            </div>
            <div class="drag-drop-rules">
              <div>
                {{ $t('events.upsert.generalDetails.supportedFileTypes') }}:
                <span class="primary--text"
                  >{{ extensions.replaceAll('.', '').toUpperCase() }}.</span
                >
              </div>
              <div>
                {{ $t('events.upsert.generalDetails.minimumResolution') }}:
                <span class="primary--text"
                  >{{ targetDimensions.width }}x{{ targetDimensions.height }}</span
                >.
              </div>
            </div>
          </div>
        </template>

        <template v-slot:image-preview="{ dataUrl, imageName, chooseFile, editImage, removeImage }">
          <div class="image-preview-container">
            <div style="cursor: pointer" @click="chooseFile">
              <div>
                <img class="image-preview" :src="dataUrl" id="gatheringImage" />
              </div>
              <div class="image-name">{{ imageName }}</div>
            </div>

            <div class="image-buttons">
              <button class="action-button edit-button" @click="editImage">
                <img class="button-icon" src="/images/edit.png" alt="Edit" />
                Edit
              </button>

              <button class="action-button remove-button" @click="removeImage">
                <img class="button-icon" src="/images/trash.png" alt="Remove" />
                Remove
              </button>
            </div>
          </div>
        </template>
      </c-image-upload>
    </div>

    <div class="mb-6">
      <div class="mb-2 input-label">
        {{ $t('events.upsert.generalDetails.name') }} <span class="primary--text">*</span>
      </div>
      <div>
        <input
          type="text"
          class="name-input"
          v-model="name"
          :class="{ filled: name.length }"
          :placeholder="$t('events.upsert.generalDetails.enterName')"
        />
      </div>
    </div>

    <div class="mb-6">
      <div class="mb-2 input-label">
        {{ $t('events.upsert.generalDetails.description') }}
      </div>
      <div class="description-text-container" :class="{ filled: description.length }">
        <div class="d-flex">
          <div
            class="description-text-area"
            contenteditable="true"
            @input="updateDescription"
            @focus="editingDescription = true"
            @focusout="editingDescription = false"
            ref="description"
          >
            <div class="description-placeholder" v-if="!description.length && !editingDescription">
              {{ $t('events.upsert.generalDetails.enterDescription') }}
            </div>
          </div>
          <div style="width: 36px" v-if="description.length">
            <v-icon @click="clearDescription">mdi-close</v-icon>
          </div>
        </div>

        <div class="description-count">{{ description.length }}/{{ allowedDescriptionLength }}</div>
      </div>
    </div>

    <div class="mb-6">
      <div class="mb-2 input-label">{{ $t('events.upsert.generalDetails.selectTimezone') }}</div>
      <m-timezone-picker
        :showSearch="true"
        :timezones="timeZones"
        orderBy="utcOffset"
        :menuProps="timezonePickerMenuProps"
        v-model="timeZone"
        style="max-width: 500px"
      />
    </div>

    <div class="date-inputs">
      <div class="date-input mb-6 mb-sm-0 mr-6">
        <div class="mb-2 input-label">
          {{ $t('events.upsert.generalDetails.startDateAndTime') }}
          <span class="primary--text">*</span>
        </div>
        <c-date-time-picker
          v-on:date="setStart($event, 'date')"
          v-on:time="setStart($event, 'time')"
        />
      </div>

      <div class="date-input">
        <div class="mb-2 input-label">
          {{ $t('events.upsert.generalDetails.endDateAndTime') }}
          <span class="primary--text">*</span>
        </div>
        <c-date-time-picker v-on:date="setEnd($event, 'date')" v-on:time="setEnd($event, 'time')" />
      </div>
    </div>
  </div>
</template>

<script>
import { MTimezonePicker } from 'gatherings-storybook'
export default {
  components: {
    MTimezonePicker,
  },
  props: ['gathering', 'ecosystemId'],
  data() {
    return {
      partOfAConference: false,
      conferenceName: '',
      name: '',
      description: '',
      editingDescription: false,
      allowedDescriptionLength: 1200,
      workspace: null,
      user: null,
      appTimeZone: null,
      timeZone: null,
      timeZones: [],
      timezonePickerMenuProps: {
        nudgeRight: '10',
        maxWidth: '650px',
      },
    }
  },
  methods: {
    updateDescription(e) {
      if (e.target.innerText.length <= this.allowedDescriptionLength) {
        this.description = e.target.innerText
      }
    },
    clearDescription() {
      this.description = ''
      this.$refs.description.innerText = ''
    },
    setStart(value, type) {
      console.log('start', value, 'type', type)
    },
    setEnd(value, type) {
      console.log('end', value, 'type', type)
    },
  },
  async mounted() {
    if (this.ecosystemId) {
      this.workspace = await this.$store.getters['ecosystems/getById'](this.ecosystemId)
    }
    this.user = this.$store.getters['auth/user']

    this.timeZones = await this.$store.getters['timeZones/all']
    this.timeZone = await this.$store.getters['timeZones/default']()
  },
}
</script>

<style lang="scss" scoped>
.conference-question {
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: #1f2329;
}

.input-label {
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 20px;
  color: #464d54;
}

.name-input {
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 12px 16px;
  gap: 6px;
  width: 100%;
  height: 48px;
  background: #ffffff;
  border: 1px solid #dce2e9;
  border-radius: 8px;
}

@media screen and (min-width: 700px) {
  .name-input {
    width: 664px;
  }
}

input::placeholder {
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: #95a1aa;
}

input:focus-visible {
  outline-color: #807ef0 !important;
}

.description-text-container {
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  padding: 12px 12px 12px 16px;
  gap: 6px;
  height: 144px;
  width: 100%;
  background: #ffffff;
  border: 1px solid #dce2e9;
  border-radius: 8px;
}

@media screen and (min-width: 700px) {
  .description-text-container {
    width: 664px;
  }
}

.description-placeholder {
  color: #95a1aa;
}

.description-count {
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 18px;
  display: flex;
  align-items: center;
  font-feature-settings: 'pnum' on, 'lnum' on;
  color: #95a1aa;
}

.description-text-area {
  width: calc(100vw - 90px);
  height: 96px;
  font-family: 'Mulish';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  overflow-y: scroll;
}

@media screen and (min-width: 700px) {
  .description-text-area {
    width: 600px;
  }
}

.description-text-area::-webkit-scrollbar {
  width: 4px !important;
}

.description-text-area:focus-visible {
  outline: none;
}

.date-inputs {
  width: 664px;
  display: block;
  margin-bottom: 24px;
}

@media screen and (min-width: 700px) {
  .date-inputs {
    display: flex;
  }
}

.date-input {
  width: 50%;
}

.filled {
  background-color: #f6f9fc;
}
</style>
