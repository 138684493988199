import { formatFileSize, isValidMimeType } from '@/utilities/files'
import i18n from '@/plugins/i18n'
import store from '@/plugins/store'
const MAX_UPLOAD_SIZE_IN_MB = parseInt(process.env.VUE_APP_MAX_UPLOAD_SIZE_IN_MB) || 50

/**
 * Returns a relevant file icon for the file
 * @param {File} file the file
 */
const getFileIcon = file => {
  if (file.type === 'application/pdf') {
    return { icon: 'file-pdf-solid', iconColor: '#FF5630' }
  }

  if (file.type.includes('image/')) {
    return { icon: 'file-image-solid', iconColor: '#FFAB00' }
  }

  if (file.type.includes('video/')) {
    return { icon: 'file-video-solid', iconColor: '#FF5630' }
  }

  return { icon: 'file-solid', iconColor: '#5E6C84' }
}

/**
 * Validates a file returning an error message string if necessary
 * @param {File} file The file
 * @return {string} The error message or null if validation has passed
 */
const validateFile = (file, allowedFileTypes) => {
  if (!isValidMimeType(file.type, allowedFileTypes)) {
    return i18n.t('files.add.fileTypeValidationMsg')
  }
  if (file.size > MAX_UPLOAD_SIZE_IN_MB * 1024 * 1024) {
    return i18n.t('files.add.fileSizeValidationMsg')
  }

  return null
}

/**
 * Adds a list of files to the upload queue
 * @param {FileList} files The list of files
 */
const addFilesToQueue = (file, allowedFileTypes) => {
  // Validate file
  const message = validateFile(file, allowedFileTypes)
  const icon = getFileIcon(file)

  return {
    file,
    message,
    fileSize: formatFileSize(file.size),
    status: message ? 'error' : 'pending',
    ...icon,
  }
}

/**
 * Uploads a list of files
 * @param {Array<File>} files Array of file objects to upload
 */
const uploadFiles = async (files, ecosystemId, uploadQueue) => {
  try {
    const response = await store.dispatch('files/upload', {
      ecosystemId,
      files,
    })

    const { message, data } = response.data

    if (message === 'Succeeded') {
      updateFilesInQueue(data, uploadQueue)
    } else {
      throw new Error(i18n.t('files.add.requestCompleteButError'))
    }
  } catch (e) {
    // Mark files as unsuccessful
    updatePendingFilesError(uploadQueue)
  }
}

/**
 * Updates the queued files with the reponse statuses
 * @param {Array} files Array of file statuses
 */
const updateFilesInQueue = async (files, uploadQueue) => {
  const pendingFiles = uploadQueue.filter(file => file.status === 'pending')

  files.forEach(({ name, succeeded, errorMessage }) => {
    const index = pendingFiles.findIndex(item => item.file.name === name)

    if (index > -1) {
      const item = pendingFiles[index]

      if (succeeded) {
        item.status = i18n.t('files.add.success')
      } else {
        item.status = i18n.t('files.add.error')
        item.message = errorMessage
      }
    }
    return pendingFiles
  })
}

/**
 * Updates the queued files with a error message
 */
const updatePendingFilesError = async uploadQueue => {
  const pendingFiles = uploadQueue.filter(file => file.status === 'pending')

  pendingFiles.forEach(item => {
    item.status = i18n.t('files.add.error')
    item.message = i18n.t('files.add.uploadFailed')
  })
  return pendingFiles
}

export {
  getFileIcon,
  validateFile,
  addFilesToQueue,
  uploadFiles,
  updateFilesInQueue,
  updatePendingFilesError,
}
