import account from '@/i18n/en/account'
import auth from '@/i18n/en/auth'
import files from '@/i18n/en/files'
import common from '@/i18n/en/common'
import currencies from '@/i18n/en/currencies'
import dashboard from '@/i18n/en/dashboard'
import events from '@/i18n/en/events'
import users from '@/i18n/en/users'
import docs from '@/i18n/en/docs'
import errors from '@/i18n/en/errors'
import fields from '@/i18n/en/fields'
import schedule from '@/i18n/en/schedule'
import plurals from '@/i18n/en/plurals'
import ui from '@/i18n/en/ui'
import validations from '@/i18n/en/validations'
import ecosystems from '@/i18n/en/ecosystems'
import spaces from '@/i18n/en/spaces'

export default {
  ...account,
  ...auth,
  ...files,
  ...common,
  ...currencies,
  ...dashboard,
  ...events,
  ...users,
  ...docs,
  ...errors,
  ...fields,
  ...plurals,
  ...schedule,
  ...ui,
  ...validations,
  ...ecosystems,
  ...spaces,
}
