<template>
  <m-notification
    :value="visible"
    :buttonColor="buttonColor"
    :color="color"
    :message="message"
    :subMessage="subMessage"
    :timeout="timeout"
    :vertical="vertical"
    :icon="icon"
    v-on:close="close"
  ></m-notification>
</template>

<script>
import { MNotification } from 'gatherings-storybook'
export default {
  components: {
    MNotification,
  },
  computed: {
    buttonColor() {
      return this.snackbar?.buttonColor
    },
    color() {
      return this.snackbar?.color
    },
    message() {
      return this.snackbar?.message
    },
    subMessage() {
      return this.snackbar?.subMessage
    },
    snackbar() {
      return this.$store.getters['ui/snackbar']
    },
    timeout() {
      return this.snackbar?.timeout
    },
    vertical() {
      return this.snackbar?.vertical
    },
    visible() {
      return this.snackbar?.visible
    },
    icon() {
      return this.snackbar?.icon
    },
  },
  methods: {
    close() {
      this.$store.dispatch('ui/closeSnackbar')
    },
  },
}
</script>
<style></style>
