import { countBy } from 'lodash'
import i18n from '@/plugins/i18n'

const itemStatuses = uploadQueue => {
  return {
    pending: 0,
    success: 0,
    error: 0,
    ...countBy(uploadQueue, 'status'),
  }
}

const statusPanelTitle = uploadQueue => {
  const { pending, success, error } = itemStatuses(uploadQueue)

  if (pending > 0) {
    return i18n.t('files.add.filesUploading', { count: pending })
  }

  if (error > 0) {
    return i18n.t('files.add.filesNotUploaded', { count: error })
  }

  if (success > 0) {
    return i18n.t('files.add.filesUploaded', { count: success })
  }

  return null
}

export { itemStatuses, statusPanelTitle }
