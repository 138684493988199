<template>
  <div class="tab-container scrollbar">
    <div v-if="activityLog.length">
      <div v-for="(events, day) in groupedEvents" :key="day">
        <div class="day-container">
          <div class="divider left"></div>
          <v-chip class="z-ten point-events-none" outlined :ripple="false">{{
            pillDate(day)
          }}</v-chip>
          <div class="divider right"></div>
        </div>

        <div>
          <div v-for="(event, index) in events" :key="event.date">
            <div class="event">
              <div class="d-flex align-center">
                <!-- Icon or avatar -->
                <div v-if="event.type !== 'avatar-created'">
                  <div class="status-button" :style="{ backgroundColor: buttonColor(event) }">
                    <v-icon small dark size="16">
                      {{ buttonIcon(event) }}
                    </v-icon>
                  </div>
                </div>
                <div v-else>
                  <v-avatar size="28" class="mr-4">
                    <img
                      :src="event.thumbnail"
                      style="height: auto"
                      :alt="`${user.givenName} ${user.surname}`"
                    />
                  </v-avatar>
                </div>

                <!-- Event detail -->
                <div class="event-detail">{{ event.detail }}</div>
              </div>

              <!-- Event time -->
              <div class="d-flex justify-end ml-1">
                <div class="event-time">{{ getTime(event) }}</div>
              </div>
            </div>

            <div v-if="index + 1 != events.length" class="divider full"></div>
          </div>
        </div>
      </div>
    </div>
    <div v-else>
      <h3>No activity to show</h3>
    </div>
  </div>
</template>

<script>
import { DateTime } from 'luxon'
import cloneDeep from 'lodash/cloneDeep'
export default {
  props: ['user', 'avatarStyle'],
  data() {
    return {
      underline: 'underline',
    }
  },
  computed: {
    activityLog() {
      let log = []

      let labels = {
        Privacy: 'Accepted Privacy Terms',
        TermsOfUse: 'Accepted Terms of Use',
        Age: 'Opted in to Age Consent',
        SpecialData: 'Opted in to Special Data Terms',
        AvatarFailed: 'Avatar upload failed',
        AvatarPending: 'Avatar being processed',
        AvatarCreated: 'Created an avatar',
        DevicePaired: 'Connected a device',
      }

      let consents = this.user.consents ? this.user.consents.items : []

      for (const [consentName, consentDate] of Object.entries(consents)) {
        let event = {
          date: consentDate,
          detail: labels[consentName],
          type: 'consent-success',
        }
        log.push(event)
      }

      let avatars = this.user.avatars?.items
      for (const key in avatars) {
        const avatar = avatars[key]

        let event = {
          date: avatar.created ?? null,
        }

        switch (avatar.thumbnail) {
          case '/img/avatar-error.jpg':
            event.detail = labels['AvatarFailed']
            event.type = 'avatar-error'
            break
          case '/img/avatar-pending.jpeg':
            event.detail = labels['AvatarPending']
            event.type = 'avatar-pending'
            break

          default:
            event.detail = labels['AvatarCreated']
            event.type = 'avatar-created'
            event.thumbnail = avatar.thumbnail ?? '/images/avatar-pending.jpeg'
            break
        }

        log.push(event)
      }

      let devices = this.user.devices
      for (const key in devices) {
        const device = devices[key]

        let event = {
          date: device.created,
          detail: labels['DevicePaired'],
          type: 'device-paired',
        }

        log.push(event)
      }

      return log
    },
    groupedEvents() {
      let activityLog = cloneDeep(this.activityLog)
      let eventsInDateOrder = activityLog.sort(function (a, b) {
        return new Date(b.date) - new Date(a.date)
      })

      let groupedEventObject = eventsInDateOrder.reduce((groups, event) => {
        const day = new DateTime.fromISO(event.date).toFormat('yyyy-MM-dd')
        if (!groups[day]) {
          groups[day] = []
        }
        groups[day].push(event)
        return groups
      }, {})

      return groupedEventObject
    },
  },
  methods: {
    pillDate(day) {
      if (!day || day == 'Invalid DateTime') {
        return 'Unknown'
      }
      let today = DateTime.now()
      let yesterday = today.minus({ days: 1 })
      let eventDate = DateTime.fromISO(day)

      if (eventDate.toISODate() === today.toISODate()) return 'Today'
      if (eventDate.toISODate() === yesterday.toISODate()) return 'Yesterday'
      return eventDate.toFormat('d LLL yyyy')
    },
    buttonColor(event) {
      return event.type.includes('error')
        ? '#F25C5C'
        : event.type.includes('pending')
        ? 'orange'
        : '#57BFA9'
    },
    buttonIcon(event) {
      return event.type.includes('error')
        ? 'mdi-alert'
        : event.type.includes('pending')
        ? 'fa-clock'
        : 'mdi-check'
    },
    getTime(event) {
      if (!event.date) {
        return ''
      }
      return DateTime.fromISO(event.date).toFormat('HH:mm')
    },
  },
}
</script>

<style lang="scss" scoped>
.divider {
  border-bottom: 1px solid rgba(0, 0, 0, 0.12);
  box-sizing: border-box;
  position: absolute;
  width: 40%;
  top: 50%;
}

.left {
  left: 0;
}

.right {
  right: 0;
}

.full {
  width: 100%;
  position: static;
  margin: 10px 0px;
}

.event {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 40px;
}

.event-detail {
  font-style: normal;
  font-size: 16px;
  line-height: 24px;
  color: #363332;
  font-weight: 400;
}

.shadow {
  filter: drop-shadow(0px 10px 30px rgba(128, 126, 240, 0.1));
}

.v-chip.v-chip--outlined.v-chip.v-chip {
  background: #f8f8ff !important;
  border-radius: 6px;
  border: none;

  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 18px;
  /* identical to box height, or 150% */

  display: flex;
  align-items: center;
  font-feature-settings: 'pnum' on, 'lnum' on;

  /* Primary/Dark Lavender */

  color: #5154c7;
}

.day-container {
  display: flex;
  justify-content: center;
  align-content: center;
  width: 100%;
  position: relative;
}

.status-button {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 16px;
  width: 28px;
  height: 28px;
  border-radius: 50%;
}

.event-time {
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 18px;
  text-align: right;
  font-feature-settings: 'pnum' on, 'lnum' on;
  color: #464d54;
  opacity: 0.6;
}
</style>
