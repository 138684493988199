<template>
  <v-progress-linear v-show="loading" fixed indeterminate color="primary"></v-progress-linear>
</template>

<script>
export default {
  computed: {
    loading() {
      return this.$store.getters['ui/loading'].isLoading
    },
  },
}
</script>
