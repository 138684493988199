import { pageTitle } from '@/utilities/page'
import i18n from '@/plugins/i18n'
import requirements from '@/routes/requirements'
import { setLayout } from '@/utilities/routes'

let routes = [
  {
    component: () => import(/* webpackChunkName: "Register" */ '@/views/account/Register.vue'),
    meta: {
      title: pageTitle(String(i18n.t('account.titles.register'))),
      requires: requirements,
    },
    name: 'Register',
    path: '/account/register',
  },
]

routes = setLayout(routes, 'default')

export default routes
