<template>
  <c-model-select
    :options="options"
    :isDisabled="disabled"
    v-model="selectedOption"
    :placeholder="placeholderText"
    data-cy="search-select"
  >
  </c-model-select>
</template>
<script>
import 'vue-search-select/dist/VueSearchSelect.css'
export default {
  props: {
    options: {
      type: Array,
      default: () => {
        return []
      },
    },
    selected: {
      type: [String, Number],
    },
    placeholder: {
      type: String,
      default: 'Select...',
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  data: () => {
    return {
      selectedOption: undefined,
      placeholderText: '',
    }
  },
  mounted() {
    if (this.selected !== undefined) {
      this.selectedOption = this.selected
    }
    if (this.placeholder) {
      this.placeholderText = this.placeholder
    }
  },
  watch: {
    selected(value) {
      this.selectedOption = value
    },
    selectedOption(option) {
      this.$emit('optionSelected', option)
    },
  },
}
</script>
