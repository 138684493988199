export default {
  account: {
    titles: {
      register: 'Complete Registration',
    },
    register: {
      complete: 'Complete',
      registration: 'registration',
      completeYourProfileAndRegisterToGetStarted:
        'Complete your profile and register to get started!',
      iConfirmIAmOver_18: 'I confirm I am over 18',
      pleaseConfirmThatYouAreOver18: 'Please confirm that you are over 18',
      error: 'This field is required and must be between 2 and 100 characters long',
    },
  },
}
