<template>
  <v-menu :close-on-content-click="true" top offset-y nudge-top="8" v-model="active">
    <template v-slot:activator="{ on, attrs }">
      <button
        v-bind="attrs"
        v-on="on"
        class="bulk-action-select"
        :class="{ 'purple-border': active }"
      >
        <div>Bulk actions</div>
        <v-icon v-if="active" size="24">mdi-chevron-up</v-icon>
        <v-icon v-else size="24">mdi-chevron-down</v-icon>
      </button>
    </template>

    <v-list class="bulk-action-select-menu">
      <v-list-item @click="bulkAction('setDefaultSpace')">
        <v-list-item-title data-cy="bulk-set-default-space" class="action-text"
          >Set default space</v-list-item-title
        >
      </v-list-item>
      <v-list-item @click="bulkAction('setNewRole')">
        <v-list-item-title data-cy="bulk-edit-role" class="action-text"
          >Edit role</v-list-item-title
        >
      </v-list-item>
      <v-list-item @click="bulkAction('remove')">
        <v-list-item-title data-cy="bulk-remove" class="action-text red--text"
          >Remove</v-list-item-title
        >
      </v-list-item>
    </v-list>
  </v-menu>
</template>

<script>
export default {
  data() {
    return {
      active: false,
    }
  },
  methods: {
    bulkAction(action) {
      this.$emit('select', action)
    },
  },
}
</script>
<style lang="scss" scoped>
.bulk-action-select {
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 12px 12px 12px 16px;
  gap: 6px;
  width: 100%;
  height: 48px;
  background: #ffffff;
  border: 1px solid #dce2e9;
  border-radius: 8px;
  font-style: normal !important;
  font-weight: 400 !important;
  font-size: 16px !important;
  line-height: 24px !important;
  color: #1f2329 !important;
}
@media screen and (min-width: 600px) {
  .bulk-action-select {
    width: 200px;
  }
}
.purple-border {
  border: 1px solid #807ef0 !important;
}
.action-text {
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
}

.bulk-action-select-menu {
  border: 1.5px solid #dce2e9;
  border-radius: 10px;
}

.v-menu__content {
  filter: drop-shadow(0px 10px 25px rgba(41, 45, 51, 0.12));
  box-shadow: none;
}
</style>
