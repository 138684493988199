import { pageTitle } from '@/utilities/page'
import i18n from '@/plugins/i18n'
import router from '@/plugins/router'
import store from '@/plugins/store'

const routes = [
  {
    component: () => import(/* webpackChunkName: "Login" */ '@/views/auth/Email.vue'),
    meta: {
      title: pageTitle(String(i18n.t('auth.titles.email'))),
      requires: { authentication: false },
    },
    name: 'Email',
    path: '/account/login',
  },
  {
    component: () => import(/* webpackChunkName: "Passcode" */ '@/views/auth/Passcode.vue'),
    meta: {
      title: pageTitle(String(i18n.t('auth.titles.passcode'))),
      requires: { authentication: false },
    },
    name: 'Passcode',
    path: '/account/passcode',
  },
  {
    name: 'Logout',
    path: '/logout',
    meta: {
      requires: { authentication: true },
    },
    beforeEnter: () => {
      store.dispatch('auth/forceLogout')
      router.push('/account/login')
    },
  },
]

export default routes
