<template>
  <div v-if="loading">
    <m-loading type="circle" :loading="true"></m-loading>
  </div>
  <div v-else>
    <div
      v-if="isCustomizableEcosystem"
      class="branding-container"
      :class="{ containerLgAndUp: $vuetify.breakpoint.mdAndUp }"
    >
      <div
        v-if="spaceStyles.length"
        class="branding-accordion-container"
        :class="{ accordionContainerLgAndUp: $vuetify.breakpoint.lgAndUp }"
      >
        <m-accordions class="branding-accordions" :value="openStyle" @value="changeOpen">
          <m-accordion
            v-for="(style, index) in spaceStyles"
            :key="index"
            class="branding-accordion"
            :completed="isComplete(style)"
          >
            <template #header>
              <div class="d-flex justify-center align-center header-text">
                <div class="mr-3">
                  <div class="purple-circle primary--text">
                    {{ getLetter(index) }}
                  </div>
                </div>
                <div>{{ styleName(style) }}</div>
              </div>
            </template>
            <template>
              <m-asset-picker
                :value="selectedFile"
                :dimensions="
                  style.minimumSize
                    ? {
                        height: style.minimumSize.height,
                        width: style.minimumSize.width,
                      }
                    : defaultDimensions
                "
                :previewBackground="style.backgroundColour"
                customFilePicker
                :layout="$vuetify.breakpoint.smAndDown ? 'horizontal' : 'vertical'"
                class="my-4"
                @click="openFilePicker(style)"
                @delete="deleteFile(style)"
              >
                <template #dropzone>
                  <div class="dropzone-text">
                    {{ $t('spaces.branding.selectFile') }}
                  </div>
                </template>
                <template #default>
                  <m-color-picker-input
                    v-if="style.selectedFile"
                    v-model="style.backgroundColour"
                  />
                  <div class="asset-picker-text">
                    {{ $t('spaces.branding.transparentImagesOnly') }}
                    <br />
                    <div v-if="style.minimumSize">
                      {{ $t('spaces.branding.recommendedSize') }}
                      <span
                        >{{ style.minimumSize.width }}x{{ style.minimumSize.height }}
                        {{ $t('spaces.branding.px') }}</span
                      >
                    </div>
                  </div>
                </template>
              </m-asset-picker>
            </template>
          </m-accordion>
        </m-accordions>
      </div>

      <div class="vr-preview" :class="{ vrPreviewLgAndUp: $vuetify.breakpoint.lgAndUp }">
        <c-space-branding-preview
          :brandingStyle="openedStylePreview"
          :letter="getLetter(openStyle)"
        />
      </div>

      <c-space-files-modal
        v-if="spaceStyles.length"
        :show="filesModal.show"
        @close="closeModal"
        @addFile="addFile"
        :ecosystemId="ecosystem.id"
        :brandingOpportunity="spaceStyles[openStyle]"
        :header="modalHeader"
      ></c-space-files-modal>
    </div>

    <div v-else>
      <div class="space-locked">
        <div>
          <img class="space-locked__img" src="/images/svgs/lock.svg" data-cy="space-locked-image" />
        </div>
        <div>
          You are not able to brand this space.<br />
          Please, contact the sustained engineering team to custom brand this space.
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { sleep } from '@/utilities/functions'
import {
  MAccordion,
  MAccordions,
  MAssetPicker,
  MColorPickerInput,
  MLoading,
} from 'gatherings-storybook'

export default {
  components: {
    MAccordion,
    MAccordions,
    MAssetPicker,
    MColorPickerInput,
    MLoading,
  },
  props: {
    ecosystem: {
      type: Object,
    },
    space: {
      type: Object,
    },
    templateId: {
      type: String,
    },
    templateName: {
      type: String,
    },
  },
  data() {
    return {
      templateStyles: [],
      spaceStyles: [],
      openStyle: 0,
      selectedFile: null,
      filesModal: {
        show: false,
      },
      loading: true,
    }
  },
  computed: {
    openedStylePreview() {
      return this.templateStyles.find(
        tStyle => tStyle.id === this.spaceStyles[this.openStyle].groupName
      )
    },
    isCustomizableEcosystem() {
      // For now customised branding will be disabled :(
      return false

      // To enable custom branding of spaces uncomment this...
      // if (!this.spaceStyles.length) return false
      // return !this.ecosystem.style.isStyleLocked
    },
    modalHeader() {
      return this.$t('spaces.branding.selectFileForBrandingOpportunity', {
        opportunity: this.styleName(this.spaceStyles[this.openStyle]),
      })
    },
    defaultDimensions() {
      return { width: 2048, height: 1024 }
    },
  },
  methods: {
    async getTemplateStyles() {
      this.templateStyles = await this.$store.dispatch('templates/fetchStyles', {
        templateId: this.templateId,
      })
    },
    async getSpaceStyles() {
      this.spaceStyles = await this.$store.dispatch('ecosystems/fetchSpaceStyle', {
        ecosystemId: this.ecosystem.id,
        spaceId: this.space.id,
      })
    },
    async fetchFiles() {
      await this.spaceStyles.forEach(async (style, index) => {
        if (style.textureId) {
          const { data } = await this.$store.dispatch('files/fetchFile', {
            file: { id: style.textureId },
            ecosystemId: this.ecosystem.id,
          })
          let file = new File([data], {
            type: data.type,
          })
          this.$set(style, 'selectedFile', file)
          if (index === 0) {
            this.selectedFile = file
          }
        }
      })
    },
    styleName(style) {
      return this.templateStyles.find(tStyle => tStyle.id === style.groupName).name
    },
    getLetter(index) {
      if (!index) {
        index = 0
      }
      return (index + 10).toString(36).toUpperCase()
    },
    openFilePicker() {
      this.filesModal.show = true
    },
    async addFile(file) {
      const { data } = await this.$store.dispatch(`files/fetchFile`, {
        file: file[0],
        ecosystemId: this.ecosystem.id,
      })
      // Create a file object for the preview to render
      const style = this.spaceStyles[this.openStyle]

      this.$set(
        style,
        'selectedFile',
        new File([data], file[0].name, {
          type: data.type,
        })
      )

      style.textureId = file[0].id
      this.setBranding()
    },
    isComplete(style) {
      return !!style.selectedFile
    },
    deleteFile(style) {
      style.selectedFile = null
      style.textureId = null
    },
    closeModal() {
      this.filesModal.show = false
    },
    changeOpen(event) {
      this.openStyle = event
    },
    setBranding() {
      this.$emit('setBranding', this.spaceStyles)
    },
  },
  async mounted() {
    this.loading = true
    try {
      await this.getTemplateStyles()
      await this.getSpaceStyles()
      await this.fetchFiles()
      await sleep(500) // time for the images to load into the asset pickers - TODO - replace this with loading state within asset picker
    } catch (error) {
      this.$store.dispatch('ui/launchSnackbar', {
        color: 'error',
        message: this.$t('errors.sorrySomethingWentWrong'),
        buttonColor: 'white',
        icon: 'mdi-alert-octagon',
      })
    }

    this.loading = false
  },
  watch: {
    spaceStyles: {
      handler(newValue) {
        if (!newValue.length) return

        this.selectedFile = newValue[this.openStyle].selectedFile
      },
      deep: true,
    },
    openStyle(newVal) {
      this.selectedFile = this.spaceStyles[newVal]?.selectedFile ?? null
    },
  },
}
</script>

<style lang="scss" scoped>
@import '~gatherings-storybook/src/styles/variables.scss';
.branding-container {
  display: flex;
  flex-direction: column;
  margin-top: 32px;
}
.containerLgAndUp {
  flex-direction: row;
}
.branding-accordion-container {
  width: 100%;
  margin-bottom: 32px;
}
.accordionContainerLgAndUp {
  width: 50%;
  margin-bottom: 0px;
  margin-right: 32px;
}
.branding-accordion {
  margin-bottom: 10px;
  box-shadow: 20px 10px 30px rgba(12, 34, 68, 0.1) !important;
  background: #ffffff !important;
  border: 1px solid #dce2e9 !important;
  border-radius: 12px !important;
}
.vr-preview {
  width: 100%;
  max-width: 840px;
}
.vrPreviewLgAndUp {
  width: 50%;
}
.header-text {
  font-family: 'Mulish';
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 24px;
  color: var(--v-grey-dark-base);
}
.purple-circle {
  border: 2px solid #807ef0;
  border-radius: 60px;
  box-sizing: border-box;
  width: 32px;
  height: 32px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.dropzone-text {
  font-family: 'Mulish';
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 24px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: var(--v-primary-base);
}
.asset-picker-text {
  font-family: 'Mulish';
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 18px;
  font-feature-settings: 'pnum' on, 'lnum' on;
  color: #95a1aa;
  justify-content: start;
  align-items: center;
}
.asset-picker-text span {
  color: var(--v-primary-base);
}
.space-locked {
  padding-top: 130px;
  display: flex;
  flex-direction: column;
  gap: 24px;
  justify-content: center;
  align-items: center;
  margin: auto;
  text-align: center;
}
</style>
