import { pageTitle } from '@/utilities/page'
import i18n from '@/plugins/i18n'
import requirements from '@/routes/requirements'
import { setLayout } from '@/utilities/routes'

let routes = [
  {
    component: () => import(/* webpackChunkName: "EcosystemsEdit" */ '@/views/ecosystems/Edit.vue'),
    meta: {
      title: pageTitle(String(i18n.t('ecosystems.titles.edit'))),
      requires: requirements,
    },
    name: 'EcosystemsEdit',
    path: '/ws/:ecosystemId/edit',
    props: true,
  },
  {
    component: () => import(/* webpackChunkName: "EcosystemsView" */ '@/views/ecosystems/View.vue'),
    meta: {
      title: pageTitle(String(i18n.t('ecosystems.titles.view'))),
      requires: requirements,
    },
    name: 'EcosystemsView',
    path: '/ws/:ecosystemId',
    props: true,
  },
  {
    component: () =>
      import(/* webpackChunkName: "EcosystemsCreate" */ '@/views/ecosystems/Create.vue'),
    meta: {
      title: pageTitle(String(i18n.t('ecosystems.titles.create'))),
      requires: requirements,
    },
    name: 'EcosystemsCreate',
    path: '/ecosystems/create',
  },
]

routes = setLayout(routes, 'default')

export default routes
