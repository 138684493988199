<template>
  <div class="space-attendees">
    <div class="space-attendees__description" data-cy="attendees-description">
      {{ $t('spaces.create.provideBasicSpaceDetailsAndManageAttendees') }}
    </div>

    <m-table-view v-if="attendees.length" class="space-attendees__table" data-cy="attendees-list">
      <template #toolbar>
        <m-search-input
          class="space-attendees__search"
          v-model="searchTerm"
          :placeholder="$t('spaces.attendeesTable.searchUsers')"
          @clear="searchTerm = ''"
          data-cy="attendees-search-input"
        ></m-search-input>
        <m-button
          :label="$t('spaces.create.addAttendees')"
          icon="plus-stroke"
          icon-right
          @click="showAttendeeModal = true"
          data-cy="table-add-attendees-button"
        ></m-button>
      </template>

      <m-table
        v-model="selectedAttendees"
        :items="computedAttendees"
        :headers="headers"
        showSelect
        :sort-by.sync="sort.key"
        :sort-desc.sync="sort.desc"
        data-cy="attendees-table"
      >
        <template #item.name="{ item }">
          <div class="d-flex align-center">
            <c-user-avatar-circle size="42" :user="item" />
            <span class="table-text">
              {{ item.name }}
            </span>
          </div>
        </template>
        <template #item.company="{ item }">
          <span class="table-text">
            {{ item.company }}
          </span>
        </template>
        <template #item.jobTitle="{ item }">
          <span class="table-text">
            {{ item.jobTitle }}
          </span>
        </template>

        <template #item.host="{ item }">
          <m-switcher
            data-cy="table-host-switch"
            :input-value="item.host"
            @change="setHost($event, item)"
          />
        </template>

        <template #item.remove="{ item }">
          <m-button
            data-cy="delete-attendee"
            @click="removeAttendee(item)"
            error
            variant="textOnly"
            icon="cross-stroke"
          >
          </m-button>
        </template>
      </m-table>
    </m-table-view>

    <div v-else class="space-attendees__add">
      <img
        class="space-attendees__img"
        src="/images/svgs/user-card.svg"
        data-cy="attendees-illustration"
      />
      <p>{{ $t('spaces.create.thereAreNoPeopleInYourSession') }}</p>
      <m-button
        data-cy="add-attendees"
        :label="$t('spaces.create.addAttendees')"
        icon="plus-stroke"
        icon-right
        @click="showAttendeeModal = true"
      ></m-button>
    </div>

    <portal selector="#routerViewport">
      <m-bulk-select-panel
        data-cy="bulk-select-panel"
        v-show="showBulkActionPanel"
        :quantity="selectedAttendees.length"
        name="user"
        @unselect-all="selectedAttendees = []"
        @bulk-action="onBulkDelete"
      ></m-bulk-select-panel>
    </portal>

    <c-space-select-attendees-modal
      :ecosystem="ecosystem"
      :attendees="attendees"
      :show="showAttendeeModal"
      @close="showAttendeeModal = false"
      @addAttendees="addAttendees"
      @addUser="showAddUserModal"
    />

    <m-modal v-model="showUserModal" header="User details" width="980">
      <template #content>
        <c-user-upsert-content
          data-cy="add-new-user-modal"
          :ecosystemId="ecosystem.id"
          title="Provide the user details."
          :user="user"
          @validate="onUserValidate"
        />
      </template>

      <template #buttons>
        <m-button label="Cancel" @click="showUserModal = false" variant="secondary"></m-button>
        <m-button
          label="Add user"
          data-cy="add-new-user-submit-button"
          :disabled="!isUserValid || isLoading"
          @click="onSubmitUser"
        ></m-button>
      </template>
    </m-modal>
  </div>
</template>

<script>
import { Portal } from '@linusborg/vue-simple-portal'
import {
  MButton,
  MTable,
  MTableView,
  MSearchInput,
  MBulkSelectPanel,
  MModal,
  MSwitcher,
} from 'gatherings-storybook'

export default {
  components: {
    MButton,
    MTable,
    MTableView,
    MSearchInput,
    MBulkSelectPanel,
    Portal,
    MModal,
    MSwitcher,
  },
  model: {
    event: 'change',
    prop: 'attendees',
  },
  props: {
    attendees: {
      type: Array,
    },
    hideBulkAction: {
      type: Boolean,
    },
    ecosystem: {
      type: Object,
    },
  },
  data() {
    return {
      searchTerm: '',
      headers: [
        {
          value: 'name',
          text: this.$t('spaces.attendeesTable.headers.name'),
        },
        {
          value: 'company',
          text: this.$t('spaces.attendeesTable.headers.company'),
        },
        {
          value: 'jobTitle',
          text: this.$t('spaces.attendeesTable.headers.jobTitle'),
        },
        {
          value: 'host',
          text: this.$t('spaces.attendeesTable.headers.host'),
        },
        {
          value: 'remove',
          sortable: false,
          width: '56px',
          align: 'center',
        },
      ],
      sort: {
        key: 'name',
        desc: false,
      },
      selectedAttendees: [],
      internalValue: this.attendees,
      showAttendeeModal: false,
      showUserModal: false,
      user: this.defaultUser(),
      isUserValid: false,
      isLoading: false,
    }
  },
  watch: {
    // Updates v-model
    internalValue: {
      handler(newValue) {
        this.$emit('change', newValue)
      },
      deep: true,
    },
    // Emits if the bulk action panel has been shown/hidden
    showBulkActionPanel(newValue) {
      this.$emit('bulk-action-panel:update', newValue)
    },
    isLoading(value) {
      this.$store.dispatch('ui/toggleLoading', { isLoading: value })
    },
  },
  computed: {
    /**
     * The attendees to display to the user
     */
    computedAttendees() {
      return this.attendees
        .map(item => ({
          ...item,
          name: `${item.givenName} ${item.surname}`,
        }))
        .filter(item => item.name.toLocaleLowerCase().includes(this.searchTerm.toLocaleLowerCase()))
    },
    /**
     * A boolean to show/hide the bulk actions
     */
    showBulkActionPanel() {
      return !this.hideBulkAction && this.selectedAttendees.length > 0
    },
  },
  methods: {
    setHost(event, item) {
      let index = this.internalValue.findIndex(attendee => attendee.id === item.id)
      this.internalValue[index].host = event
    },
    /**
     * Adds attendees
     * @param {Array} newAttendees the attendees (ie people) to add
     */
    addAttendees(newAttendees) {
      this.showAttendeeModal = false
      this.internalValue = [...this.attendees, ...newAttendees]
    },
    /**
     * Removes a single from this space
     * @param {Object} removeAttendee the person to remove
     */
    removeAttendee(removeAttendee) {
      this.internalValue = this.attendees.filter(attendee => attendee.id !== removeAttendee.id)

      this.selectedAttendees = this.selectedAttendees.filter(at => at.id !== removeAttendee.id)

      this.$store.dispatch('ui/launchSnackbar', {
        message: `1 user successfully deleted`,
        timeout: 3000,
      })
    },
    /**
     * Removes a list of selected attendees from this space
     * @param {Object} attendees
     */
    removeAttendees(attendees) {
      const selectedIds = attendees.map(attendee => attendee.id)
      this.internalValue = this.attendees.filter(attendee => !selectedIds.includes(attendee.id))

      this.$store.dispatch('ui/launchSnackbar', {
        message: `${attendees.length} users successfully deleted`,
        timeout: 3000,
      })
    },
    /**
     * Event handler for bulk deletion
     */
    onBulkDelete() {
      this.removeAttendees(this.selectedAttendees)
      this.selectedAttendees = []
    },
    /**
     * Function for showing the user modal
     */
    showAddUserModal() {
      this.showUserModal = true
      // Hide the attendees modal
      this.showAttendeeModal = false
    },
    /**
     * Fires when validating the user
     */
    onUserValidate(isValid) {
      this.isUserValid = isValid
    },
    /**
     * Triggered when clicking the add user button
     */
    onSubmitUser() {
      this.isLoading = true

      const { userType, ...user } = this.user
      const userData = {
        ecosystemId: this.ecosystem.id,
        user,
        userType,
      }

      try {
        this.createUser(userData)

        this.$store.dispatch('ui/launchSnackbar', {
          color: 'green',
          message: 'User added',
          buttonColor: 'white',
        })

        this.user = this.defaultUser()
        this.isLoading = false
        this.showUserModal = false

        // Re-display the attendees modal
        this.showAttendeeModal = true
      } catch (e) {
        this.isLoading = false
      }
    },
    /**
     * Creates a new user via the API
     */
    createUser(userData) {
      return this.$store.dispatch('ecosystems/addUser', {
        ecosystemId: this.ecosystem.id,
        data: userData,
      })
    },
    /**
     * Creates a default user object
     */
    defaultUser() {
      return {
        givenName: null,
        surname: null,
        email: null,
        company: null,
        jobTitle: null,
        userTimeZone: null,
        defaultGatheringId: null,
        userType: 2,
        isNew: true,
      }
    },
  },
}
</script>

<style lang="scss" scoped>
@import '~gatherings-storybook/src/styles/variables.scss';
.space-attendees {
  &__description {
    font-size: 18px;
    margin-top: 16px;
  }

  &__add {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 24px;
    padding: 87px 0;
    color: $black;
  }

  &__search {
    flex-grow: 1;
  }

  &__table {
    margin-top: 8px;
  }

  p {
    font-size: 16px;
    margin: 0;
  }

  &__img {
    max-width: 160px;
  }

  // Overwrite font-family
  @at-root {
    .v-application &:deep(*) {
      font-family: 'Mulish' !important;
    }
    .v-application .bulk-select-panel:deep(*) {
      font-family: 'Mulish' !important;
    }
  }
}

.table-text {
  font-family: 'Mulish';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: #1f2329;
}
</style>
