<template>
  <m-modal v-model="modal.show" :persistent="true" width="610">
    <div class="content" data-cy="ecosystem-creating-modal">
      <div class="icon">
        <img
          style="height: auto; width: auto"
          :src="`/images/svgs/${illustration.src}.svg`"
          :alt="illustration.alt"
        />
      </div>

      <div class="heading">
        <div class="heading-title">
          {{ title }}
        </div>

        <div class="heading-subtitle">
          {{ subTitle }}
        </div>
      </div>

      <div
        class="process"
        v-if="!modal.complete && !modal.hasFailed"
        data-cy="ecosystem-creating-steps"
      >
        <div v-for="step in modal.steps" :key="step.number" class="step">
          <div class="step-text">
            <span :class="{ 'red--text': step.status === 'failure' }">
              {{ step.text }}
            </span>
          </div>

          <img
            class="loading"
            v-if="step.status === 'loading'"
            src="../../assets/Spinner-1s-200px.gif"
          />
          <v-icon v-else :color="icon(step).color">{{ icon(step).name }}</v-icon>
        </div>
      </div>

      <div class="process" v-if="modal.complete" data-cy="ecosystem-creating-complete">
        <m-button
          :label="$t('ecosystems.create.modal.button.exploreSpaces')"
          @click="$emit('explore-spaces')"
        ></m-button>
      </div>

      <div class="process" v-if="modal.hasFailed">
        <m-button
          :label="$t('ecosystems.create.modal.button.manageEcosystem')"
          @click="$emit('manage-ecosystem')"
        ></m-button>
      </div>
    </div>
  </m-modal>
</template>

<script>
import { MModal, MButton } from 'gatherings-storybook'
export default {
  components: {
    MModal,
    MButton,
  },
  props: {
    modal: {
      type: Object,
    },
  },
  computed: {
    illustration() {
      return this.modal.complete
        ? {
            src: 'portal',
            alt: this.$t('ecosystems.create.modal.illustrationAlt.ecosystemBeingCreated'),
          }
        : this.modal.hasFailed
        ? {
            src: 'problem',
            alt: this.$t('ecosystems.create.modal.illustrationAlt.thereWasAProblem'),
          }
        : {
            src: 'loading-cog',
            alt: this.$t('ecosystems.create.modal.illustrationAlt.setupComplete'),
          }
    },
    title() {
      return this.modal.complete
        ? this.$t('ecosystems.create.modal.title.setupComplete')
        : this.modal.hasFailed
        ? this.$t('ecosystems.create.modal.title.weHaveHadAProblem')
        : this.$t('ecosystems.create.modal.title.buildingEcosystem')
    },
    subTitle() {
      return this.modal.complete
        ? this.$t('ecosystems.create.modal.subTitle.yourEcosystemHasbeenSuccessfullyCreated')
        : this.modal.hasFailed
        ? this.$t('ecosystems.create.modal.subTitle.ecosystemCreatedBrandingNotApplied')
        : this.$t('ecosystems.create.modal.subTitle.gettingVRReadyForYou')
    },
  },
  methods: {
    icon(step) {
      return step.status == 'success'
        ? { color: 'green', name: 'mdi-checkbox-marked-circle' }
        : step.status == 'failure'
        ? { color: 'red', name: 'mdi-alert' }
        : { color: 'blue', name: 'mdi-loading' }
    },
  },
}
</script>

<style lang="scss" scoped>
.content {
  padding: 48px 32px 50px;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 610px;
  font-family: 'Mulish';
}

.heading {
  text-align: center;
  font-style: normal;
  margin: 32px 0px;
  width: 100%;
}
.heading-title {
  font-weight: 700;
  font-size: 2.7rem;
  line-height: 46px;
  margin-bottom: 4px;
  // letter-spacing: 0.2rem;
}
.heading-subtitle {
  font-weight: 300;
  font-size: 1.285rem;
  line-height: 24px;
  max-width: 477px;
  margin: auto;
}
.process {
  display: inline-flex;
  flex-direction: column;
  flex-wrap: wrap;
  gap: 20px;
  width: 358px;
  align-items: center;
  .button {
    margin-bottom: 16px;
  }
}
.step {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 0px;
  gap: 16px;
  width: 350px;
  height: 24px;
}
.step-text {
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 20px;
  color: #464d54;
}
.loading-spin {
  animation: rotation 2s;
  animation-timing-function: linear;
  animation-iteration-count: infinite;
}
@keyframes rotation {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(359deg);
  }
}

.v-enter-active,
.v-leave-active {
  transition: opacity 0.5s ease;
}

.v-enter-from,
.v-leave-to {
  opacity: 0;
}

.step .v-icon,
.loading {
  width: 40px;
  height: 40px;
}
</style>
