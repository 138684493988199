<template>
  <div style="min-height: 400px">
    <!-- Search and "add all" small and up button-->
    <v-row>
      <v-col cols="12" sm="8" class="pt-0">
        <div class="d-block d-sm-flex">
          <c-model-select
            :options="ecosystemUsers"
            v-model="selectedUser"
            placeholder="Search users"
            data-cy="attendee-select"
            @change="selectUser()"
            :isDisabled="ecosystemUsers.length < 1"
            class="user-select placeholder-text"
          >
          </c-model-select>
          <v-btn
            data-cy="add-all-attendees-button"
            v-if="$vuetify.breakpoint.smAndUp"
            large
            @click.prevent="addAll"
            color="primary"
            rounded
            outlined
            class="ml-sm-6 pa-5 add-all-button"
            :disabled="ecosystemUsers.length < 1"
          >
            <v-icon size="16" style="margin-right: 10px">fa-user-plus</v-icon>
            Add all
          </v-btn>
        </div>
      </v-col>
    </v-row>

    <!-- "add all" xs button-->
    <v-row v-if="$vuetify.breakpoint.xs" class="pt-0 pt-sm-3">
      <v-col>
        <v-btn
          data-cy="button"
          large
          @click.prevent="addAll"
          color="primary"
          rounded
          class="pa-5 add-all-button"
          :disabled="ecosystemUsers.length < 1"
        >
          <v-icon size="16" style="margin-right: 10px">fa-plus-circle</v-icon>
          Add all
        </v-btn>
      </v-col>
    </v-row>

    <!-- User cards -->
    <v-row v-if="attendees && attendees.length" data-cy="attendees-list">
      <div class="card-grid">
        <c-user-list-card
          v-for="(attendee, index) in attendees"
          :key="`${attendee.id}-${index}`"
          :user="attendee"
          :checkBox="{ show: true, value: false }"
          :switch="{ show: true, value: attendee.host }"
          :shadow="'lighter-shadow'"
          v-on:checked="checked"
          v-on:switched="switched"
          :ecosystemId="ecosystem.id"
        />
      </div>
    </v-row>
  </div>
</template>
<script>
import 'vue-search-select/dist/VueSearchSelect.css'

export default {
  props: ['ecosystem', 'attendees'],
  data: () => ({
    selectedUser: {
      text: '',
      value: '',
    },
    ecosystemUsers: [],
    checkedUsers: [],
  }),
  computed: {
    iconSize() {
      return this.windowWidth <= 420 ? '15px' : '20px'
    },
    btnSize() {
      return this.windowWidth <= 420 ? '30px' : '40px'
    },
    userDetailsTextSize() {
      return this.windowWidth <= 420 ? '.75rem' : '1.3rem'
    },
  },
  methods: {
    removeUser(user) {
      const index = this.attendees.map(item => item.id).indexOf(user.id)
      this.attendees.splice(index, 1)
      this.ecosystemUsers.push({
        value: user.id,
        text: `${user.givenName} ${user.surname} (${user.email})`,
      })
    },
    addAll() {
      this.ecosystem.users.forEach(user => {
        if (this.attendees.map(item => item.id).indexOf(user.id) === -1) {
          this.attendees.push(user)
        }
      })
      this.ecosystemUsers = []
    },
    checked({ user, value }) {
      this.$emit('userChecked', { user, value })
    },
    switched({ user, value }) {
      let attendee = this.attendees.find(a => a.id === user.id)
      attendee.host = value // true or false
    },
  },
  watch: {
    selectedUser(selectedUser) {
      const user = this.ecosystem.users.find(user => user.id === selectedUser.value)
      if (!user) {
        return
      }
      this.attendees.push(user)
      const index = this.ecosystemUsers.findIndex(item => item.value === selectedUser.value)
      this.ecosystemUsers.splice(index, 1)
      this.selectedUser.text = ''
      this.selectedUser.value = ''
    },
    attendees(attendees) {
      for (const user of this.ecosystem.users) {
        if (
          attendees.filter(a => a.id === user.id).length === 0 &&
          this.ecosystemUsers.filter(u => u.value === user.id).length === 0
        ) {
          this.ecosystemUsers.push({
            text: `${user.givenName} ${user.surname} (${user.email})`,
            value: user.id,
          })
        }
      }
    },
  },
  mounted() {
    this.ecosystemUsers = this.ecosystem.users
      .map(user => {
        if (this.attendees.find(u => u.id === user.id) === undefined) {
          return {
            text: `${user.givenName} ${user.surname} (${user.email})`,
            value: user.id,
          }
        }
      })
      .filter(u => u !== undefined)
  },
}
</script>
<style scoped lang="scss">
.user-select {
  background: #ffffff !important;
  border: 1px solid #9e9e9e !important;
  box-sizing: border-box;
  border-radius: 10px !important;
}

.placeholder-text {
  font-style: normal !important;
  font-weight: normal !important;
  font-size: 16px !important;
  line-height: 18px !important;
  color: #9e9e9e !important;
}

.add-all-button {
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 19px;
  text-transform: none;
}

.card-grid {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  margin: 0 auto;
  width: 360px;
}

// Vuetify md
@media screen and (min-width: 768px) {
  .card-grid {
    width: 720px;
  }
}

@media screen and (min-width: 1260px) {
  .card-grid {
    width: 1080px;
  }
}

@media screen and (min-width: 1600px) {
  .card-grid {
    width: 1440px;
  }
}
</style>
