export const CASIFY = { ACCEPTED_CASES: ['camel', 'snake'] }

export const CHECKBOX_VALUES = {
  CHECKBOX_FALSE: 'check_box_outline_blank',
  CHECKBOX_TRUE: 'check_box',
}

export const DEFAULT_CURRENCY_PREFIX = '£'

export const DEFAULT_DATE_FORMAT = 'dd/MM/yyyy'

export const DEFAULT_DATE_TIME_FORMAT = 'dd/MM/yyyy HH:mm:ss'

export const DEFAULT_TABLE_OPTIONS = {
  page: 1,
  itemsPerPage: 10,
  sortBy: [],
  sortDesc: [],
  groupBy: [],
  groupDesc: [],
  multiSort: false,
  mustSort: false,
}

export const DEFAULT_TIME_FORMAT = 'HH:mm:ss'

export const FORM = {
  FIELD: { COMPONENT: 'v-text-field', RULES: [], TYPE: null },
  SUBMIT_BUTTON_ICON: 'chevron_right',
}

export const HTTP = {
  STATUSES: {
    ERRORS: [
      ...[400, 401, 402, 403, 404, 405, 406, 407, 408, 409, 410, 411, 412, 413, 414, 415, 416, 417],
      419,
      ...[421, 422, 423, 424, 425, 426, 427, 428, 429, 430, 431],
      451,
      ...[500, 501, 502, 503, 504, 505, 506, 507, 508, 509, 510, 511],
    ],
    NON_GLOBAL_ERRORS: [401, 419, 422],
    NON_ERRORS: [
      ...[100, 101, 102, 103],
      ...[200, 201, 202, 203, 204, 205, 206, 207, 208],
      226,
      ...[300, 301, 302, 303, 304, 305, 306, 307, 308],
    ],
  },
}

export const SNACKBAR_DEFAULTS = {
  visible: false,
  timeout: 4000,
  header: '',
  text: '',
  vertical: false,
  closeButton: true,
  buttonColor: 'white',
  color: '#1C00ff00', // transparent - prevents flicker of color when snackbar closing,
  icon: '',
}

export const TRUTHY = ['true', 'TRUE', true, 1]
