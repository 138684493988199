<template>
  <v-form v-model="valid" ref="userUpsertForm" class="user-upsert-form">
    <div class="user-upsert-content">
      <div class="instructions">{{ title }}</div>

      <div class="upsert-input-container">
        <c-upsert-input
          :rules="rules.fullName"
          :label="$t('fields.name')"
          placeholder="Enter user's full name"
          :content.sync="fullName"
          :required="true"
        />
        <c-upsert-input
          :rules="rules.email"
          :disabled="!user.isNew"
          :label="$t('fields.email')"
          placeholder="someone@mesmerisevr.com"
          type="email"
          :content.sync="user.email"
          :required="user.isNew"
        />
      </div>

      <div class="upsert-input-container">
        <c-upsert-input
          :rules="rules.company"
          :label="$t('fields.company')"
          placeholder="Enter user's company"
          :content.sync="user.company"
        />
        <c-upsert-input
          :rules="rules.jobTitle"
          :label="$t('fields.jobTitle')"
          placeholder="Enter user's job title"
          :content.sync="user.jobTitle"
        />
      </div>

      <div class="upsert-input-container mb-5">
        <c-upsert-input label="Default space" placeholder="Select default gathering">
          <c-search-select
            :options="defaultGatherings"
            :selected="user.defaultGatheringId"
            :placeholder="'Select default space'"
            v-on:optionSelected="updateDefaultGatheringId"
            data-cy="defaultGathering-select"
            class="default-gathering-select"
            id="defaultGatheringSelect"
          />
        </c-upsert-input>
        <c-upsert-input label="Timezone" placeholder="Choose timezone">
          <m-timezone-picker
            :showSearch="true"
            :showTimezoneField="true"
            :timezones="timeZones"
            orderBy="utcOffset"
            :menuProps="timezonePickerMenuProps"
            v-model="user.userTimeZone"
            data-cy="spaceTimeZone-select"
          />
        </c-upsert-input>
      </div>

      <div class="upsert-input-container half">
        <c-upsert-input label="Role">
          <c-search-select
            :disabled="iAmEditingMyself"
            :options="roles"
            :selected="selectedRole"
            :placeholder="'Select role'"
            v-on:optionSelected="updateRole"
          />
        </c-upsert-input>
      </div>

      <!-- Presenter checkbox hidden until api makes this data available -->
      <!-- <div>
                <v-checkbox v-model="user.isPresenter" label="Presenter" hide-details></v-checkbox>
            </div> -->
    </div>
  </v-form>
</template>

<script>
import { isRequired, minChar, maxChar, isEmailAddress } from '@/utilities/validations'
import { MTimezonePicker } from 'gatherings-storybook'
export default {
  components: {
    MTimezonePicker,
  },
  props: {
    title: {
      type: String,
      default: '',
    },
    user: {
      type: Object,
    },
    ecosystemId: {
      type: String,
    },
  },
  data() {
    return {
      valid: false,
      fullName: '',
      defaultGatherings: [],
      roles: [
        // user 0/admin 2/guest 4
        { text: 'Guest', value: 4 },
        { text: 'User', value: 0 },
        { text: 'Admin', value: 2 },
      ],
      defaultTimeZone: null,
      timeZones: [],
      timezonePickerMenuProps: {
        width: '100%',
        nudgeRight: '12px',
      },
    }
  },
  computed: {
    selectedRole() {
      return this.user.userType ?? 4
    },
    isEdit() {
      return !this.user.isNew
    },
    emailRules() {
      return [isEmailAddress, ...((this.isEdit && []) || [isRequired])]
    },
    rules() {
      return {
        fullName: [
          isRequired,
          this.isAtLeast2words,
          () => this.eachNameIs('min', 2),
          () => this.eachNameIs('max', 26),
        ],
        email: this.emailRules,
        company: [minChar(2), maxChar(26)],
        jobTitle: [minChar(2), maxChar(26)],
      }
    },
    hasSurname() {
      return this.fullName.trim().indexOf(' ') > 0
    },
    givenName() {
      // A user with more than 2 words in their name will be assigned the very last word as a surname
      // and all the other words as their givenname,
      // e.g. Jean Paul Gautier will be assigned "Jean Paul" as their first name
      // and Sharon Rawson Smith will be assigned "Sharon Rawson" as their first name
      return this.hasSurname
        ? this.fullName.trim().substring(0, this.fullName.trim().lastIndexOf(' '))
        : this.fullName.trim()
    },
    surname() {
      return this.hasSurname
        ? this.fullName
            .trim()
            .substring(this.fullName.trim().lastIndexOf(' ') + 1)
            .trim()
        : ''
    },
  },
  asyncComputed: {
    iAmEditingMyself() {
      let authUser = this.$store.getters['auth/user']
      return this.isEdit && authUser.id === this.user.id
    },
  },
  methods: {
    updateDefaultGatheringId(option) {
      this.user.defaultGatheringId = option
    },
    makeFullName(user) {
      if (user.givenName === null) {
        return ''
      }
      return `${user.givenName} ${user.surname}`
    },
    updateTimeZone(option) {
      this.user.userTimeZone = option
    },
    updateRole(userType) {
      this.user.userType = userType
    },

    isAtLeast2words() {
      return this.fullName.trim().split(' ').length >= 2 || 'Min. 2 words'
    },
    eachNameIs(type, length) {
      if (!this.fullName || !this.fullName.length) {
        return true
      }

      if (type == 'min') {
        if (this.givenName.length < length) {
          return `Given name min ${length} characters`
        }

        if (this.surname.length < length) {
          return `Surname name min ${length} characters`
        }
      }

      if (type == 'max') {
        if (this.givenName.length > length) {
          return `Given name max ${length} characters`
        }

        if (this.surname.length > length) {
          return `Surname name max ${length} characters`
        }
      }

      return true
    },
  },
  async mounted() {
    this.fullName = this.makeFullName(this.user)

    await this.$store.dispatch('spaces/fetchDefaults', {
      ecosystemId: this.ecosystemId,
      user: this.$store.getters['auth/user'],
    })

    this.defaultGatherings = await this.$store.getters['spaces/defaults'].map(g => {
      return {
        value: g.id,
        text: g.name,
      }
    })

    this.timeZones = await this.$store.getters['timeZones/all']

    this.defaultTimeZone = await this.$store.getters['timeZones/default']({
      ecosystemId: this.ecosystemId,
    })
    this.user.userTimeZone = this.user.userTimeZone ?? this.defaultTimeZone
  },
  watch: {
    fullName() {
      this.user.givenName = this.givenName
      this.user.surname = this.surname
    },
    user: {
      handler(newUser, oldUser) {
        if (newUser.id !== oldUser.id) {
          this.fullName = this.makeFullName(newUser)
          this.$refs.userUpsertForm.resetValidation()
          this.user.userTimeZone = this.user.userTimeZone ?? this.defaultTimeZone
        }
      },
      deep: true,
    },
    valid(newVal) {
      this.$emit('validate', newVal)
    },
  },
}
</script>
<style lang="scss" scoped>
.instructions {
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: #69737a;
  width: 100%;
}

.user-upsert-content {
  width: 100%;
  padding: 32px;
  display: flex;
  flex-direction: column;
  gap: 24px;
}

.upsert-input-container {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  gap: 24px;
}

@media screen and (min-width: 600px) {
  .upsert-input-container {
    flex-direction: row;
  }
}

@media screen and (min-width: 600px) {
  .half {
    width: calc(50% - 24px);
  }
}

::v-deep .v-text-field--outlined fieldset {
  border: none;
}

::v-deep .v-label {
  top: 1px;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: #69737a !important;
}

::v-deep .theme--light.v-icon {
  color: #c4cdd5;
}

::v-deep .v-text-field.v-text-field--solo .v-label {
  // color: rgba(0, 0, 0, 0.38) !important;
  width: 250px;
  top: 9px;
  left: 1px !important;
}

::v-deep .v-input__slot {
  border: none !important;
  height: 48px;
}

::v-deep .default-gathering-select {
  height: 48px;
}

::v-deep .search {
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: #69737a !important;
  height: 48px;
}

::v-deep .ui.selection.dropdown {
  border-radius: 8px;
}

::v-deep .ui.search.dropdown > .text {
  top: 2px;
}

::v-deep #defaultGatheringSelect .text {
  color: rgba(0, 0, 0, 0.87);
}

::v-deep .ui.dropdown .menu .selected.item,
.ui.dropdown.selected {
  background-color: #807ef0 !important;
  color: white !important;
}

.user-upsert-form {
  width: 100%;
  height: 100%;
  overflow: auto;
}
</style>
