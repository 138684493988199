import { pageTitle } from '@/utilities/page'
import i18n from '@/plugins/i18n'
import requirements from '@/routes/requirements'
import { setLayout } from '@/utilities/routes'

let routes = [
  {
    component: () => import(/* webpackChunkName: "UsersList" */ '@/views/users/List.vue'),
    meta: {
      title: pageTitle(String(i18n.t('users.titles.list'))),
      requires: requirements,
    },
    name: 'UsersList',
    path: '/ws/:ecosystemId/users',
    props: true,
  },
  {
    component: () => import(/* webpackChunkName: "UsersImport" */ '@/views/users/Import.vue'),
    meta: {
      title: pageTitle(String(i18n.t('users.titles.import'))),
      requires: requirements,
    },
    name: 'UsersImport',
    path: '/ws/:ecosystemId/users/import',
    props: true,
  },
  {
    component: () => import(/* webpackChunkName: "UsersImport" */ '@/views/users/ImportLog.vue'),
    meta: {
      title: pageTitle(String(i18n.t('users.titles.importLog'))),
      requires: requirements,
    },
    name: 'UsersImportLog',
    path: '/ws/:ecosystemId/users/import/log',
    props: true,
  },
  {
    component: () => import(/* webpackChunkName: "UsersView" */ '@/views/users/View.vue'),
    meta: {
      title: pageTitle(String(i18n.t('users.titles.view'))),
      requires: requirements,
    },
    name: 'UsersView',
    path: '/ws/:ecosystemId/users/:userId',
    props: true,
  },
]

routes = setLayout(routes, 'default')

export default routes
