export default {
  users: {
    titles: {
      add: 'Add users',
      view: 'User profile',
      list: 'List users',
      edit: 'Edit users',
      import: 'Import users',
      importLog: 'Import users log',
    },
    add: {
      add: 'Add',
      user: 'User',
      addANewUserToYourEcosystem: 'Add a new user to your ecosystem',
      userDetails: 'User details',
      addUsersToTheEcosystemUsingThisForm:
        'Add users to the ecosystem using this form. Users will be able to join your Spaces by logging in with the email below.',
      addUser: 'Add user',
      addToSpaces: 'Add to Spaces',
      addANewUserToOneOrMoreSpacesAsEitherAnAttendeeOrAHost:
        'Add a new user to one or more Spaces, as either an attendee or a host',
    },
    list: {
      manage: 'Manage',
      users: 'Users',
      viewAddEditAndRemoveUsersWithinYourEcosystem:
        'View, add, edit and remove users within your ecosystem',
      addNewUsersAndManageThemHere: 'Add new users and manage them here.',
    },
    edit: {
      edit: 'Edit',
      user: 'User',
      manageUserDetailsAndGeneratePin: 'Manage User details and generate pin.',
      userDetails: 'User details',
      editTheDetailsOfTheUserTheEmailOfTheUserCannotBeEdited:
        'Edit the details of the user. The email of the user cannot be edited.',
      updateUser: 'Update user',
      generatePin: 'Generate Pin',
    },
    import: {
      import: 'Import',
      users: 'Users',
      importUsersInToYourEcosystem: 'Import Users in to your ecosystem',
      selectAFileToContinue: 'Select a file to continue',
      selectAFile: 'Select a file',
      browse: 'Browse',
      defaultSpace: 'Default Space',
      timeZone: 'Time zone',
      importFile: 'Import file',
      downloadATemplateCsvFile: 'Download a template CSV file',
    },
    importLog: {
      importUsers: 'Import Users',
      log: 'Log',
      totalRecordsImported: 'Total records imported -',
      succesfullyImported: 'Successfully imported -',
      failed: 'Failed -',
    },
    remove: {
      question: 'Remove this user from the ecosystem? | Remove these users from the ecosystem?',
    },
  },
}
