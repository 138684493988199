<template>
  <m-form ref="form" @submit="onSubmit">
    <c-ecosystem-details v-model="form"></c-ecosystem-details>
    <c-ecosystem-branding
      v-model="form"
      :banners="ecosystem.style.logos.length > 0 ? ecosystem.style.logos : requiredBanners"
      @upload="onFileUpload"
      @delete="onFileDelete"
      ref="branding"
    ></c-ecosystem-branding>

    <template #footer="{ invalid, dirty }">
      <!-- Teleport action bar to the bottom of the page -->
      <portal selector="#routerViewport">
        <m-action-bar>
          <m-button
            v-if="dirty && showCancel"
            data-cy="cancel-button"
            class="ecosystem__cancel"
            @click="cancel"
            :label="cancelLabel"
            variant="secondary"
          ></m-button>

          <template #action>
            <div class="flex-spacer"></div>

            <m-button
              data-cy="submit-button"
              class="ecosystem__submit"
              @click="submit"
              :disabled="!dirty || invalid || disableSubmit"
              :label="submitLabel"
            ></m-button>
          </template>
        </m-action-bar>
      </portal>
    </template>
  </m-form>
</template>

<script>
import { Portal } from '@linusborg/vue-simple-portal'
import { MForm, MActionBar, MButton } from 'gatherings-storybook'
import { cloneDeep } from 'lodash'

export default {
  components: {
    MForm,
    MActionBar,
    MButton,
    Portal,
  },
  model: {
    prop: 'ecosystem',
  },
  props: {
    /**
     * The ecosystem data to use, can be bound to the v-model
     */
    ecosystem: {
      required: true,
    },
    /**
     * Set true/false to disable the submit button
     */
    disableSubmit: {
      type: Boolean,
      default: false,
    },
    /**
     * The submit button label
     */
    submitLabel: {
      type: String,
    },
    /**
     * Show or hide the cancel button
     */
    showCancel: {
      type: Boolean,
      default: false,
    },
    /**
     * The cancel button label
     */
    cancelLabel: {
      type: String,
    },
  },
  data() {
    return {
      // form: this.ecosystem,
      // TODO: This is hard coded for now but in future will come from an api request
      requiredBanners: [
        {
          id: '00000001-0001-0001-0001-000000000001',
          displayName: 'Narrow Portrait',
          textureId: null,
          thumbnailUrl: '',
          requirements: {
            minimumSize: {
              width: 512,
              height: 2048,
            },
            aspectRatio: {
              width: 1,
              height: 4,
            },
          },
        },
        {
          id: '00000002-0002-0002-0002-000000000002',
          displayName: 'Wide Portrait',
          textureId: null,
          thumbnailUrl: '',
          requirements: {
            minimumSize: {
              width: 1024,
              height: 2048,
            },
            aspectRatio: {
              width: 1,
              height: 2,
            },
          },
        },
        {
          id: '00000003-0003-0003-0003-000000000003',
          displayName: 'Landscape',
          textureId: null,
          thumbnailUrl: '',
          requirements: {
            minimumSize: {
              width: 2048,
              height: 512,
            },
            aspectRatio: {
              width: 4,
              height: 1,
            },
          },
        },
        {
          id: '00000004-0004-0004-0004-000000000004',
          displayName: 'Square',
          textureId: null,
          thumbnailUrl: '',
          requirements: {
            minimumSize: {
              width: 2048,
              height: 2048,
            },
            aspectRatio: {
              width: 1,
              height: 1,
            },
          },
        },
        {
          id: '00000005-0005-0005-0005-000000000005',
          displayName: 'Holding Image',
          textureId: null,
          thumbnailUrl: '',
          requirements: {
            minimumSize: {
              width: 1920,
              height: 1080,
            },
            aspectRatio: {
              width: 16,
              height: 9,
            },
          },
        },
      ],
      // Clone the object so we don't modify the original
      internalValue: cloneDeep(this.ecosystem),
    }
  },
  watch: {
    ecosystem: {
      handler(newVal) {
        this.internalValue = newVal
      },
      deep: true,
    },
  },
  computed: {
    form: {
      get() {
        return this.internalValue
      },
      set(value) {
        this.internalValue = value
        this.$emit('input', value)
      },
    },
  },
  async mounted() {
    if (!this.ecosystem.timeZone) {
      this.form.timeZone = await this.$store.getters['timeZones/default']()
    } else {
      this.form.timeZone = this.ecosystem.timeZone
    }
  },
  methods: {
    onSubmit() {
      this.$emit('submit', this.form)
    },
    cancel() {
      this.$emit('cancel')
    },
    submit() {
      this.$refs.form.submit()
    },
    onFileUpload({ file, banner }) {
      this.$emit('file-selected', { file, banner })
    },
    onFileDelete(banner) {
      this.$emit('file-delete', banner)
    },
    resetAssetPicker(index) {
      this.$refs.branding.resetAssetPicker(index)
    },
    setAssetPickerLoading(index, isLoading) {
      this.$refs.branding.setAssetPickerLoading(index, isLoading)
    },
    reset() {
      this.$refs.form.$refs.obs.reset()
    },
  },
}
</script>

<style scoped lang="scss">
// Full width button on mobile
@media #{map-get($display-breakpoints, 'xs-only')} {
  .ecosystem__cancel {
    margin-bottom: 1rem;
  }

  .ecosystem__cancel,
  .ecosystem__submit {
    width: 100%;
  }
}
</style>
