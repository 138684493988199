<template>
  <v-dialog v-model="show" width="1000" elevation="0">
    <div class="cropper-container">
      <div class="relative cropper-main">
        <v-btn @click="close" class="pr-0" plain absolute top right>
          <v-icon size="24">mdi-close</v-icon>
        </v-btn>

        <!-- Header -->
        <div class="cropper-title">
          <div class="mb-4 cropper-title-text cropper-title-header">Crop your photo</div>
          <div class="cropper-title-text cropper-title-subheader">
            Crop the image as you would like
          </div>
        </div>

        <!-- Cropper + zoom buttons -->
        <div v-show="!inPreview">
          <div class="cropper-wrapper">
            <cropper
              ref="cropper"
              class="cropper"
              :src="imageUrl"
              :default-boundaries="boundaries"
              :transitions="true"
              :stencil-props="stencilProps"
              @change="updateSize"
              foregroundClass="cropper-background"
              backgroundClass="cropper-background"
              :image-restriction="restriction"
              :min-height="minimumDimensions.height"
              :min-width="minimumDimensions.width"
            />
            <canvas
              ref="resizingCanvas"
              :width="targetWidth"
              :height="targetHeight"
              class="d-none"
            ></canvas>
          </div>
          <div class="zoom-panel">
            <div class="zoom-icons">
              <v-icon @click="zoom(2)" size="24" class="zoom-icon">mdi-magnify-plus-outline</v-icon>
              <v-icon @click="zoom(0.5)" size="24" class="zoom-icon"
                >mdi-magnify-minus-outline</v-icon
              >

              <v-icon size="24" @click="move('up')" class="zoom-icon">mdi-arrow-up</v-icon>
              <v-icon size="24" @click="move('down')" class="zoom-icon">mdi-arrow-down</v-icon>

              <v-icon @click="move('left')" size="24" class="zoom-icon">mdi-arrow-left</v-icon>
              <v-icon @click="move('right')" size="24" class="zoom-icon">mdi-arrow-right</v-icon>

              <v-icon @click="rotate(90)" size="24" class="zoom-icon">mdi-refresh</v-icon>
            </div>

            <div class="reset" @click="reset">Reset</div>
          </div>
        </div>

        <div v-if="inPreview" class="cropper">Preview</div>
      </div>

      <!-- Footer -->
      <div class="cropper-footer">
        <c-button @click="close" type="secondary"> Cancel </c-button>
        <c-button v-if="!inPreview" @click="crop"> {{ cropButtonText }} </c-button>
        <c-button v-else @click="apply"> Apply </c-button>
      </div>
    </div>
    <!-- End cropper -->
  </v-dialog>
</template>
<script>
import { Cropper } from 'vue-advanced-cropper'

export default {
  components: {
    Cropper,
  },
  props: {
    image: {
      type: Object,
    },
    show: {
      type: Boolean,
    },
    preview: {
      type: Boolean,
      default: false,
    },
    aspectRatio: {
      type: String,
      default: '1/1', // 'width/height'
    },
    restriction: {
      type: String,
      default: 'fit-area',
      validator: function (value) {
        return ['fit-area', 'none', 'stencil', 'fill-area'].indexOf(value) !== -1
      },
    },
    minimumDimensions: {
      type: Object,
      default: () => ({
        width: 512,
        height: 512,
      }),
    },
  },
  data() {
    return {
      size: {
        width: null,
        height: null,
      },
      inPreview: false,
    }
  },
  methods: {
    boundaries({ cropper }) {
      return {
        width: cropper.clientWidth,
        height: cropper.clientHeight,
      }
    },
    updateSize({ coordinates }) {
      this.size.width = Math.round(coordinates.width)
      this.size.height = Math.round(coordinates.height)
    },
    zoom(factor) {
      this.$refs.cropper.zoom(factor)
    },
    move(direction) {
      if (direction === 'left') {
        this.$refs.cropper.move(-this.size.width / 4)
      } else if (direction === 'right') {
        this.$refs.cropper.move(this.size.width / 4)
      } else if (direction === 'up') {
        this.$refs.cropper.move(0, -this.size.height / 4)
      } else if (direction === 'down') {
        this.$refs.cropper.move(0, this.size.height / 4)
      }
    },
    rotate(angle) {
      this.$refs.cropper.rotate(angle)
    },
    reset() {
      this.$refs.cropper.reset()
    },
    crop() {
      if (!this.preview) {
        this.apply()
      } else {
        this.inPreview = true
      }
    },
    close() {
      this.inPreview = false
      this.$emit('close')
    },
    apply() {
      if (this.aspectRatio) {
        const canvas = this.$refs.resizingCanvas
        const context = canvas.getContext('2d')
        canvas.width = this.targetWidth
        canvas.height = this.targetHeight
        context.drawImage(
          this.$refs.cropper.getResult().canvas,
          0,
          0,
          this.targetWidth,
          this.targetHeight
        )
        this.$emit('crop', canvas.toDataURL())
      } else {
        this.$emit('crop', this.$refs.cropper.getResult().canvas.toDataURL())
      }
      this.inPreview = false
      this.$emit('close')
    },
  },
  computed: {
    imageUrl() {
      return this.image?.url ?? ''
    },
    stencilProps() {
      return {
        aspectRatio: this.targetWidth / this.targetHeight,
      }
    },
    targetWidth() {
      return this.aspectRatio?.split('/')[0]
    },
    targetHeight() {
      return this.aspectRatio?.split('/')[1]
    },
    cropButtonText() {
      return this.preview ? 'Crop & Preview' : 'Crop & close'
    },
  },
}
</script>

<style lang="scss" scoped>
::v-deep .v-dialog {
  box-shadow: none;
}

.cropper-container {
  background: #ffffff;
  box-shadow: 0px 4px 24px rgba(0, 0, 0, 0.15);
  border-radius: 16px;
  display: flex;
  flex-direction: column;
}

.cropper-main {
  padding: 56px 32px 0px 32px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  flex-grow: 1;
}

.cropper-title {
  margin-bottom: 24px;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0px;
  width: 546px;
}

.cropper-title-text {
  font-style: normal;
  text-align: center;
}

.cropper-title-header {
  font-weight: 700;
  font-size: 32px;
  line-height: 36px;
  color: #1f2329;
}

.cropper-title-subheader {
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: #69737a;
}

.cropper-wrapper {
  position: relative;
  user-select: none;
  border: solid 1px #eee;
  background-color: gray;
}

.cropper {
  height: 390px;
  width: 936px;
  background-color: gray;
}

.zoom-panel {
  margin-top: 16px;
  margin-bottom: 32px;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 12px;
}

.zoom-icons {
  margin-left: 32px;
}

.zoom-icon {
  padding: 8px;
  cursor: pointer;
}

.zoom-icon:hover {
  color: #807ef0;
  background-color: #f8f8ff;
}

.reset {
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 24px;
  color: #807ef0;
}

.cropper-footer {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  padding: 24px 32px;
  gap: 200px;
  height: 96px;
  background: #ffffff;
  border: 1px solid #dce2e9;
  border-radius: 0px 0px 16px 16px;
}
</style>
<style>
.cropper-background {
  background: gray !important;
}
</style>
