import cloneDeep from 'lodash/cloneDeep'
import Vue from 'vue'
import { makeDefaultGetters, makeDefaultMutations } from '@/utilities/store'
import api from '@/utilities/api'
const ipLocation = require('iplocation')
import axios from 'axios'

const defaultState = () => ({
  authenticated: false,
  deviceId: null,
  token: null,
  loginTimestamp: null,
  passcodeRequested: false,
  user: {
    email: null,
    givenName: null,
    surname: null,
    consents: {
      items: {},
    },
  },
  ipDetails: {
    address: null,
    location: null,
  },
})

const properties = () => Object.keys(defaultState())
const defaultGetters = makeDefaultGetters(properties())
const defaultMutations = makeDefaultMutations(properties(), defaultState())
const state = defaultState()

const getters = {
  ...defaultGetters,
  userHasAgeConsent(state) {
    return !!state.user.consents.items.Age?.length > 0
  },
}

const actions = {
  forceLogout({ commit }) {
    commit('resetAllState', {}, { root: true })
  },

  onInitialLoad({ commit }, { initialStateData }) {
    commit('loadInitialStateData', initialStateData)
  },

  requestPasscode({ commit }, data) {
    let options = { data, method: 'POST' }
    let endpoint = `authenticate/request-email-code`

    commit('updateUser', data)

    return api(endpoint, options).then(() => {
      commit('setPasscodeRequested', true)
    })
  },

  async registerIPAddress({ commit }) {
    try {
      const ipResponse = await axios('https://api.ipify.org')
      const userIPAddress = ipResponse.data
      const userLocation = await ipLocation(userIPAddress)
      let ipDetails = {
        address: userIPAddress,
        location: userLocation,
      }

      commit('setIpDetails', ipDetails)
    } catch (error) {
      console.log('Failed to fetch IP details and determine user location')
      console.error(error)
    }
  },

  verifyPasscode({ commit }, data) {
    const deviceId = this.getters['auth/deviceId'] ?? 'web-browser-management-app'
    commit('setDeviceId', deviceId)
    const email = this.getters['auth/user'].email
    data = {
      passcode: data.passcode,
      email: email,
      deviceId: deviceId,
    }

    let options = { data, method: 'POST' }
    let endpoint = `authenticate/email`

    return api(endpoint, options).then(response => {
      commit('updateUser', { ...response.data.data.user, id: response.data.data.id })
      commit('setToken', response.data.data.token)
      commit('setLoginTimestamp', +new Date())
      commit('setAuthenticated', true)
    })
  },
  register({ commit }, data) {
    let options = { data, method: 'POST' }
    let endpoint = `users/register`
    return api(endpoint, options).then(response => {
      commit('updateUser', response.data.data)
    })
  },
}

const mutations = {
  ...defaultMutations,
  loadInitialStateData(state, data) {
    if (!data || typeof data !== 'object') return
    Object.keys(data).forEach(k => {
      Vue.set(state, k, cloneDeep(data[k]))
    })
  },
  updateUser(state, userData) {
    state.user = { ...state.user, ...userData }
  },
}

export default {
  actions,
  getters,
  mutations,
  namespaced: true,
  state,
}
