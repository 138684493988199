<template>
  <v-dialog v-model="show" width="610px" persistent data-cy="confirm-delete-workspace-dialog">
    <v-card class="confirm-delete-dialog">
      <div class="d-flex flex-column justify-center align-center confirm-delete-text">
        <!-- Close icon -->
        <div class="d-flex justify-end" style="width: 100%">
          <v-icon dark color="black" size="24px" @click="close"> mdi-close </v-icon>
        </div>

        <!-- Trash can image -->
        <div class="mt-2 mb-6">
          <img src="/images/delete.png" style="width: 160px" alt="Delete" />
        </div>

        <div class="question">
          <slot name="question">
            Are you sure you want <br />
            to delete
            <span v-if="quantity > 1">these</span>
            <span v-else>this</span>
            {{ itemName }}?
          </slot>
        </div>

        <div class="detail">
          <slot name="detail"></slot>
        </div>
      </div>

      <div class="footer-button-container">
        <v-btn
          type="submit"
          height="48"
          @click.prevent="decide('no')"
          color="primary"
          outlined
          class="footer-button mb-4 mb-sm-0"
          data-cy="delete-no"
        >
          Cancel
        </v-btn>
        <v-btn
          type="submit"
          height="48"
          @click.prevent="decide('yes')"
          color="red"
          dark
          class="footer-button"
          data-cy="delete-yes"
        >
          {{ actionName }} {{ itemName }} ({{ quantity }})
        </v-btn>
      </div>
    </v-card>
  </v-dialog>
</template>
<script>
export default {
  props: {
    itemName: {
      type: String,
      default: 'item',
    },
    show: {
      type: Boolean,
    },
    quantity: {
      type: Number,
    },
    detail: {
      type: String,
    },
    actionName: {
      type: String,
      default: 'Delete',
    },
  },
  methods: {
    decide(decision) {
      this.$emit('decision', decision)
    },
    close() {
      this.$emit('close')
    },
  },
}
</script>
<style lang="scss" scoped>
.confirm-delete-dialog {
  box-shadow: 0px 4px 24px rgba(0, 0, 0, 0.15);
  border-radius: 16px;
  height: 95vh;
}
::v-deep .v-dialog:not(.v-dialog--fullscreen) {
  max-height: 95%;
}
@media screen and (min-width: 600px) {
  .confirm-delete-dialog {
    height: auto;
  }
}
.confirm-delete-text {
  padding: 24px !important;
}
.question {
  font-weight: 700;
  font-size: 32px;
  line-height: 36px;
  text-align: center;
  color: #252729;
}
.detail {
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  text-align: center;
  color: #73787c !important;
  margin: 16px 0 16px;
}
.footer-button-container {
  display: block;
  padding: 24px 32px;
  height: 96px;
  background: #ffffff;
  border: 1px solid #dce2e9;
}
@media screen and (min-width: 600px) {
  .footer-button-container {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-start;
  }
}
.footer-button {
  border-radius: 8px;
  text-transform: none;
  font-style: normal;
  font-weight: 700;
  font-size: 16px !important;
  line-height: 24px !important;
  padding: 12px 75px !important;
  width: 100%;
}

@media screen and (min-width: 600px) {
  .footer-button {
    width: auto;
  }
}
</style>
