<template>
  <div class="steps-container tab-container scrollbar">
    <div v-for="(step, index) in steps" :key="index" class="onboarding-step">
      <div class="icon-and-name">
        <div v-if="isComplete(step)" class="step-circle purple-middle">
          <v-icon size="9px" color="white">mdi-check</v-icon>
        </div>
        <div v-else-if="isNext(step)" class="step-circle purple-edge">
          <div class="step-circle purple-middle target"></div>
        </div>

        <div v-else class="step-circle"></div>

        <div class="step-name">{{ step.name }}</div>
      </div>

      <div class="step-datetime">{{ step.date }}</div>

      <div v-if="index != Object.keys(steps).length" class="step-connector"></div>
    </div>
  </div>
</template>

<script>
import cloneDeep from 'lodash/cloneDeep'
import { DateTime } from 'luxon'
export default {
  props: ['user'],
  computed: {
    steps() {
      let steps = {
        1: { name: 'Agreed to Terms of Use', date: null },
        2: { name: 'Agreed to Privacy Policy', date: null },
        3: { name: 'Agreed to Age Consent', date: null },
        4: { name: 'Agreed to Special Data Terms', date: null },
        5: { name: 'Created an Avatar', date: null },
        6: { name: 'Connected a Device', date: null },
      }

      let consents = this.user?.consents?.items
      if (!consents) {
        return steps
      }

      if (!consents.TermsOfUse) {
        return steps
      }
      steps['1'].date = this.formatDate(consents.TermsOfUse)

      if (!consents.Privacy) {
        return steps
      }
      steps['2'].date = this.formatDate(consents.Privacy)

      if (!consents.Age) {
        return steps
      }
      steps['3'].date = this.formatDate(consents.Age)

      if (!consents.SpecialData) {
        return steps
      }
      steps['4'].date = this.formatDate(consents.SpecialData)

      let avatars = this.user.avatars?.items
      if (!avatars || !avatars.length > 0) {
        return steps
      }

      let firstAvatar = cloneDeep(this.user.avatars.items).sort(function (a, b) {
        return new Date(b.created) - new Date(a.created)
      })[0]
      steps['5'].date = this.formatDate(firstAvatar.created)

      let devices = cloneDeep(this.user.devices)
      if (!devices || devices.length < 1) {
        return steps
      }

      let firstDevice = devices.sort(function (a, b) {
        return new Date(b.created) - new Date(a.created)
      })[0]
      steps['6'].date = this.formatDate(firstDevice.created)

      return steps
    },
  },
  methods: {
    formatDate(date) {
      const d = DateTime.fromISO(date).toFormat('dd.MM.yyyy')
      const t = DateTime.fromISO(date).toFormat('HH:mm')
      return `${d} • ${t}`
    },
    isComplete(step) {
      return step.date != null
    },
    isNext(step) {
      let steps = Object.entries(cloneDeep(this.steps))
      let incompleteSteps = steps.filter(step => !step[1].date)

      if (!incompleteSteps) {
        return false
      }

      return (
        incompleteSteps.sort(function (a, b) {
          return new Date(b[1].date) - new Date(a[1].date)
        })[0][1].name === step.name
      )
    },
  },
}
</script>

<style lang="scss" scoped>
.steps-container {
  display: flex;
  flex-direction: column;
  gap: 16px;
}

.onboarding-step {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 12px 16px;
  border: 1px solid #dce2e9;
  border-radius: 8px;
  position: relative;
}

.step-circle {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 2px;
  width: 20px;
  height: 20px;
  border-radius: 20px;
  border: 1px solid #dce2e9;
}

.purple-edge {
  border: solid 1px #807ef0;
}

.purple-middle {
  background: #807ef0;
}

.icon-and-name {
  display: flex;
  gap: 12px;
}

.step-datetime {
  font-weight: 400;
  font-size: 12px;
  line-height: 18px;
  font-feature-settings: 'pnum' on, 'lnum' on;
  color: #464d54;
  opacity: 0.6;
}

.step-connector {
  border-right: solid 2px #e8eef3;
  position: absolute;
  height: 20px;
  top: 45px;
  left: 25px;
}

.target {
  position: absolute;
  height: 12px;
  width: 12px;
}

.step-name {
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  /* identical to box height, or 150% */

  /* Greyscale/Black */

  color: #1f2329;
}
</style>
