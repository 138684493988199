<template>
  <div
    data-cy="overlay-letter"
    class="letter-container"
    :style="{ bottom: `${letterPosition.height}%`, left: `${letterPosition.width}%` }"
  >
    <span>{{ letter }}</span>
  </div>
</template>

<script>
export default {
  props: {
    letterPosition: {
      type: Object,
    },
    letter: {
      type: String,
    },
  },
}
</script>

<style lang="scss" scoped>
.letter-container {
  position: absolute;
  animation: grow-animation 2s linear infinite;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 10%;
  max-width: 80px;
  border-radius: 50%;
  border: 5px solid #807ef0;
  background: white;
  color: #807ef0;
  font-size: 30px;
  font-weight: bold;
  z-index: 1;
  cursor: default;
}
.letter-container::after {
  content: '';
  display: block;
  padding-bottom: 100%;
}

@keyframes grow-animation {
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.1);
  }
  100% {
    transform: scale(1);
  }
}
</style>
