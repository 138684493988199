<template>
  <v-dialog v-model="show" width="300px" height="250px" class="dialog">
    <v-card>
      <div class="d-flex pa-4 align-start">
        <div class="dialog-text mr-1 pa-6 flex-grow-1">
          Functionality is <br />
          temporarily disabled
        </div>
        <v-icon @click="$emit('close')">close</v-icon>
      </div>
    </v-card>
  </v-dialog>
</template>
<script>
export default {
  props: ['show'],
}
</script>
<style lang="scss" scoped>
::v-deep .v-dialog {
  background: #ffffff;
  box-shadow: 0px 10px 30px rgba(112, 136, 210, 0.102);
  border-radius: 20px;
}
.dialog-text {
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 20px;
  text-align: center;
  color: #2d3142;
}
</style>
