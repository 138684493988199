import { pascalCase } from '@/utilities/filters'
import api from '@/utilities/api'
import cloneDeep from 'lodash/cloneDeep'
import i18n from '@/plugins/i18n'
import Vue from 'vue'
import router from '@/plugins/router'

export const getErrorMessage = function (status) {
  let key = 'errors.' + status

  if (status !== 429 && router.currentRoute.path === '/account/passcode') {
    key = 'errors.passcode'
  }

  return i18n.te(key) ? String(i18n.t(key)) : String(i18n.t('common.error'))
}

export const itemById = state => id => cloneDeep(state.items[id])

export const itemLoading = state => id => !!state.itemsLoading[id]

export const makeDefaultGetters = function (properties) {
  const defaultGetters = {}
  properties.forEach(p => {
    defaultGetters[p] = state => cloneDeep(state[p])
  })
  return defaultGetters
}

export const makeDefaultMutations = function (properties, defaultState = {}) {
  const defaultMutations = {}
  properties.forEach(p => {
    const m = 'set' + pascalCase(p)
    const r = 're' + m
    defaultMutations[m] = (state, value) => Vue.set(state, p, value)
    defaultMutations[r] = state => Vue.set(state, p, cloneDeep(defaultState[p]))
  })
  return defaultMutations
}

export const propertyLoading = state => property => !!state.propertiesLoading[property]

export async function send(a, payload) {
  const collected = { a, payload }
  return await api(collected.payload?.url, collected.payload, undefined)
}

export async function sendAndSet({ commit, dispatch }, { mutation, payload, reset = true }) {
  if (reset) commit('re' + mutation)
  const r = await dispatch('send', payload)
  commit(mutation, r?.data)
}

export const setItem = function (state, item) {
  if (item && item?.id) Vue.set(state.items, item.id, item)
  else throw { item, msg: 'setItem requires item with valid id' }
}

export const setItemLoading = function (state, opts) {
  const { id, value } = opts
  if (id) Vue.set(state.itemsLoading, id, value)
  else throw 'setItemLoading requires a valid id'
}

export const setProperties = function ({ commit }, properties, data) {
  properties.forEach(p => {
    const mutation = 'set' + pascalCase(p)
    if (Object.prototype.hasOwnProperty.call(data, p)) commit(mutation, data[p])
  })
}

export const setPropertiesLoading = function (state, { data, value }) {
  Object.keys(data).forEach(p => {
    Vue.set(state.propertiesLoading, p, value)
  })
}

export const setState = (state, values, full) => {
  const keys = Object.keys(values)
  // unset non-default values if full true
  if (full)
    Object.keys(state)
      .filter(k => !keys.includes(k))
      .forEach(k => {
        Vue.delete(state, k)
      })
  // set default values
  Object.keys(values).forEach(k => {
    Vue.set(state, k, values[k])
  })
}

export const sortByPosition = function (arr) {
  const newArr = cloneDeep(arr)
  newArr.sort((a, b) => {
    // if no positions, return 0 as not comparable - or if equal return 0
    if (!a.position || !b.position || a.position === b.position) return 0
    // return value based on position
    return a.position - b.position > 0 ? 1 : -1
  })
  return newArr
}
