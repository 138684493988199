<template>
  <v-overlay color="rgba(35, 36, 42, 0.4)" style="backdrop-filter: blur(4px)">
    <div class="loading-notice">
      <!-- <v-progress-circular indeterminate color="primary" size="100" width="8"></v-progress-circular> -->
      <v-progress-circular :rotate="rotation" :size="100" :width="8" :value="25" color="primary">
      </v-progress-circular>
      <div>
        <div class="mb-2">Loading...</div>
        <div class="subTitle">(please allow 1-2 minutes)</div>
      </div>
    </div>
  </v-overlay>
</template>

<script>
export default {
  props: ['loading'],
  data() {
    return {
      rotation: 0,
      interval: {},
    }
  },
  beforeDestroy() {
    clearInterval(this.interval)
  },
  mounted() {
    this.interval = setInterval(() => {
      if (this.rotation === 360) {
        return (this.rotation = 0)
      }
      this.rotation += 1
    }, 10)
  },
}
</script>
<style lang="scss" scoped>
.loading-notice {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  padding: 32px;
  gap: 24px;
  background: rgba(35, 36, 42, 0.4);
  backdrop-filter: blur(4px);
  border-radius: 8px;
  font-style: normal;
  font-weight: 700;
  font-size: 20px;
  line-height: 28px;
  text-align: center;
  color: #ffffff;
  width: 267px;
}
.loading-notice .subTitle {
  font-size: 16px;
  line-height: 24px;
}

::v-deep .v-progress-circular__underlay {
  stroke: #c4cdd4;
}
</style>
