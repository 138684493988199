<template>
  <m-modal :header="header" v-model="localValue" @close="close" :width="width">
    <template v-slot:content>
      <div class="edit-modal-content">
        <m-form ref="form" :buttons="formButtons" v-slot="{ errors }">
          <m-text-input
            name="fileName"
            type="text"
            class="edit-modal-text-input"
            :label="$t('files.list.table.filename')"
            :placeholder="$t('files.list.table.filename')"
            rules="required"
            :hintIcon="getHintIcon('fileName', errors)"
            v-model="edit.fileName"
            data-cy="editFileName"
          />
        </m-form>
        <div class="edit-modal-readonly">
          <m-readonly-field :keyValues="keyValues" />
        </div>
      </div>
    </template>
    <template v-slot:buttons>
      <m-button variant="secondary" error :label="$t('files.list.delete')" @click="deleteFile()" />
      <div
        class="edit-modal-buttons"
        :class="{ editModalButtonsSmAndUp: $vuetify.breakpoint.smAndUp }"
      >
        <m-button variant="secondary" :label="$t('files.list.preview')" @click="preview()" />
        <m-button
          :label="$t('files.list.save')"
          :disabled="submitDisabled || edit.fileName === null || edit.fileName === ''"
          @click="updateFile()"
        />
      </div>
    </template>
  </m-modal>
</template>

<script>
import { MModal, MButton, MForm, MTextInput, MReadonlyField } from 'gatherings-storybook'
import { formatFileSize } from '@/utilities/files'
import { DateTime } from 'luxon'

export default {
  name: 'edit-file-modal',
  props: ['file', 'value'],
  components: {
    MModal,
    MButton,
    MForm,
    MTextInput,
    MReadonlyField,
  },
  data() {
    return {
      localValue: this.value,
      header: '',
      submitDisabled: true,
      keyValues: {},
      edit: {
        fileName: null,
      },
      width: this.$vuetify.breakpoint.smAndUp ? '610px' : undefined,
    }
  },
  watch: {
    file() {
      this.loadFileInformation()
    },
    'edit.fileName'(newValue) {
      if (newValue !== this.file.name) {
        this.submitDisabled = false
      }
    },
    localValue(newVal) {
      this.$emit('input', newVal)
    },
    value(newVal) {
      this.localValue = newVal
    },
  },
  computed: {
    formButtons() {
      return {
        Submit: {
          show: false,
        },
      }
    },
  },
  methods: {
    loadFileInformation() {
      const file = this.file
      this.header = this.edit.fileName = file.name

      // setting the readonly key values to show in the modal
      this.keyValues = {
        [this.$t('files.filter.uploadedBy')]: !file.owner
          ? this.$t('files.filter.nA')
          : `${file.owner?.givenName} ${file.owner?.surname}`,
        [this.$t('files.list.table.contentType')]: this.formatContentType(file.contentType),
        [this.$t('files.misc.dateAndTime')]: this.formatDate(file.created),
        [this.$t('files.misc.fileSize')]: formatFileSize(file.size),
        [this.$t('files.misc.fileId')]: file.id,
      }
    },
    updateFile() {
      this.$emit('update', this.edit.fileName)
      this.close()
    },
    deleteFile() {
      this.$emit('delete')
      this.close()
    },
    formatDate(date) {
      if (!date) return null
      return DateTime.fromISO(date).toFormat('EEE DD, H:mm (ZZZZ)')
    },
    formatContentType(type) {
      if (!type) return this.$t('files.filter.nA')

      if (type === 'application/pdf') {
        return this.$t('files.list.contentType.pdf')
      }

      if (type.includes('image/')) {
        return this.$t('files.list.contentType.image')
      }

      if (type.includes('video/')) {
        return this.$t('files.list.contentType.video')
      }

      return type
    },
    getHintIcon(field, errors) {
      if (errors && errors[field] && errors[field].length > 0) return 'alert-triangle-solid'
      else return null
    },
    close() {
      this.$emit('close')
      this.submitDisabled = true
      // this is to reset the input back to its original value, if the user did not save their changes and closed the modal
      this.edit.fileName = this.file.name
    },
    preview() {
      this.$emit('preview')
      this.close()
    },
  },
}
</script>

<style lang="scss" scoped>
.edit-modal-content {
  padding: 24px 32px 8px;
  :deep(*) {
    font-family: 'Mulish' !important;
    font-size: 1rem;
  }
  :deep(.value) {
    font-size: 1.1429rem;
  }
}
.edit-modal-text-input {
  :deep(label) {
    font-family: 'Mulish' !important;
  }
  :deep(input) {
    font-family: 'Mulish' !important;
  }
}
.edit-modal-buttons {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  margin-bottom: 14px;
}
.editModalButtonsSmAndUp {
  flex-direction: row;
  justify-content: space-between;
  margin: 0;
}
.text-input {
  margin-bottom: 24px;
}
</style>
<style lang="scss">
.v-application {
  .v-dialog__content {
    .v-dialog > .modal {
      .modal__top-container {
        .modal__header {
          font-family: 'Mulish' !important;
          font-weight: 400;
          font-size: 1.4286rem;
          line-height: 32px;
        }
      }
      .modal__buttons-container {
        .modal__buttons > button > span {
          font-family: 'Mulish' !important;
        }
        .modal__buttons > div > button > span {
          font-family: 'Mulish' !important;
        }
      }
    }
  }
}
</style>
