import cloneDeep from 'lodash/cloneDeep'
import { DateTime, Interval } from 'luxon'

const { fromISO } = DateTime
const { fromDateTimes } = Interval

export const parseDate = d => fromISO(d)

export const parseDates = a => a.map(d => fromISO(d))

export const forceChronologicalOrder = dates =>
  cloneDeep(dates).sort((a, b) => {
    if (isAfter(a, b)) return 1
    else if (isBefore(a, b)) return -1
    else return 0
  })

export const isAfter = (d1, d2) => fromDateTimes(d2, d1).isValid && !d2.equals(d1)

export const isBefore = (d1, d2) => fromDateTimes(d1, d2).isValid && !d1.equals(d2)

export const fifteenMinuteIntervals = () => {
  let intervals = [
    12, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 17, 18, 19, 20, 21, 22, 23,
  ]
    .map((hour, index) => {
      let meridiem = hour > 11 && index !== 0 ? 'pm' : 'am'
      let modifiedHour = hour > 11 && index !== 0 ? hour - 12 : hour
      return [
        `${modifiedHour}:00 ${meridiem}`,
        `${modifiedHour}:15 ${meridiem}`,
        `${modifiedHour}:30 ${meridiem}`,
        `${modifiedHour}:45 ${meridiem}`,
      ]
    })
    .flat()

  return intervals
}
