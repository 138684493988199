<template>
  <div class="detail-text">
    <v-row>
      <!-- Left column -->
      <v-col cols="6" lg="4" xl="5">
        <div class="detail-title">Job Title</div>
        <div v-if="user.jobTitle && user.jobTitle.length">
          {{ user.jobTitle }}
        </div>
        <div v-else>-</div>
      </v-col>

      <!-- Right column -->
      <v-col cols="6" lg="8" xl="7">
        <div class="detail-title">User Role</div>
        <div>{{ role }}</div>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="6" lg="4" xl="5">
        <div class="detail-title">Company</div>
        <div v-if="user.company && user.company.length">
          {{ user.company }}
        </div>
        <div v-else>-</div>
      </v-col>

      <!-- Right column -->
      <v-col cols="6" lg="8" xl="7">
        <div class="detail-title">Default Space</div>
        <div v-if="defaultSpaceName.length > 0">{{ defaultSpaceName }}</div>
        <div v-else>-</div>
      </v-col>
    </v-row>
  </div>
</template>

<script>
export default {
  props: ['user', 'defaultSpaceName'],
  computed: {
    role() {
      switch (this.user.userType) {
        case 0:
          return 'User'
        case 2:
          return 'Admin'
        case 4:
          return 'Guest'
        default:
          return 'Guest'
      }
    },
  },
}
</script>

<style lang="scss" scoped>
.detail-title {
  color: #828282;
}
.detail-text {
  font-size: 18px;
}
</style>
