<template>
  <component
    @keyup.enter="onChange(field)"
    @change="onChange(field)"
    @input="onChange(field)"
    :key="'field' + field.id"
    :is="getField(field)"
    :class="field.class"
    :color="field.color"
    :elevation="field.elevation"
    :items="field.items"
    :label="field.label"
    :outlined="field.outlined"
    :placeholder="field.placeholder"
    :rounded="field.rounded"
    :type="getType(field)"
    v-model="field.value"
    :rules="getRules(field)"
    :autofocus="field.autofocus"
    :flat="field.flat"
    :dense="field.dense"
    :solo="field.solo"
    :maxlength="field.maxlength"
    :data-cy="field.dataCy || field.name"
    :disabled="field.disabled"
    :background-color="field.backgroundColor"
    :prefix="field.prefix"
  ></component>
</template>

<script>
import { FORM } from '@/utilities/constants'
import { hasProp } from '@/utilities/functions'

const { FIELD, SUBMIT_BUTTON_ICON } = FORM
const { COMPONENT, RULES, TYPE } = FIELD

export default {
  props: ['field'],
  data: () => ({
    rulesActive: true,
  }),
  computed: {
    defaults() {
      return { COMPONENT, RULES, SUBMIT_BUTTON_ICON, TYPE }
    },
  },
  methods: {
    getField(field) {
      if (hasProp(field, 'component')) return field.component
      else return this.defaults.COMPONENT
    },
    getRules(field) {
      if (!this.rulesActive) return []
      else if (hasProp(field, 'rules')) return field.rules
      else return this.defaults.RULES
    },
    getType(field) {
      if (hasProp(field, 'type')) return field.type
      else return this.defaults.TYPE
    },
    onChange(field) {
      this.$emit('change', { name: field.name, value: field.value })
    },
  },
}
</script>
