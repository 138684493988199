var render = function render(){var _vm=this,_c=_vm._self._c;return (_vm.loading)?_c('div',[_c('m-loading',{attrs:{"type":"circle","loading":true}})],1):_c('div',[(_vm.isCustomizableEcosystem)?_c('div',{staticClass:"branding-container",class:{ containerLgAndUp: _vm.$vuetify.breakpoint.mdAndUp }},[(_vm.spaceStyles.length)?_c('div',{staticClass:"branding-accordion-container",class:{ accordionContainerLgAndUp: _vm.$vuetify.breakpoint.lgAndUp }},[_c('m-accordions',{staticClass:"branding-accordions",attrs:{"value":_vm.openStyle},on:{"value":_vm.changeOpen}},_vm._l((_vm.spaceStyles),function(style,index){return _c('m-accordion',{key:index,staticClass:"branding-accordion",attrs:{"completed":_vm.isComplete(style)},scopedSlots:_vm._u([{key:"header",fn:function(){return [_c('div',{staticClass:"d-flex justify-center align-center header-text"},[_c('div',{staticClass:"mr-3"},[_c('div',{staticClass:"purple-circle primary--text"},[_vm._v(" "+_vm._s(_vm.getLetter(index))+" ")])]),_c('div',[_vm._v(_vm._s(_vm.styleName(style)))])])]},proxy:true}],null,true)},[[_c('m-asset-picker',{staticClass:"my-4",attrs:{"value":_vm.selectedFile,"dimensions":style.minimumSize
                  ? {
                      height: style.minimumSize.height,
                      width: style.minimumSize.width,
                    }
                  : _vm.defaultDimensions,"previewBackground":style.backgroundColour,"customFilePicker":"","layout":_vm.$vuetify.breakpoint.smAndDown ? 'horizontal' : 'vertical'},on:{"click":function($event){return _vm.openFilePicker(style)},"delete":function($event){return _vm.deleteFile(style)}},scopedSlots:_vm._u([{key:"dropzone",fn:function(){return [_c('div',{staticClass:"dropzone-text"},[_vm._v(" "+_vm._s(_vm.$t('spaces.branding.selectFile'))+" ")])]},proxy:true},{key:"default",fn:function(){return [(style.selectedFile)?_c('m-color-picker-input',{model:{value:(style.backgroundColour),callback:function ($$v) {_vm.$set(style, "backgroundColour", $$v)},expression:"style.backgroundColour"}}):_vm._e(),_c('div',{staticClass:"asset-picker-text"},[_vm._v(" "+_vm._s(_vm.$t('spaces.branding.transparentImagesOnly'))+" "),_c('br'),(style.minimumSize)?_c('div',[_vm._v(" "+_vm._s(_vm.$t('spaces.branding.recommendedSize'))+" "),_c('span',[_vm._v(_vm._s(style.minimumSize.width)+"x"+_vm._s(style.minimumSize.height)+" "+_vm._s(_vm.$t('spaces.branding.px')))])]):_vm._e()])]},proxy:true}],null,true)})]],2)}),1)],1):_vm._e(),_c('div',{staticClass:"vr-preview",class:{ vrPreviewLgAndUp: _vm.$vuetify.breakpoint.lgAndUp }},[_c('c-space-branding-preview',{attrs:{"brandingStyle":_vm.openedStylePreview,"letter":_vm.getLetter(_vm.openStyle)}})],1),(_vm.spaceStyles.length)?_c('c-space-files-modal',{attrs:{"show":_vm.filesModal.show,"ecosystemId":_vm.ecosystem.id,"brandingOpportunity":_vm.spaceStyles[_vm.openStyle],"header":_vm.modalHeader},on:{"close":_vm.closeModal,"addFile":_vm.addFile}}):_vm._e()],1):_c('div',[_vm._m(0)])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"space-locked"},[_c('div',[_c('img',{staticClass:"space-locked__img",attrs:{"src":"/images/svgs/lock.svg","data-cy":"space-locked-image"}})]),_c('div',[_vm._v(" You are not able to brand this space."),_c('br'),_vm._v(" Please, contact the sustained engineering team to custom brand this space. ")])])
}]

export { render, staticRenderFns }