import i18n from '@/plugins/i18n'
import { DateTime, Interval } from 'luxon'

const { fromISO, now } = DateTime
const { fromDateTimes } = Interval

export const isRequired = v =>
  (v && String(v).trim().length > 0) || String(i18n.t('validations.thisFieldIsRequired'))

/**
 * Type checking
 */
export const isFloat = (v, strict) =>
  strict
    ? Number(v) === v
    : Number(v) == v || endsInDecimalPoint(v) || String(i18n.t('validations.mustBeAFloat'))

export const isInteger = (v, strict = false) =>
  Number.isInteger(strict ? v : Number(v)) || String(i18n.t('validations.mustBeAnInteger'))

/**
 * Format
 */
export const endsInDecimalPoint = v => String(v).lastIndexOf('.') === String(v).length - 1

export const isRoundedToNDecimalPlaces = (v, n, exact = false) => {
  const exploded = String(v).split('.')
  const eLen = exploded.length
  if (eLen === 1) return true // number is whole
  if (eLen > 2) return String(i18n.t('validations.mustHaveASingleDecimalPoint')) // not a decimal
  const length = exploded[eLen - 1].length
  if (exact)
    return length === n || String(i18n.t('validations.mustBeRoundedToExactlyNDecimalPlaces', { n }))
  else return length <= n || String(i18n.t('validations.mustBeRoundedToNDecimalPlaces', { n }))
}

/**
 * Strings
 */

export const maxChar = n => v =>
  !v || String(v).length <= n || String(i18n.t('validations.maxNCharacters', { n }))

export const minChar = n => v =>
  !v || String(v).length >= n || String(i18n.t('validations.minNCharacters', { n }))

export const noSpecialChars = v =>
  !/[!"#$%&'()*+,-./:;<=>?@[\]^_`{|}~]/.test(String(v)) ||
  String(i18n.t('validations.noSpecialCharacters'))

export const validHexCode = v =>
  !v ||
  /^([A-Fa-f0-9]{6}|[A-Fa-f0-9]{3})$/.test(String(v)) ||
  String(i18n.t('validations.pleaseProvideAValidHexCode'))

/**
 * Email
 */

export const isEmailAddress = v =>
  !v || isEmailAddressCheck(v) || String(i18n.t('validations.mustBeAValidEmailAddress'))

/**
 * Returns true if a valid email address.
 *
 * @param {String} str string to be checked
 *
 * @returns {Boolean} true if string is a valid email address
 */
export function isEmailAddressCheck(str) {
  const re =
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
  return re.test(String(str).toLowerCase())
}

/**
 * Time
 */

export const isPast = v =>
  fromDateTimes(fromISO(v), now()).isValid || String(i18n.t('validations.thisDateMustBeInThePast'))

export const isFuture = v =>
  fromDateTimes(now(), fromISO(v)).isValid ||
  String(i18n.t('validations.thisDateMustBeInTheFuture'))

export const isValidDate = v =>
  DateTime.fromISO(v).isValid || String(i18n.t('validations.thisFieldMustBeAValidDate'))

/**
 * Numbers
 */
export const isGreaterThanN = (v, n) =>
  v > n || String(i18n.t('validations.mustBeGreaterThanN', { n }))

export const isGreaterThanOrEqualToN = (v, n) =>
  v >= n || String(i18n.t('validations.mustBeGreaterThanOrEqualToN', { n }))

export const isLessThanN = (v, n) => v < n || String(i18n.t('validations.mustBeLessThanN', { n }))

export const isLessThanOrEqualToN = (v, n) =>
  v <= n || String(i18n.t('validations.mustBeLessThanOrEqualToN', { n }))
