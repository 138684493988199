<template>
  <v-dialog
    :value="show"
    @click:outside="clickOutside"
    @keydown="onKeydown"
    max-width="1000px"
    persistent
    scrollable
    :fullscreen="shouldBeFullScreen"
  >
    <div class="upsert-dialog relative d-flex flex-column">
      <v-btn icon fab elevation="0" absolute right class="top" @click="close">
        <v-icon size="24">mdi-close</v-icon>
      </v-btn>

      <div ref="title" class="mt-6 upsert-title">
        {{ title }}
      </div>

      <slot>
        <div>Content</div>
      </slot>

      <div ref="footer" class="upsert-footer">
        <div>
          <c-button type="danger" v-if="showDeleteBtn" @click="$emit('delete')">
            {{ deleteBtnText }}
          </c-button>
        </div>

        <c-button :disabled="disableSubmit" @click="$emit('submit')">
          {{ submitBtnText }}
        </c-button>
      </div>
    </div>
  </v-dialog>
</template>

<script>
export default {
  props: {
    show: {
      type: Boolean,
      default: false,
    },
    title: {
      type: String,
    },
    submitBtnText: {
      type: String,
      default: 'Submit',
    },
    deleteBtnText: {
      type: String,
      default: 'Delete',
    },
    disableSubmit: {
      type: Boolean,
      default: false,
    },
    showDeleteBtn: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    clickOutside() {
      this.close()
    },
    onKeydown(event) {
      if (event.key === 'Escape') {
        this.close()
      }
    },
    close() {
      this.$emit('close')
    },
  },
  computed: {
    shouldBeFullScreen() {
      return this.$vuetify.breakpoint.height < 900 || this.$vuetify.breakpoint.width < 900
    },
  },
}
</script>
<style lang="scss" scoped>
@media screen and (max-width: 599px) {
  .upsert-dialog {
    height: fit-content !important;
  }
}

.upsert-dialog {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0px;
  background: #ffffff;
  border-radius: 16px;
}

.upsert-title {
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 32px;
  text-align: center;
  color: #1f2329;
}

.upsert-footer {
  display: flex;
  width: 100%;
  padding: 24px 32px;
  justify-content: space-between;
  background: #ffffff;
  border: 1px solid #dce2e9;
}

@media screen and (min-width: 600px) and (min-height: 900px) {
  .upsert-footer {
    border-radius: 0px 0px 16px 16px;
  }
}

@media screen and (min-width: 600px) {
  ::v-deep .v-dialog {
    box-shadow: none;
    overflow-y: visible !important;
  }
}
</style>
