export default {
  errors: {
    400: 'Sorry, the request was unsuccessful.',
    401: 'You must be logged in to access this resuorce.',
    403: 'You are not authorised to access this resource.',
    404: 'Sorry, we could not find the page you were looking for.',
    429: 'Too many consecutive failed login attempts. Please wait 5 minutes and try again.',
    500: 'The server could not process the request.',
    error: 'Error',
    nError: '{n} error',
    noUrl: 'No url',
    sorrySomethingWentWrong: 'Sorry, something went wrong',
    passcode: 'Passcode invalid or may have expired',
    oops: 'Oops!',
    anErrorOccurred: 'An error occurred',
    pleaseTryAgainOrContactSupportIfThisProblemPersists:
      'Please try again or contact support if this problem persists',
  },
}
