<template>
  <div class="mt-12 mb-12">
    <h1 style="font-size: 3rem" data-cy="page-heading">
      <span style="font-weight: 200" data-cy="heading">
        {{ heading }}
      </span>
      <span class="font-weight-bold" data-cy="subHeading">
        {{ subHeading }}
      </span>
    </h1>
  </div>
</template>

<script>
export default { props: { heading: String, subHeading: String } }
</script>
