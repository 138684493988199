<template>
  <v-container data-cy="panels" :fluid="$vuetify.breakpoint.lgAndDown">
    <v-row>
      <!-- Users -->
      <v-col cols="12" :sm="5" offset-sm="1" class="my-1">
        <div
          class="panel mx-1 pointer"
          @click="ecosystemId ? $router.push({ path: `/ws/${ecosystemId}/users` }) : null"
        >
          <div class="d-flex justify-space-between mb-6">
            <div class="d-flex">
              <v-icon size="38" class="panel-icon">fa-users</v-icon>
              <div class="panel-title ml-6">{{ $t('dashboard.panels.users') }}</div>
            </div>
            <v-icon size="22" class="chevron">fa-chevron-right</v-icon>
          </div>

          <div>{{ $t('dashboard.panels.userPanelText') }}</div>
        </div>
      </v-col>
      <!-- Spaces -->
      <v-col cols="12" :sm="5" class="my-1">
        <div
          class="panel mx-1 pointer"
          @click="ecosystemId ? $router.push({ path: `/ws/${ecosystemId}/spaces` }) : null"
        >
          <div class="d-flex justify-space-between mb-6">
            <div class="d-flex">
              <v-icon size="38" class="panel-icon">fa-calendar-alt</v-icon>
              <div class="panel-title ml-6">{{ $t('dashboard.panels.spaces') }}</div>
            </div>
            <v-icon size="22" class="chevron">fa-chevron-right</v-icon>
          </div>

          <div>{{ $t('dashboard.panels.spacesPanelText') }}</div>
        </div>
      </v-col>
    </v-row>

    <v-row>
      <!-- Files -->
      <v-col v-if="files" cols="12" :sm="5" offset-sm="1" class="my-1">
        <div
          class="panel mx-1 pointer"
          @click="ecosystemId ? $router.push({ path: `/ws/${ecosystemId}/files` }) : null"
        >
          <div class="d-flex justify-space-between mb-6">
            <div class="d-flex">
              <v-icon size="38" class="panel-icon">fa-file</v-icon>
              <div class="panel-title ml-6">{{ $t('dashboard.panels.files') }}</div>
            </div>
            <v-icon size="22" class="chevron">fa-chevron-right</v-icon>
          </div>

          <div>{{ $t('dashboard.panels.filesPanelText') }}</div>
        </div>
      </v-col>

      <!-- Spaces - commented out as "gatherings" was renamed to "spaces" -->
      <!-- <v-col cols="12" :sm="5" :offset-sm="offset('spaces')" class="my-1">
        <div class="comingsoon-panel mx-1">
          <div class="d-flex justify-space-between mb-6">
            <div class="d-flex">
              <v-icon size="38" class="panel-icon">fa-home</v-icon>
              <div class="panel-title ml-6">{{ $t('dashboard.panels.spaces') }}</div>
            </div>
          </div>

          <div>
            <span class="font-weight-bold"> {{ $t('dashboard.panels.comingSoon') }} </span
            >{{ $t('dashboard.panels.spacesPanelText') }}
          </div>
        </div>
      </v-col> -->

      <!-- Admin -->
      <v-col cols="12" :offset-sm="offset('admin')" :sm="5" class="my-1">
        <div class="comingsoon-panel mx-1">
          <div class="d-flex justify-space-between mb-6">
            <div class="d-flex">
              <v-icon size="38" class="panel-icon">fa-cog</v-icon>
              <div class="panel-title ml-6">{{ $t('dashboard.panels.admin') }}</div>
            </div>
          </div>

          <div>
            <span class="font-weight-bold"> {{ $t('dashboard.panels.comingSoon') }} </span
            >{{ $t('dashboard.panels.adminPanelText') }}
          </div>
        </div>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {
  props: ['ecosystemId', 'files'],
  methods: {
    offset(panel) {
      if (panel === 'spaces') {
        return this.files ? 0 : 1
      }
      if (panel === 'admin') {
        return this.files ? 0 : 1
      }
    },
  },
}
</script>

<style lang="scss" scoped>
.icon-btn {
  cursor: inherit;
}
.panel {
  background: #ffffff;
  border-radius: 20px;
  border: 1px solid #dfe2e6;
  box-shadow: 0px 10px 30px rgba(128, 126, 240, 0.1);
  box-sizing: border-box;
  box-sizing: border-box;
  color: #363332;
  font-size: 18px;
  font-style: normal;
  font-weight: normal;
  height: 100% !important;
  line-height: 27px;
  padding-bottom: 4rem;
  padding: 32px 32px 32px 32px;
  text-align: left;
}

.comingsoon-panel {
  background: #ffffff;
  border-radius: 20px;
  border: 1px solid #dfe2e6;
  box-shadow: 0px 10px 30px rgba(128, 126, 240, 0.1);
  box-sizing: border-box;
  box-sizing: border-box;
  color: #363332;
  font-size: 18px;
  font-style: normal;
  font-weight: normal;
  height: 100% !important;
  line-height: 27px;
  padding-bottom: 4rem;
  padding: 32px 32px 32px 32px;
  text-align: left;
}

.panel:hover {
  background: #807ef0;
  color: white;
}

.panel-icon {
  color: #807ef0;
  transition: none;
}

.chevron {
  transition: none;
}

.panel:hover .panel-title {
  color: white;
}

.panel:hover .panel-icon {
  color: white;
}

.panel:hover .chevron {
  color: white;
}

.panel-title {
  font-family: Kiro;
  font-style: normal;
  font-weight: bold;
  font-size: 28px;
  line-height: 42px;
  color: #363332;
}
.panel-body {
  font-size: 1.5rem;
}
</style>
