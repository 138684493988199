import { pageTitle } from '@/utilities/page'
import i18n from '@/plugins/i18n'
import requirements from '@/routes/requirements'
import { setLayout } from '@/utilities/routes'

let routes = [
  {
    component: () => import(/* webpackChunkName: "Dashboard" */ '@/views/Dashboard.vue'),
    meta: {
      title: pageTitle(String(i18n.t('dashboard.title'))),
      requires: requirements,
    },
    name: 'Dashboard',
    path: '/',
  },
]

routes = setLayout(routes, 'default')

export default routes
