export default [
  {
    order: 1,
    name: 'Details',
    section: 'General details',
    subTitle: 'Let’s start by entering the main information about your upcoming event.',
    shortSubTitle: 'Provide basic event details.',
    component: 'c-event-upsert-general-details',
  },
  {
    order: 2,
    name: 'Location',
    section: 'Choose location',
    subTitle: 'Select the location where your event will take place.',
    component: 'c-event-upsert-choose-location',
  },
  {
    order: 3,
    name: 'Branding',
    section: 'Branding',
    subTitle: 'Select the branding assets to show in Gatherings VR',
  },
  {
    order: 4,
    name: 'Presenters',
    section: 'Presenters for event',
    subTitle: 'You can choose one or more presenters for your event.',
  },
  {
    order: 5,
    name: 'Team',
    section: 'Choose your team',
    subTitle: 'Here you can choose some hosts and facilitators.',
  },
  {
    order: 6,
    name: 'Attendees',
    section: 'Invite attendees',
    subTitle: 'Here you can choose invite attendees to your event',
  },
]
