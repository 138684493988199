<template>
  <v-dialog
    v-model="show"
    width="500"
    v-click-outside="{
      handler: close,
      include: include,
    }"
    @keydown="onKeydown"
  >
    <div class="pin-dialog included">
      <v-btn plain x-small class="close-button" @click="$emit('close')">
        <v-icon color="black" size="24px"> mdi-close </v-icon>
      </v-btn>
      <div class="your-pin">Your Gatherings Key is:</div>
      <div class="preview-and-copy">
        <div class="pin-preview">{{ pin }}</div>
        <c-button buttonType="button" v-on:click="copyPin">{{ copyText }}</c-button>
      </div>
      <div class="pin-length">This Gatherings Key is valid for {{ pinLifetime }} days</div>
    </div>
  </v-dialog>
</template>
<script>
export default {
  props: {
    show: {
      type: Boolean,
      default: false,
    },
    pin: {
      type: String,
    },
  },
  data() {
    return {
      copyText: 'Copy',
    }
  },
  methods: {
    close() {
      this.$emit('close')
      this.copyText = 'Copy'
    },
    onKeydown(event) {
      if (event.key === 'Escape') {
        this.$emit('close')
      }
    },
    copyPin() {
      navigator.clipboard.writeText(this.pin)
      this.copyText = 'Copied'
    },
    include() {
      if (document.querySelector('.included')) {
        return [document.querySelector('.included')]
      }
      return []
    },
  },
  computed: {
    pinLifetime() {
      return process.env.VUE_APP_DEVICE_PIN_LIFETIME_IN_DAYS ?? 7
    },
  },
}
</script>
<style lang="scss" scoped>
.pin-dialog {
  width: 100% !important;
  padding: 24px;
  border-radius: 16px !important;
  height: 200px;
  background: white;
  position: relative;
  text-align: center;
}

@media screen and (min-width: 600px) {
  .pin-dialog {
    width: 500px;
  }
}

.close-button {
  position: absolute;
  top: 24px;
  right: 24px;
}

.pin-preview {
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 10px 24px;
  gap: 10px;
  width: 124px;
  height: 48px;
  background: #f8f8ff;
  border: 1.5px dashed #807ef0;
  border-radius: 8px;
  font-style: normal;
  font-weight: 700;
  font-size: 20px;
  line-height: 28px;
  color: #464d54;
}

.your-pin {
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 32px;
  text-align: center;
  color: #1f2329;
  margin-bottom: 24px;
}

.pin-length {
  font-style: normal;
  font-weight: 300;
  font-size: 18px;
  line-height: 24px;
  color: #464d54;
}

.preview-and-copy {
  margin-bottom: 16px;
  display: flex;
  justify-content: center;
  gap: 16px;
}
</style>
