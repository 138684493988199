<template>
  <div>
    <div class="details-heading" data-cy="provideBasicSpaceDetailsAndManageAttendees">
      {{ $t('spaces.create.provideBasicSpaceDetailsAndManageAttendees') }}
    </div>
    <v-row class="ma-0">
      <v-col cols="12" md="6" class="pa-0 mb-6" :class="{ 'pr-6': $vuetify.breakpoint.mdAndUp }">
        <m-text-input
          name="spaceName"
          type="text"
          class="text-input"
          :label="$t('spaces.create.spaceName')"
          :placeholder="$t('spaces.create.boardMeeting')"
          rules="required|min:2|max:26"
          data-cy="name-input"
          v-model="form.name"
        />
      </v-col>
      <v-col cols="12" md="6" class="pa-0 mb-6">
        <m-dropdown
          :label="$t('spaces.create.template')"
          autocomplete="off"
          :placeholder="$t('spaces.create.select')"
          data-cy="template-select"
          rules="required"
          v-model="form.templateId"
          :items="spaceTemplates"
          :allowSearch="true"
          :disabled="!!spaceId"
          :menuProps="{
            nudgeRight: '12px',
          }"
        />
      </v-col>
      <!-- <v-col cols="12" md="6" class="pa-0 mb-6" :class="{ 'pr-6': $vuetify.breakpoint.mdAndUp }">
        <m-date-time-picker
          icon
          :label="$t('spaces.create.startDateAndTime')"
          :datePickerProps="startDateProps"
          :minHour="startDateMinHour"
          :minMinute="startDateMinMinute"
          v-model="form.startTime"
          :data-cy="'startTime'"
        />
      </v-col>
      <v-col cols="12" md="6" class="pa-0 mb-6">
        <m-date-time-picker
          icon
          :label="$t('spaces.create.endDateAndTime')"
          :datePickerProps="endDateProps"
          :minHour="endDateMinHour"
          :minMinute="endDateMinMinute"
          v-model="form.endTime"
          :data-cy="'endTime'"
        />
      </v-col>
      <v-col cols="12" md="6" class="pa-0" :class="{ 'pr-6': $vuetify.breakpoint.mdAndUp }">
        <div
          v-if="timeZonesDiffer"
          class="error--text font-weight-light text-caption d-flex align-center"
        >
          {{
            $t(
              'spaces.create.pleaseNoteTheSelectedTimezoneIsDifferentToTheDefaultForThisEcosystem'
            )
          }}.
        </div>
        <m-timezone-picker
          :label="$t('spaces.create.timezone')"
          showSearch
          :timezones="timeZones"
          orderBy="utcOffset"
          :menuProps="timezonePickerMenuProps"
          showTimezoneField
          v-model="form.timeZone"
          data-cy="spaceTimeZone-select"
        />
      </v-col> -->
    </v-row>
    <v-row class="ma-0">
      <v-col cols="12" md="6" class="pa-0">
        <m-radio-button-group
          @change="accessChanged"
          v-model="form.isEcosystemOpen"
          :label="$t('spaces.create.accessLevel')"
        >
          <m-radio-button
            :value="true"
            :label="$t('spaces.create.anyoneInYourEcosystemCanJoin')"
            data-cy="radio-btn-open"
          />
          <m-radio-button
            :value="false"
            :label="$t('spaces.create.attendeesMustBeInvitedToJoin')"
            data-cy="radio-btn-inviteOnly"
          />
        </m-radio-button-group>
      </v-col>
    </v-row>
  </div>
</template>
<script>
import {
  //   MTimezonePicker,
  MDropdown,
  MTextInput,
  //   MDateTimePicker,
  MRadioButtonGroup,
  MRadioButton,
} from 'gatherings-storybook'
import { cloneDeep, isEqual } from 'lodash'
export default {
  components: {
    // MTimezonePicker,
    MDropdown,
    MTextInput,
    // MDateTimePicker,
    MRadioButtonGroup,
    MRadioButton,
  },
  model: {
    prop: 'space',
  },
  props: {
    space: {
      required: true,
      type: Object,
    },
    ecosystemId: {
      type: String,
    },
    spaceId: {
      type: String,
    },
  },
  data() {
    return {
      spaceTemplates: [],
      timeZones: [],
      timezonePickerMenuProps: {
        width: '100%',
        showTimezoneField: true,
        showSearch: true,
      },
      ecosystem: {},
      todaysDate: new Date().toISOString().slice(0, 10),
      startDateTime: '',
      endDateTime: '',
      // Clone the object so we don't modify the original
      form: cloneDeep(this.space),
    }
  },
  watch: {
    space: {
      handler(newVal) {
        // Check if the space has been updated
        if (!isEqual(newVal, this.form)) {
          // Update the local form value
          this.form = cloneDeep(newVal)
        }
      },
      deep: true,
    },
    form: {
      handler(value) {
        // Emit the update to the parent
        this.$emit('input', value)
      },
      deep: true,
    },
    'form.startTime'() {
      this.$emit('updateDate')
    },
    'form.endTime'() {
      this.$emit('updateDate')
    },
    deep: true,
  },
  computed: {
    startDateProps() {
      let dateToday = new Date().toISOString().slice(0, 10)
      return {
        min: dateToday,
      }
    },
    endDateProps() {
      return {
        min: this.form.startTime ? this.form.startTime : this.startDateProps.min,
      }
    },
    startDateMinHour() {
      let hour = 0
      if (this.form.startTime?.substring(0, 10) === this.todaysDate) {
        hour = parseInt(new Date().toISOString().slice(11, 13))
      }
      return hour
    },
    endDateMinHour() {
      let hour = 0

      if (this.form.endTime?.substring(0, 10) === this.todaysDate) {
        hour = parseInt(new Date().toISOString().slice(11, 13))
      }
      if (this.form.startTime?.length > 10) {
        if (this.form.startTime?.substring(0, 10) === this.form.endTime?.substring(0, 10)) {
          hour = parseInt(this.form.startTime?.slice(11, 13))
        }
      }

      return hour
    },
    startDateMinMinute() {
      let minute = 0
      if (this.form.startTime?.substring(0, 10) === this.todaysDate) {
        minute = parseInt(new Date().toISOString().slice(14, 16))
      }

      return minute
    },
    endDateMinMinute() {
      let minute = 0

      if (this.form.endTime?.substring(0, 10) === this.todaysDate) {
        minute = parseInt(new Date().toISOString().slice(14, 16))
      }
      if (this.form.startTime?.length > 10) {
        if (this.form.startTime?.substring(0, 10) === this.form.endTime?.substring(0, 10)) {
          minute = parseInt(this.form.startTime.slice(14, 16))
        }
      }

      return minute
    },
    timeZonesDiffer() {
      return this.ecosystem.timeZone && this.ecosystem.timeZone != this.form.timeZone ? true : false
    },
  },
  methods: {
    accessChanged() {
      this.$emit('accessChanged')
    },
  },
  async mounted() {
    await this.$store.dispatch('spaces/fetchTemplates')
    this.ecosystem = await this.$store.getters['ecosystems/getById'](this.ecosystemId)
    this.spaceTemplates = await this.$store.getters['spaces/templates'].map(g => {
      return {
        value: g.id,
        text: g.name,
      }
    })

    this.timeZones = await this.$store.getters['timeZones/all']
    this.form.timeZone = await this.$store.getters['timeZones/default']({
      ecosystemId: this.ecosystemId,
    })
  },
}
</script>
<style lang="scss">
.details-heading {
  margin-top: 16px;
  margin-bottom: 32px;
  font-family: 'Mulish';
  font-style: normal;
  font-weight: 300;
  font-size: 18px;
  line-height: 24px;

  color: #464d54;
}
.date-time-picker {
  :deep(.date-time-picker__inner) {
    margin-bottom: 0;
  }
}
.v-input--radio-group legend.v-label {
  color: var(--v-grey-dark-base);
  font-size: 0.875rem;
  font-weight: 700;
  line-height: 1.25rem;
  flex-grow: 1;
}

::v-deep .v-text-field__slot {
  width: 100%;
  border-radius: 15px;
  border: none;
  background-color: #f3efed;
  padding: 10px 20px 10px 20px;
}

::v-deep .v-text-field__details {
  padding-left: 0px;
}

::v-deep .v-input__slot {
  padding-left: 0px !important;
}

::v-deep .v-select__slot {
  width: 100%;
  border: none;
  padding: 0px 20px 0px 20px;
}

.datetime-local-input {
  width: 100%;
  border-radius: 15px;
  border: none;
  background-color: #f3efed;
  padding: 10px 20px 10px 20px;
}

.date-time-picker__calendar-icon {
  height: 22px;
  width: 22px;
}

// Overwrite font-family
.date-time-picker__menu {
  font-family: 'Mulish' !important;
  &.v-menu__content {
    border: none;
  }
}
</style>
