import { pageTitle } from '@/utilities/page'
import i18n from '@/plugins/i18n'
import requirements from '@/routes/requirements'
import { setLayout } from '@/utilities/routes'

let routes = [
  {
    component: () => import(/* webpackChunkName: "FilesList" */ '@/views/files/List.vue'),
    meta: {
      title: pageTitle(String(i18n.t('files.titles.list'))),
      requires: requirements,
    },
    name: 'Consent',
    path: '/ws/:ecosystemId/files',
    props: true,
  },
]

routes = setLayout(routes, 'default')

export default routes
