<template>
  <div>
    <!-- Drop zone upload pad -->
    <div
      v-if="!dataUrl"
      @click="chooseFile"
      @dragenter="draggingInto = true"
      @dragleave="draggingInto = false"
    >
      <slot
        name="drop-zone"
        :extensions="extensions"
        :targetDimensions="targetDimensions"
        :draggingInto="draggingInto"
        :hasError="hasError"
      >
        <div>
          <img src="/images/svgs/upload.svg" alt="Upload a file" />
          <div>Upload</div>
        </div>
      </slot>
    </div>

    <!-- Image preview -->
    <div v-if="dataUrl" class="clickable">
      <slot
        name="image-preview"
        :dataUrl="dataUrl"
        :imageName="imageName"
        :chooseFile="chooseFile"
        :editImage="editImage"
        :removeImage="removeImage"
        @click="editImage"
      >
        <img :src="dataUrl" :id="uniqueId" />
      </slot>
    </div>

    <!-- Invisible file upload component for picking file from file system or catching dragged and dropped file -->
    <file-upload
      :ref="uploadRef"
      v-model="files"
      name="image-upload"
      :input-id="uniqueId"
      type="file"
      @input-filter="uploadImage"
      :drop="true"
      :accept="extensions"
    />

    <!-- Image cropper -->
    <c-image-cropper
      v-on:close="showDialog = false"
      v-on:crop="cropImage"
      :show="showDialog"
      :image="image"
      :aspectRatio="aspectRatio"
      :restriction="restriction"
      :minimumDimensions="computedMinDimensions"
    />
  </div>
</template>

<script>
import FileUpload from 'vue-upload-component'
import { v4 as uuidv4 } from 'uuid'
export default {
  components: {
    FileUpload,
  },
  props: {
    extensions: {
      type: String,
      default: '.jpeg,.jpg,.png',
    },
    minimumDimensions: {
      type: Object,
    },
    targetDimensions: {
      type: Object,
      default: () => ({
        width: 720,
        height: 300,
      }),
    },
    // For more details on below
    // see https://advanced-cropper.github.io/vue-advanced-cropper/guides/advanced-recipes.html#different-image-restrictions
    restriction: {
      type: String,
      default: 'fit-area',
      validator: function (value) {
        return ['fit-area', 'none', 'stencil', 'fill-area'].indexOf(value) !== -1
      },
    },
    uniqueId: {
      type: String,
      default: uuidv4(),
    },
  },
  data() {
    return {
      files: [],
      image: null,
      dataUrl: '',
      draggingInto: false,
      showDialog: false,
      hasError: false,
      // maxSize: 5000000, // 5MB
    }
  },
  methods: {
    chooseFile() {
      document.getElementById(this.uniqueId).click()
    },
    async uploadImage(newFile, oldFile, prevent) {
      if (this.hasInvalidFileExtension(newFile)) {
        this.fail(this.$t('events.upsert.generalDetails.thisTypeOfFileIsNotAllowed'))
        this.hasError = true
        return prevent()
      }

      const img = await new Promise((resolve, reject) => {
        const img = new Image()
        img.addEventListener('load', () => resolve(img))
        img.addEventListener('error', reject)
        img.src = URL.createObjectURL(newFile.file)
      })

      if (this.isTooSmall(img)) {
        this.fail(this.$t('events.upsert.generalDetails.thisFileIsTooSmall'))
        this.hasError = true
        return prevent()
      }

      // if(this.isTooLarge(newFile)){
      //   this.fail(this.$t('events.upsert.generalDetails.thisFileIsTooLarge'))
      //   return prevent()
      // }

      await this.processFile(newFile, img)

      this.draggingInto = false
      this.hasError = false
      this.showDialog = true
    },
    fail(message) {
      this.$store.dispatch('ui/launchSnackbar', {
        color: 'error',
        message,
        buttonColor: 'white',
        timeout: 3000,
      })
    },
    hasInvalidFileExtension(file) {
      let pipedExtensions = this.extensions.replaceAll('.', '').split(',').join('|')
      let pattern = `\\.(${pipedExtensions})$`
      let regex = new RegExp(pattern, 'i')

      if (!regex.test(file.name)) {
        return true
      }
      return false
    },
    isTooSmall(img) {
      if (
        img.naturalWidth < this.computedMinDimensions.width ||
        img.naturalHeight < this.computedMinDimensions.height
      ) {
        return true
      }
      return false
    },
    // isTooLarge(file){
    //   if(file.size > this.maxSize){
    //     return true
    //   }
    //   return false
    // },
    async processFile(file, img) {
      this.image = {}
      this.image.url = img.src
      this.image.file = file.file
      this.image.dimensions = { width: img.naturalWidth, height: img.naturalHeight }
      // Sanitize file name
      this.image.name = file.name
        .split(' ')
        .join('_')
        .replace(/[^A-Za-z0-9_.-]/g, '')
    },
    cropImage(dataUrl) {
      this.dataUrl = dataUrl
      this.$emit('image', { id: this.uniqueId, image: this.image })
    },
    editImage() {
      this.showDialog = true
    },
    removeImage() {
      this.dataUrl = ''
      this.image = null
    },
  },
  computed: {
    imageName() {
      return this.image?.name ?? ''
    },
    aspectRatio() {
      return `${this.targetDimensions.width}/${this.targetDimensions.height}`
    },
    uploadRef() {
      return this.id + '-image-upload'
    },
    computedMinDimensions() {
      let minDimensions = this.minimumDimensions?.width
        ? this.minimumDimensions
        : this.targetDimensions ?? { width: 512, height: 512 }
      return minDimensions
    },
  },
}
</script>

<style lang="scss" scoped>
.image-upload {
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 21px 16px 16px;
  gap: 8px;
  width: 100%;
  background: #ffffff;
  border: 1px dashed #dce2e9;
  border-radius: 8px;
  cursor: pointer;
}

.image-hover:hover {
  background-color: #e8eef3;
  border: 1px dashed #807ef0;
}

@media screen and (min-width: 700px) {
  .image-upload {
    width: 664px;
  }
}

.upload-icon-container {
  height: 40px;
}

.drag-drop-text {
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: #464d54;
  margin-top: 13px;
  margin-bottom: 8px;
}
.drag-drop-rules {
  width: 632px;
  height: 36px;
  font-family: 'Mulish';
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 18px;
  text-align: center;
  font-feature-settings: 'pnum' on, 'lnum' on;
  color: #95a1aa;
}

.dragging-into {
  background-color: #e8eef3;
  border: 1px dashed #807ef0;
}

.image-preview-container {
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 0px;
  width: 660px;
  background: #ffffff;
  border: 1px solid #e8eef3;
  border-radius: 8px;
}

.image-preview {
  border-radius: 8px 8px 0px 0px;
  height: 100%;
  width: 660px;
}

.image-name {
  margin-top: 6px;
  margin-left: 16px;
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 18px;
  display: flex;
  align-items: center;
  font-feature-settings: 'pnum' on, 'lnum' on;
  color: #464d54;
}

.image-buttons {
  padding: 16px;
  width: 100%;
  display: flex;
  justify-content: space-between;
}

.action-button {
  display: flex;
  justify-content: space-between;
  align-items: center;
  text-transform: none;
  padding: 0px;
  gap: 6px;
}

.edit-button {
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 20px;
  color: #807ef0;
}

.button-icon {
  width: 14px;
  height: auto;
}

.remove-button {
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 20px;
  color: #e11900;
}
</style>
