export default [
  {
    order: 1,
    name: 'Cyberspace',
    image: 'cyberspace.png',
    id: 'uuid',
  },
  {
    order: 2,
    name: 'Roundtable',
    image: 'roundtable.png',
    id: 'uuid',
  },
  {
    order: 3,
    name: 'Ballroom',
    image: 'ballroom.png',
    id: 'uuid',
  },
  {
    order: 4,
    name: 'Castle',
    image: 'castle.png',
    id: 'uuid',
  },
  {
    order: 5,
    name: 'Lounge',
    image: 'lounge.png',
    id: 'uuid',
  },
  {
    order: 6,
    name: 'Outdoor',
    image: 'outdoor.png',
    id: 'uuid',
  },
  {
    order: 7,
    name: 'Space Zone',
    image: 'spaceZone.png',
    id: 'uuid',
  },
  {
    order: 8,
    name: 'Living Space',
    image: 'livingSpace.png',
    id: 'uuid',
  },
  {
    order: 9,
    name: 'Garden',
    image: 'garden.png',
    id: 'uuid',
  },
  {
    order: 10,
    name: 'Cyberspace2',
    image: 'cyberspace.png',
    id: 'uuid',
  },
  {
    order: 11,
    name: 'Roundtable2',
    image: 'roundtable.png',
    id: 'uuid',
  },
  {
    order: 12,
    name: 'Ballroom2',
    image: 'ballroom.png',
    id: 'uuid',
  },
]
