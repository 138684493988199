export default {
  events: {
    upsert: {
      generalDetails: {
        isThisEventPartOfAConference: 'Is this event part of a conference?',
        conferenceName: 'Conference name',
        enterConferenceName: 'Enter conference name',
        coverImage: 'Cover image',
        dragAndDropImageOr: 'Drag and drop image or',
        browse: 'browse',
        forAFileToUpload: 'for a file to upload',
        supportedFileTypes: 'Supported file types',
        fileSizeUpTo: 'File size up to',
        minimumResolution: 'Minimum resolution',
        name: 'Name',
        enterName: 'Enter name',
        description: 'Description',
        enterDescription: 'Enter description',
        startDateAndTime: 'Start date & time',
        endDateAndTime: 'End date & time',
        selectTimezone: 'Select timezone',
        thisTypeOfFileIsNotAllowed: 'This type of file is not allowed',
        thisFileIsTooSmall: 'This file is too small',
        thisFileIsTooLarge: 'This file is too large',
      },
    },
  },
}
