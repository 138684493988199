export default {
  auth: {
    titles: {
      email: 'Register or log in',
      passcode: 'Enter your passcode',
    },
    layout: {
      welcomeTo: 'Welcome to',
      gatherings: 'Gatherings',
    },
    email: {
      enterYourEmailAddressToRegisterOrLogin: 'Enter your email address to register or login',
    },
    passcode: {
      enterTheSixDigitCodeSentTo: 'Enter the six digit passcode sent to',
      didnTReceiveACode: "Didn't receive a passcode?",
      enterYourPasscode: 'Enter your passcode',
      pleaseProvideAValid_6DigitCode: 'Please provide a valid 6 digit passcode',
      passcodeInvalidOrMayHaveExpired: 'Passcode invalid or may have expired',
    },
    logOut: 'Log out',
    youVeBeenLoggedOut: "You've been logged out",
    youVeBeenLoggedOutDueToSessionTimeoutSignBackInToContinueUsingSpaces:
      'You’ve been logged out due to session timeout. Sign back in to continue using Spaces.',
  },
}
